import { Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import PatchCard from "./PatchCard";

const NewPatchCards = ({
  setOpen,
  setSource,
  source,
  cartCount,
  cartItems,
}) => {
  const [forestSource, setForestSource] = useState([]);
  const mergeArrays = (arr1 = [], arr2 = []) => {
    let res = [];
    res = arr1.map((obj) => {
      const index = arr2.findIndex((el) => el["forestId"] === obj["id"]);
      const { areaInSqMeters } = index !== -1 ? arr2[index] : {};
      const maxAvailable = areaInSqMeters
        ? obj.maximumUnitsInSqAvailableForSale - areaInSqMeters
        : obj.maximumUnitsInSqAvailableForSale;
      return {
        ...obj,
        maximumUnitsInSqAvailableForSale: maxAvailable,
      };
    });
    return res;
  };

  const chunk = (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    for (index = 0; index < arrayLength; index += chunk_size) {
      const myChunk = myArray.slice(index, index + chunk_size);
      tempArray.push(myChunk);
    }

    return tempArray;
  };

  useEffect(() => {
    const mergedArr = mergeArrays(source, cartItems);
    setForestSource(chunk(mergedArr, 3));
  }, [source]);

  return (
    forestSource &&
    forestSource.map((forestsArr, index) => (
      // <div
      //   className="row"
      //   style={{ flexWrap: "wrap", columnGap: 10 }}
      //   key={index + 1}
      // >
      //   {forestsArr.map((forest, subIndex) => (
      //     <PatchCard
      //       source={forest}
      //       key={subIndex + 1}
      //       count={cartCount}
      //       clickHandler={(id) => {
      //         setSource(id);
      //         setOpen();
      //       }}
      //     />
      //   ))}
      // </div>

      <Row key={index + 1} gutter={[16, 16]}>
        {forestsArr.map((forest, subIndex) => (
          <Col xl={8} md={12} sm={24} xs={24}>
            <PatchCard
              source={forest}
              key={subIndex + 1}
              count={cartCount}
              clickHandler={(id) => {
                setSource(id);
                setOpen();
              }}
            />
          </Col>
        ))}
      </Row>
    ))
  );
};

export default NewPatchCards;
