import React from "react";
import LeftArrow from "../../../assets/icons/chevron-left-light.svg";
import RightArrow from "../../../assets/icons/chevron-right-light.svg";

function Arrows({ prevSlide, nextSlide }) {
  return (
    <div className="arrows">
      <span className="previousTabArrow" onClick={prevSlide}>
        <img src={LeftArrow} alt="previous" height={16} width={16} />
      </span>
      <span className="nextTabArrow" onClick={nextSlide}>
        <img src={RightArrow} alt="next" height={16} width={16} />
      </span>
    </div>
  );
}

export default Arrows;
