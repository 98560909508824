import React from "react";
import GoogleMapReact from "google-map-react";
import "../../map.css";

const Map = ({ latitude, longitude, zoom, height, width, name, polygone }) => {
  const options = {
    fillColor: "lightblue",
    fillOpacity: 1,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: true,
    editable: true,
    geodesic: true,
    zIndex: 1,
    mapTypeId: 'satellite',
  };
  return (
    <div
      className="flex justifyCenter alignCenter w-100"
      style={{
        height: height ? height : "496px",
        width: width ? width : "380px",

        // height: height ? height : "496px",
        // width: width ? width : "280px",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyBpxp_POQeSh0HK50fQ5mNnWjrxgxCGEmQ",
        }}
        defaultCenter={{
          lat: latitude ? latitude : 52.075333,
          lng: longitude ? longitude : 12.643346,
        }}
        defaultZoom={zoom ? zoom : 20}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          // const triangleCoords = [
          //   { lat: 59.99, lng: 30.39 },
          //   { lat: 59.99, lng: 30.38 },
          //   { lat: 59.99, lng: 30.37 },
          //   { lat: 59.98, lng: 30.36 },
          //   { lat: 59.97, lng: 30.35 },
          //   { lat: 59.96, lng: 30.34 },
          //   { lat: 59.95, lng: 30.33 },
          // ];
          if (polygone && polygone.length) {
            let forestPolygon = new maps.Polygon({
              paths: polygone,
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.35,
            });
            forestPolygon.setMap(map);
            map.setZoom(17);
          }
          let marker = new maps.Marker({
            position: {
              lat: latitude ? latitude : 52.075333,
              lng: longitude ? longitude : 12.643346,
            },
            map,
            title: name ? name : "forest patch",
          });
          marker.setMap(map);
          map.setOptions(options);
        }}
      ></GoogleMapReact>
      {/* <img src={MapView} alt="MapView" height={400} width={500} /> */}
    </div>
  );
};

export default Map;
