import initialState from "../initialState";
import { SET_USER_SUBSCRIPTIONS, SET_PAYMENTS } from "./actions";

export default function subscriptionReducer(
    state = initialState.app,
    { type, payload }
) {
    switch (type) {
        case SET_USER_SUBSCRIPTIONS:
            return { ...state, subscriptions: payload.userSubscriptions, subscriptionsAddress: payload.address };
        case SET_PAYMENTS:
            return { ...state, payments: payload };
        default:
            return state;
    }
}
