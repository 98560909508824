import React from "react";
import "../../common/patch-details-carousel/slider.css";
function SliderContent({ activeIndex, sliderImage }) {
  return (
    <section style={{ borderRadius: 8 }}>
      {sliderImage &&
        sliderImage.map((treeDetails, index) => (
          <div
            className={index === activeIndex ? "slides active" : "inactive"}
            key={index}
            style={{ borderRadius: 8 }}
          >
            <div
              // className="flex column alignStart justifyBetween w-100 h-100"
              style={
                {
                  // boxShadow: "",
                  // borderRadius: 8,
                  // height: 348,
                  // padding: "16px 24px",
                }
              }
              className="patch-detail-slider-inner"
            >
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.46)",
                  borderRadius: "50%",
                  padding: 8,
                }}
              >
                <img
                  className="slide-image"
                  src={treeDetails.imageRef}
                  alt={"imageRef" + index + 1}
                  height={64}
                  width={64}
                />
              </div>
              <div style={{ width: "90%" ,margin :"24px 0px", minHeight:"100px" }}>
                <h2 className="slide-title">Dein Waldstück hat</h2>
                <h2 className="slide-title">
                  <strong style={{ color: "#000000" }}>
                    {`${treeDetails.count}% `}
                  </strong>
                  {` ${treeDetails.specieName}`}
                </h2>
              </div>
              <div
                // className="flex row"
                className="slider-inner-sec"
                style={{ marginBottom: 32 }}
              >
                <div
                  // style={{
                  //   background: "rgba(0, 0, 0, 0.15)",
                  //   borderRadius: 8,
                  //   padding: "12px 16px",
                  //   width: 148,
                  // }}
                  className="slider-inner-sec-div1"
                >
                  <h3 className="slide-title">
                    {treeDetails.maxHeight}{" "}
                    <span className="slide-text">m</span>
                  </h3>
                  <p className="slide-text">Maximale Höhe</p>
                </div>
                <div
                  // style={{
                  //   background: "rgba(0, 0, 0, 0.15)",
                  //   borderRadius: 8,
                  //   padding: "12px 16px",
                  //   marginLeft: 16,
                  //   width: 148,
                  // }}
                  className="slider-inner-sec-div2"
                >
                  <h3 className="slide-title">
                    {treeDetails.maxAge}{" "}
                    <span className="slide-text">Jahre</span>
                  </h3>
                  <p className="slide-text" style={{textAlign:"left"}}>Maximales Alter</p>
                </div>
              </div>
            </div>
          </div>
        ))}
    </section>
  );
}

export default SliderContent;
