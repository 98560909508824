import React from "react";
import Poster from "../../assets/landing-poster.png";
import LogoTextual from "../../assets/logo.svg";

const FormContainer = (props) => {
  return (
    <div style={{
      minHeight: "100vh",
      width: "100vw",
      maxHeight: 666,
      background: "#ffffff",
      display: "flex",
      overflow: "hidden"
    }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
          width: "100%",
          position: "relative"
        }}
      >
        <img
          src={Poster}
          alt="poster"
          style={{
            width: "50vw",
            height: "100%",
            minHeight: "100vh",
            objectFit: "cover",
          }}
        />

        <div style={{
          position: "absolute",
          background: "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
          width: "50vw",
          height: "100%",
          minHeight: "100vh",
        }}
        >
          <img
            src={LogoTextual}
            alt="logo"
            width={140}
            height={56}
            style={{marginTop: 24, marginLeft: 24 }}
          />

        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "#ffffff",
          width: "100%",
          maxWidth: "50%",
          minWidth: "50vw",
          margin: 0,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default FormContainer;
