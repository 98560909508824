import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../store/app/actions";

const Logout = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(logout());
    }, []);

    window.location.href = process.env.REACT_APP_STATIC_PAGES_URL;

    return true;
};

export default Logout;