import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Selector from "../components/common/Selector";
import LoaderGif from "../components/common/LoaderGif";
import NoData from "../components/common/NoData";
import NoDataIllustration from "../assets/illustration/undraw_a_moment_to_relax_bbpa.svg";
import NewPatchCards from "../components/NewPatchCards";
import BuyModal from "../components/common/buy-modal";
import { Button } from "antd";
import {
  fetchForests,
  getCartCounts,
  getCartItems,
} from "../store/forests/actions";

import "../new-patch.css";

const NewPatches = () => {
  const { newForests, cartItems, cartPayload } = useSelector(
    (state) => state.forests
  );
  const {
    user: { id },
  } = useSelector((state) => state.app);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const [forestsList, setForestList] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [selectedForestSource, setSelectedForestSource] = useState({});
  const [forestId, setForestId] = useState("");

  const [visible, setVisible] = useState(false);
  const open = () => setVisible(true);
  const onClose = () => setVisible(false);

  const noData = {
    title: "No Forest Units",
    message:
      "There are no new forests available. Please hold for a while, we will add them soon",
    imgSrc: NoDataIllustration,
    buttonText: "Zurück",
  };
  const finishLoading = () => {
    setPageLoading(false);
  };

  const getSelectedForestData = (sourceID) => {
    setForestId(sourceID);
    const forest = newForests.filter((item) => item.id === sourceID);
    setSelectedForestSource(forest[0]);
  };

  useEffect(() => {
    if (newForests && pageLoading) {
      const newForestsList = newForests;
      setForestList(newForestsList);
      var countries = newForestsList.map((forest) => {
        return forest["address"].country;
      });
      const listOfCountries = getList(countries);
      setCountriesOptions(listOfCountries);
      setSelectedRows(newForests);
    } else {
      return;
    }
  }, [newForests, pageLoading]);

  const getList = (countries) => {
    if (countries) {
      var list = countries.filter(function (item, pos) {
        return countries.indexOf(item) === pos;
      });
      if (list && list.length > 0) {
        list = list.map((item) => {
          return {
            title: item,
            description: `Displays ${item} forest patches`,
          };
        });
        list.splice(0, 0, {
          title: "All Countries",
          description: "Displays collective data for all forest patches",
        });
        return list;
      }
    }
  };

  const onChangeOption = (option) => {
    if (option === "All Countries") {
      setSelectedRows(forestsList);
    } else {
      const getSelectedForestList = forestsList.filter((forest) => {
        return (
          forest["address"].country.toLowerCase() === option.toLowerCase() &&
          forest
        );
      });
      setSelectedRows(getSelectedForestList);
    }
  };

  useEffect(() => {
    dispatch(getCartCounts(id));
  }, [cartItems]);

  useEffect(() => {
    setPageLoading(true);
    dispatch(fetchForests(finishLoading));
    dispatch(getCartItems(id));
  }, []);

  return pageLoading ? (
    <LoaderGif />
  ) : selectedRows.length > 0 ? (
    <div className="content-body">
      <div className="new-patch-div">
        <div className="left-div">
          <h2 style={{ color: "#274B28", textAlign:"left" }}>Verfügbare Wälder</h2>
          <p className="cardDescription" style={{ textAlign:"left" }}>
            Such dir den Wald aus der zu dir passt:
          </p>
        </div>

        {countriesOptions && countriesOptions.length > 0 && (
          <div className="right-div">
            <div className="textGray" style={{marginRight:"16px", fontSize:"20px"}}>Sortieren:</div>
            <Selector
              option={(opt) => onChangeOption(opt)}
              source={countriesOptions}
              onRight={true}
            />
          </div>
        )}
      </div>

      <NewPatchCards
        cartCount={cartItems}
        cartItems={cartPayload}
        source={selectedRows}
        setOpen={() => open()}
        setSource={(id) => getSelectedForestData(id)}
      />
      <div className="flex column justifyCenter alignCenter w-100 h-100">
        <span style={{ marginTop: 32, fontSize: 24,}}>
          Oder möchtest du forest.ly mit einer einmaligen Spende unterstützen, dann klicke hier:
        </span>
        <Button
          className="green-button"
          style={{ marginTop: 32}}
          onClick={() => window.location.href='https://www.paypal.com/donate/?hosted_button_id=U57HA26EPSHVN'}
        >
          Paypal
        </Button>
      </div>

      {visible && (
        <BuyModal
          visible={visible}
          source={selectedForestSource}
          sourceID={forestId}
          onClose={() => onClose()}
          afterSubmit={() => {
            onClose();
            setPageLoading(true);
            setTimeout(() => setPageLoading(false), 3000);
          }}
        />
      )}
    </div>
  ) : (
    <NoData source={noData} />
  );
};

export default NewPatches;
