export const UPDATE_SETTINGS = "settings/UPDATE_SETTINGS";
export const UPDATE_PROFILE_PICTURE = "settings/UPDATE_PROFILE_PICTURE";
export const UPDATE_USER_PASSWORD = "settings/UPDATE_USER_PASSWORD";
export const UPDATE_USER_ADDRESS = "settings/UPDATE_USER_ADDRESS";
export const UPLOAD_PROFILE_IMAGE = "settings/UPLOAD_PROFILE_IMAGE"

export const updateProfileSettings = (form, userId, success, error) => ({
  type: UPDATE_SETTINGS,
  payload: { form, userId, success, error },
});

export const updateUserAddress = (form, userId, success, error) => ({
  type: UPDATE_USER_ADDRESS,
  payload: { form, userId, success, error },
});

export const uploadProfileImage = (imageURL, userId, success, error) => ({
  type: UPLOAD_PROFILE_IMAGE,
  payload: { imageURL, userId, success, error },
});

export const updateUserPassword = (form, userId, success, error) => ({
  type: UPDATE_USER_PASSWORD,
  payload: {
    form,
    userId,
    success,
    error,
  },
});
