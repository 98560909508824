import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoaderGif from "../components/common/LoaderGif";
import NoData from "../components/common/NoData";
import NoDataIllustration from "../assets/illustration/undraw_a_moment_to_relax_bbpa.svg";
import LargeSlider from "../components/common/large-carousel/Slider";
import MapWithCircles from "../assets/graph-images/map-with-circles.svg";
import Tooltip from "../assets/illustration/tooltip.svg";
import Slider from "../components/common/patch-details-carousel/Slider";
import MapView from "../assets/illustration/map-view.jpg";
import MapCheckOutline from "../assets/icons/map-check-outline.svg";
import Iceland from "../assets/icons/island.svg";
import GoogleMapReact from "google-map-react";
import {
  getForestDetails,
  getForestSpeciesDetails,
  getForestCoordinatesDetails,
} from "../store/forests/actions";
import { history } from "../index";
import BuyModal from "../components/common/buy-modal";
import Map from "../components/common/Map";
import "../patch-details.css";

const PatchDetails = () => {
  const { forestDetails, forestSpecies, forestCoordinates } = useSelector(
    (state) => state.forests
  );

  const {
    location: { search },
  } = history;
  const [subscribedForest, setSubscribedForest] = useState(null);
  const { address, areaInSqMeters } = subscribedForest || {};
  const queryParam = search.replace("?", "");
  const { id, isNew } = JSON.parse(
    '{"' +
      queryParam
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
  const dispatch = useDispatch();
  // States
  const [pageLoading, setPageLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const open = () => setVisible(true);
  const onClose = () => setVisible(false);
  const [speciesList, setSpeciesList] = useState([]);

  const benefits = [
    {
      bgColor: "#FFF8E1",
      imgAlt: "island",
      title: "Artenvielfalt",
      description:
        "Dein Waldstück ist Wildnis und Zuhause zugleich. Ein Reservat der Sicherheit. Für Tiere und Pflanzen",
    },
    {
      bgColor: "rgba(0, 207, 232, 0.12)",
      imgAlt: "map-check-outline",
      title: "Klimaschutz",
      description:
        "Mischwälder sind wiederstandsfähiger und speichern mehr CO₂. Sie sind ein wichtiger Teil der Lösung.",
    },
    {
      bgColor: "#FFF8E1",
      imgAlt: "island",
      title: "Nachhaltig",
      description:
        "Deine Spende erschafft Arbeitsplätze und hilft und weltweit noch mehr erodierte Flächen in Wildnis zu verwandeln.",
    },
  ];
  const AppleTree =
    "https://cdn0.iconfinder.com/data/icons/trees-19/50/11-512.png";
  const OakTree =
    "https://res.cloudinary.com/dd1o7dzwv/image/upload/v1660044928/patchDetailsTrees/SessileOakSilhouette_rhopci.svg";
  const AshTree =
    "https://res.cloudinary.com/dd1o7dzwv/image/upload/v1660044861/patchDetailsTrees/warszawianka-Ash-leaves_qmzxqn.svg";
  const BirchTree =
    "https://res.cloudinary.com/dd1o7dzwv/image/upload/v1660045027/patchDetailsTrees/birch-leaf1-black_davghz.svg";
  const BeechTree =
    "https://res.cloudinary.com/dd1o7dzwv/image/upload/v1660044863/patchDetailsTrees/warszawianka-Beech-leaf_hfcaro.svg";
  const AlderTree =
    "https://res.cloudinary.com/dd1o7dzwv/image/upload/v1660044901/patchDetailsTrees/AlderBuckthornSilhouette_lcxf7c.svg";
  const SpruceTree =
    "https://res.cloudinary.com/dd1o7dzwv/image/upload/v1660044872/patchDetailsTrees/NorwaySpruce_xdvoah.svg";
  const IpeTree =
    "https://res.cloudinary.com/dd1o7dzwv/image/upload/v1664490819/patchDetailsTrees/ipe_retdgm.svg";  

  const noData = {
    title: "No Forest Units",
    message:
      "You have no forest units. Sposor your first forest units to see more details",
    imgSrc: NoDataIllustration,
    buttonText: "Sponsor Forest Units",
  };

  const finishLoading = () => {
    setPageLoading(false);
  };

  const stopPageLoading = () => {
    setTimeout(finishLoading, 2000);
  };

  useEffect(() => {
    if (forestDetails) {
      const details = forestDetails;
      setSubscribedForest(details);
    }
  }, [forestDetails]);

  useEffect(() => {
    setPageLoading(true);
    if (isNew === "false") {
      dispatch(getForestCoordinatesDetails(id, finishLoading));
      dispatch(getForestSpeciesDetails(id, finishLoading));
    }
    dispatch(getForestDetails(id, finishLoading));
  }, []);
  useEffect(() => {
    if (forestSpecies !== null) {
      const species = forestSpecies;
      const list = species.map((specie) => ({
        ...specie,
        imageRef:
          specie.specieName === "apple trees"
            ? AppleTree
            : specie.specieName === "Traubeneiche (Quercus petraea)"
            ? OakTree
            : specie.specieName === "Birke (Betula Pendula)"
            ? BirchTree
            : specie.specieName === "Buche (Fagus sylvatica)"
            ? BeechTree
            : specie.specieName === "Faulbaum (Frangula alnus)"
            ? AlderTree
            : specie.specieName === "Fichte (Pinus sylvestris)"
            ? SpruceTree
            : specie.specieName === "Eberesche (Sorbus aucuparia)"
            ? AshTree
            : specie.specieName === "ipê (Handroanthus chrysotrichus)"
            ? IpeTree
            : null,
      }));
      setSpeciesList(list);
    }
  }, [forestSpecies]);

  return pageLoading ? (
    <LoaderGif />
  ) : subscribedForest && forestCoordinates ? (
    <div className="content-body patch-detail-outer-div">
      <div className="patch-details">
        <LargeSlider setOpen={() => open()} dataSource={subscribedForest} />
      </div>

      {isNew === "false" && (
        <div className="patch-bottom-row2" style={{ marginTop: "16px" }}>
          <div
            // className="flex column"
            // style={{ marginLeft: "32px", minWidth: "0px" }}
            className="patch-bottom-row2-div1"
          >
            <h2 className="textGreen" style={{ fontWeight: 700 }}>
              Dein Waldstück
            </h2>

            <div
              className="map"
              style={{
                // position: "relative",
                marginTop: 16,
              }}
            >
              <Map
                longitude={
                  forestCoordinates ? forestCoordinates[0].longitude : null
                }
                latitude={
                  forestCoordinates ? forestCoordinates[0].latitude : null
                }
                zoom={11}
                width="100%"
                height="400px"
                // className="map"
                polygone={
                  forestCoordinates &&
                  forestCoordinates[0] &&
                  forestCoordinates[0].polygone &&
                  forestCoordinates[0].polygone.length
                    ? forestCoordinates[0].polygone
                    : []
                }
              />
            </div>
          </div>

          {speciesList && speciesList.length > 0 && (
            <div
              // className="flex column"
              // style={{
              //   marginLeft: 32,
              // }}
              className="patch-bottom-row2-div2"
            >
              <h2 className="textGreen" style={{ fontWeight: 700 }}>
                Wusstest du schon?
              </h2>

              <div>
                <Slider dataSource={speciesList} />
              </div>
            </div>
          )}
        </div>
      )}

      {isNew === "true" && (
        <div className="map_facts">
          <div className="facts">
            <h2 className="textGreen" style={{ fontWeight: 700 }}>
              Die Wirkung deines Waldstücks
            </h2>
            <p className="textGray" style={{ marginTop: 8, marginBottom: 0 }}>
              Detailierte Darstellung der positiven Effekte
            </p>
            <div
              className="w-100"
              style={{
                background: "#ffffff",
                borderRadius: 8,
                boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.12)",
                height: "100%",
                minHeight: 300,
                marginTop: 16,
                padding: "28px 0 0",
              }}
            >
              {benefits &&
                benefits.map((value, index) => (
                  <div
                    className="flex column justifyStart"
                    key={index + 1}
                    style={{ padding: "0px 24px 24px" }}
                  >
                    <div className="flex">
                      <div
                        className="flex alignCenter justifyCenter"
                        style={{
                          background: value.bgColor,
                          height: 60,
                          minWidth: 60,
                          borderRadius: "50%",
                        }}
                      >
                        {value.imgAlt === "map-check-outline" && (
                          <img
                            src={MapCheckOutline}
                            alt={value.imgAlt}
                            height={24}
                            width={24}
                          />
                        )}
                        {value.imgAlt === "island" && (
                          <img
                            src={Iceland}
                            alt={value.imgAlt}
                            height={24}
                            width={24}
                          />
                        )}
                      </div>
                      <div className="flex column" style={{ marginLeft: 24 }}>
                        <h3 style={{ color: "#424242" }}> {value.title}</h3>
                        <p
                          className="small-p"
                          style={{ marginTop: 8, fontSize: 12 }}
                        >
                          {value.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="maps">
            <Map
              longitude={
                forestCoordinates ? forestCoordinates[0].longitude : null
              }
              latitude={
                forestCoordinates ? forestCoordinates[0].latitude : null
              }
              zoom={11}
              width="100%"
              // height="500px"
              polygone={
                forestCoordinates &&
                forestCoordinates[0] &&
                forestCoordinates[0].polygone &&
                forestCoordinates[0].polygone.length
                  ? forestCoordinates[0].polygone
                  : []
              }
            />
          </div>
        </div>
      )}

      {visible && (
        <BuyModal
          visible={visible}
          source={forestDetails}
          sourceID={id}
          onClose={() => onClose()}
          afterSubmit={() => {
            onClose();
            setPageLoading(true);
            stopPageLoading();
          }}
        />
      )}
    </div>
  ) : (
    <NoData source={noData} />
  );
};

export default PatchDetails;
