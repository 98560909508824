import React, { useState, useEffect } from "react";
import CashUSD from "../../assets/icons/cash-usd-outline.svg";
import AreaOutline from "../../assets/icons/area-check-outline.svg";
import PencileOutline from "../../assets/icons/pencil-outline.svg";
import Receipt from "../../assets/icons/receipt.svg";
import LightCalendar from "../../assets/icons/calendar-light.svg";
import DarkCalendar from "../../assets/icons/calendar-dark.svg";
import EditPayment from "./EditPayment";
import PaymentHistory from "./PaymentHistory";
import moment from "moment";

// Payment methods Icons
import UNIONPAY from "../../assets/payment-methods/union.svg";
import AMEX from "../../assets/payment-methods/amex.svg";
import DINERS from "../../assets/payment-methods/diners.svg";
import DISCOVER from "../../assets/payment-methods/discover.svg";
import JBC from "../../assets/payment-methods/jbc.svg";
import MASTERCARD from "../../assets/payment-methods/master.svg";
import VISACARD from "../../assets/payment-methods/visa.svg";

const PaymentCard = ({ dataSource, paymentMethods, afterUpdate }) => {
  const [subscriptions, setSubscriptions] = useState(null);
  const [sourceId, setSourceId] = useState(null);
  const [forestId, setForestId] = useState(null);
  const [forestName, setForestName] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [showEditPayment, setShowEditPayment] = useState(false);
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);

  const checkDateState = (dateToBeCompared) => {
    const isBefore = moment(dateToBeCompared).isBefore(moment());
    const isToday = moment(dateToBeCompared).isSame(moment());
    const isFuture = moment(dateToBeCompared).isAfter(moment());

    if (isBefore && !isToday && !isFuture) {
      return true;
    } else if (!isBefore && isToday && !isFuture) {
      return true;
    } else if (!isBefore && !isToday && isFuture) {
      return false;
    }
  };

  useEffect(() => {
    setSubscriptions(dataSource);
  }, [dataSource]);

  return (
    <div className="wrapper">
      {subscriptions &&
        subscriptions.map((subscriptionItem, index) => (
          <div className="payment-wrapper" key={index + 1}>
            <div className="payment-card" key={`payment-card-` + index + 1}>
              <div className="payment-card-left-div ">
                <h3 className="textGreen">{subscriptionItem.forest["name"]}</h3>
                {subscriptionItem["address"] && (
                  <p
                    className="textGray"
                    style={{ margin: "6px 0 0", textTransform: "capitalize" }}
                  >
                    {`${subscriptionItem["address"].city} | ${subscriptionItem["address"].country}`}
                  </p>
                )}
              </div>
              <div className="payment-card-right-div">
                <div className="payment-detail-wrapper">
                  <div className="payment-details">
                    <div className="flex alignCenter w-100" style={{margin:"10px 0px"}}>
                      <div className="payment-circle-icon">
                        <img
                          src={AreaOutline}
                          alt={AreaOutline}
                          height={24}
                          width={24}
                        />
                      </div>
                      <div className="flex column payment-margin">
                        <h3 className="textGreen">
                          {subscriptionItem.areaInSqMeters}
                        </h3>
                        <p className="small-p">Quadratmeter</p>
                      </div>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div className="payment-circle-icon">
                        <img
                          src={CashUSD}
                          alt={CashUSD}
                          height={24}
                          width={24}
                        />
                      </div>
                      <div className="flex column payment-margin">
                        <h3 className="textGreen">{`${
                          subscriptionItem.currency
                        } ${(
                          Number(subscriptionItem.areaInSqMeters) *
                          Number(
                            subscriptionItem.subscriptionPlan["amountPerSqm"]
                          )
                        ).toFixed(2)}`}</h3>
                        <p
                          className="small-p textGreen"
                          style={{
                            margin: "8px 0 0",
                          }}
                        >
                          {`Spendenbetrag`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-div">
                  <div className="flex w-100">
                    <div
                      className="payment-light-circle-icon"
                      onClick={() => {
                        setSourceId(subscriptionItem.stripeSubscriptionId);
                        setForestName(subscriptionItem.forest["name"]);
                        setForestId(subscriptionItem.forestId);
                        setShowPaymentHistory(true);
                      }}
                    >
                      <img src={Receipt} alt={Receipt} height={24} width={24} />
                    </div>
                  </div>
                  {subscriptionItem.expiryDate && (
                  <div className="flex w-100" style={{ marginLeft: 24 }}>
                    <div
                      className="payment-light-circle-icon"
                      onClick={() => {
                        setSourceId(subscriptionItem.id);
                        setShowEditPayment(true);
                      }}
                    >
                      <img
                        src={PencileOutline}
                        alt={PencileOutline}
                        height={24}
                        width={24}
                      />
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
            {checkDateState(subscriptionItem.expiryDate) ? (
              <div
                className="overdue"
                style={{ boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.12)" }}
              >
                <img
                  src={LightCalendar}
                  alt={LightCalendar}
                  height={24}
                  width={24}
                />
                <p
                  style={{
                    fontSize: 16,
                    lineHeight: "20px",
                    margin: "0px 0px 0px 8px",
                  }}
                >
                  {`Your payment is overdue. Please recharge your payment to continue`}
                </p>
              </div>
            ) : (
              <div
                className={
                  subscriptionItem.isActive ? "within-due" : "in-active"
                }
                style={{ boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.12)" }}
              >
                {subscriptionItem.expiryDate && (
                <div className="flex alignCenter w-100" >
                  <img
                    src={DarkCalendar}
                    alt={DarkCalendar}
                    height={24}
                    width={24}
                  />
                  <p
                    className="small-p"
                    style={{
                      margin: "0px 0px 0px 8px",
                      color: "#424242",
                    }}
                  >
                    {subscriptionItem.isActive
                      ? `Your next payment is `
                      : `Your Subscription will end on `}
                    {subscriptionItem.isActive ? (
                      <b style={{ color: "#424242" }}>
                        {`${subscriptionItem.currency} ${(
                          Number(subscriptionItem.areaInSqMeters) *
                          Number(
                            subscriptionItem.subscriptionPlan["amountPerSqm"]
                          )
                        ).toFixed(2)}`}
                      </b>
                    ) : (
                      <></>
                    )}
                    {subscriptionItem.isActive ? ` is due on ` : ""}
                    <b style={{ color: "#424242" }}>
                      {subscriptionItem.expiryDate}
                    </b>
                  </p>
                </div>
                )}
                {/* <div className="flex justifyEnd alignCenter w-100">
                  {subscriptionItem.paymentMethod["brand"] === "amex" && (
                    <img
                      src={AMEX}
                      alt="payment-method-icon"
                      height={40}
                      width={40}
                    />
                  )}

                  {subscriptionItem.paymentMethod["brand"] === "mastercard" && (
                    <img
                      src={MASTERCARD}
                      alt="payment-method-icon"
                      height={40}
                      width={40}
                    />
                  )}

                  {subscriptionItem.paymentMethod["brand"] === "discover" && (
                    <img
                      src={DISCOVER}
                      alt="payment-method-icon"
                      height={40}
                      width={40}
                    />
                  )}

                  {subscriptionItem.paymentMethod["brand"] === "diners" && (
                    <img
                      src={DINERS}
                      alt="payment-method-icon"
                      height={40}
                      width={40}
                    />
                  )}

                  {subscriptionItem.paymentMethod["brand"] === "jcb" && (
                    <img
                      src={JBC}
                      alt="payment-method-icon"
                      height={40}
                      width={40}
                    />
                  )}

                  {subscriptionItem.paymentMethod["brand"] === "unionpay" && (
                    <img
                      src={UNIONPAY}
                      alt="payment-method-icon"
                      height={40}
                      width={40}
                    />
                  )}

                  {subscriptionItem.paymentMethod["brand"] === "visa" && (
                    <img
                      src={VISACARD}
                      alt="payment-method-icon"
                      height={40}
                      width={40}
                    />
                  )}
                  <p className="para">
                    <strong style={{ color: "#424242" }}>
                      {subscriptionItem.paymentMethod["cardNumber"]}
                    </strong>
                  </p>
                </div> */}
              </div>
            )}
          </div>
        ))}
      {showEditPayment && (
        <EditPayment
          source={subscriptions}
          sourceId={sourceId}
          paymentMethods={paymentMethods}
          paymnetMethodId={paymentMethodId}
          visible={showEditPayment}
          afterUpdate={afterUpdate}
          onClose={() => {
            setShowEditPayment(false);
          }}
        />
      )}

      {showPaymentHistory && (
        <PaymentHistory
          sourceId={sourceId}
          forestName={forestName}
          forestId={forestId}
          visible={showPaymentHistory}
          onClose={() => setShowPaymentHistory(false)}
        />
      )}
    </div>
  );
};

export default PaymentCard;
