import React from "react";
import Slider from "./common/new-small-carousel/Slider";
import ViewDetailsIcon from "../assets/icons/open-in-new.svg";
import CartPlus from "../assets/icons/cart-plus.svg";
import { history } from "../index";

const PatchCard = ({ source, clickHandler, newPatch = true, count }) => {
  const {
    images,
    id,
    maximumUnitsInSqAvailableForSale,
    name,
    SubscriptionPlans,
    address: { country },
  } = source;
  const plan = SubscriptionPlans.filter(
    (item) => item.subscriptionType === "monthly"
  );
  const { currency, amountPerSqm } = plan[0] || {};

  const calculateAcers = (sqm) => (sqm / 4046.86).toFixed(1);

  return (
    <div className="col">
      <Slider dataSource={images} />
      <div style={{ padding: "14px 16px 14px 24px" }}>
        <div className="flex column">
          <div className="flex alignCenter">
            <h4 className="textGreen">{name}</h4>
            <img
              src={ViewDetailsIcon}
              alt={ViewDetailsIcon}
              height={24}
              width={24}
              style={{ marginLeft: 24, cursor: "pointer" }}
              onClick={() => {
                history.push(
                  `/dashboard/patch/details?id=${id}&isNew=${newPatch}`
                );
              }}
            />
          </div>
          <p className="small-p textGray" style={{ margin: "8px 0" }}>
            {country}
            <span style={{ margin: "0 6px" }}>|</span>
            {` ${calculateAcers(maximumUnitsInSqAvailableForSale)} Hektar`}
          </p>
        </div>

        {count > 0 ? (
          <div className="flex column">
            <h3 style={{ color: "#424242" }}>
              {`${maximumUnitsInSqAvailableForSale} qm`}
              <span className="textGray"> verfügbar</span>
            </h3>
            <div
              style={{
                background:
                  maximumUnitsInSqAvailableForSale > 10000
                    ? "#8BC34A"
                    : "#FF9800",
                borderRadius: "100px",
                margin: "8px 0 16px",
                width: 80,
                height: 8,
              }}
            />
          </div>
        ) : (
          <div className="flex column">
            <h3 style={{ color: "#424242", fontWeight: 700, marginTop: 12 }}>
              {`${currency} ${amountPerSqm}`}
            </h3>
            <p
              className="small-p"
              style={{ color: "#9E9E9E", margin: "4px 0 0" }}
            >
              {`Minimale Spende pro qm`}
            </p>
          </div>
        )}

        <div className="flex alignCenter mt-16">
          <div
            className="buyNow"
            onClick={() => {
              clickHandler(id);
              localStorage.setItem("patchid", id);
            }}
          >
            <h3 style={{ color: "#274B28" }}>Waldpatenschaft</h3>
          </div>
          {/* <div className="cartIcon" onClick={() => { clickHandler(id);  }}>
            <img
              src={CartPlus}
              alt={CartPlus}
              height={44}
              width={44}
              style={{ cursor: "pointer" }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PatchCard;
