import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import TickIcon from "../../assets/icons/done.png";
import AlertIcon from "../../assets/icons/alert.png";

const Notification = ({ open, onClose, type, message, timeout }) => {
  const { pathname } = useSelector((state) => state.router.location);
  useEffect(() => {
    if (open && timeout > 0) {
      setTimeout(onClose, timeout);
    }
  }, [open]);


  const checkForOnlyCartPaths = () => {
    if (pathname === "/dashboard/patches/cart/review") {
      return true;
    } else if (pathname === "/dashboard/patches/cart/personal-information") {
      return true;
    } else if (pathname === "/dashboard/patches/cart/payment") {
      return true;
    }
  };


  return (
    open && (
      <div
        className={type}
        style={{
          display: "flex",
          flexDirection: "row",
          height: 60,
          alignItems: "center",
          position: "fixed",
          bottom: 0,
          left: checkForOnlyCartPaths() ? 0 : 272,
          width: checkForOnlyCartPaths() ? "100%" : "calc(100% - 272px)",
          zIndex: 1000000,
          justifyContent: "center",
        }}
      >
        <img
          src={type === "success-toast" ? TickIcon : AlertIcon}
          alt="notification-icon"
          style={{
            height: type === "success-toast" ? null : "24px",
            width: type === "success-toast" ? null : "32px",
          }}
        />
        <p
          style={{
            textAlign: "center",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#ffffff",
            margin: "0px 24px",
          }}
        >
          {message}
        </p>
      </div>
    )
  );
};

export default Notification;
