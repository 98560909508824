import { put, takeEvery, call } from "redux-saga/effects";
import api from "../../services/api/ApiService";
import {
  FETCH_FORESTS,
  ADD_TO_CART,
  GET_CART_ITEMS_COUNT,
  setForests,
  setCartCount,
  GET_FOREST_DETAILS,
  setForestDetails,
  GET_FOREST_SPECIES_DETAILS,
  setForestSpeciesDetails,
  GET_FOREST_COORDINATE_DETAILS,
  setForestCoordinatesDetails,
  GET_CART_ITEMS,
  setCartItems,
  DELETE_CART_ITEM,
  UPDATE_CART_ITEM,
  ADD_PAYMENT_METHOD,
  FETCH_PAYMENT_METHODS,
  setPaymentMethods,
  PAYMENT_CHECKOUT,
  APPLY_PROMO_CODE,
  GET_FOREST_SPECIES_LIST,
  GET_FORESTS_METRICES,
  setForestsMetrices,
} from "./actions";
import { createNotification } from "../app/actions";

function fetchForests() {
  const resp = api.get(`/forests`);
  return resp;
}

function* getForests({ payload: { stopLoading } }) {
  try {
    const forests = yield call(fetchForests);
    const {
      data: { payload, metadata },
    } = forests;

    if (metadata.code === 200) {
      yield put(setForests(payload));
      yield call(stopLoading);
    } else {
      yield call(stopLoading);
    }
  } catch (e) {
    if (e.response) {
      yield call(stopLoading);
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function fetchMetrices() {
  const resp = api.get(`/api/dashboard/metrics`);
  return resp;
}

function* getForestMetrices({ payload: { stopLoading } }) {
  try {
    const metrices = yield call(fetchMetrices);
    const {
      data: { payload, metadata },
    } = metrices;

    if (metadata.code === 200) {
      yield put(setForestsMetrices(payload));
      yield call(stopLoading);
    } else {
      yield call(stopLoading);
    }
  } catch (e) {
    if (e.response) {
      yield call(stopLoading);
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function setIntoCart(values) {
  const resp = api.post(`/api/cartItem`, values);
  return resp;
}

function* addForestIntoCart({ payload: { values, onSuccess, onError } }) {
  try {
    const forests = yield call(setIntoCart, values);
    const {
      data: { payload, metadata },
    } = forests;

    if (metadata.code === 200) {
      yield put(setCartCount(payload.count));
      yield call(onSuccess);
      // yield put(
      //   createNotification(
      //     "success-toast",
      //     "Added forest into cart successfully"
      //   )
      // );
    } else {
      const { message } = metadata;
      yield put(createNotification("error-toast", message));
    }
  } catch (e) {
    if (e.response) {
      const {
        metadata: { message },
      } = e.response.data;
      yield call(onError, message);
    }
  }
}

function getCartItemsCount(userId) {
  const resp = api.get(`/api/cartItem/${userId}/counts`);
  return resp;
}

function* setCartItemsCount({ payload: { userId } }) {
  try {
    const forests = yield call(getCartItemsCount, userId);
    const {
      data: { payload, metadata },
    } = forests;

    if (metadata.code === 200) {
      yield put(setCartCount(payload.count));
    } else {
      const { message } = metadata;
      yield put(createNotification("error-toast", message));
    }
  } catch (e) {
    if (e.response) {
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function getForest(forestId) {
  const resp = api.get(`/forest/${forestId}`);
  return resp;
}

function* getForestDetails({ payload: { forestId, stopLoading } }) {
  try {
    const forests = yield call(getForest, forestId);
    const {
      data: { payload, metadata },
    } = forests;

    if (metadata.code === 200) {
      yield put(setForestDetails(payload.forest[0]));
      yield call(stopLoading);
    } else {
      yield call(stopLoading);
    }
  } catch (e) {
    if (e.response) {
      yield call(stopLoading);
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function getForestSpecies(forestId) {
  const resp = api.get(`/api/forest/${forestId}/forestSpecies`);
  return resp;
}

function* getForestSpeciesDetails({ payload: { forestId, stopLoading } }) {
  try {
    const forests = yield call(getForestSpecies, forestId);
    const {
      data: { payload, metadata },
    } = forests;

    if (metadata.code === 200) {
      yield put(setForestSpeciesDetails(payload));
      yield call(stopLoading);
    } else {
      yield call(stopLoading);
    }
  } catch (e) {
    if (e.response) {
      yield call(stopLoading);
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function getForestCoordinates(forestId) {
  const resp = api.get(`/forest/${forestId}/coordinates`);
  return resp;
}

function* getForestCoordinatesDetails({ payload: { forestId, stopLoading } }) {
  try {
    const forests = yield call(getForestCoordinates, forestId);
    const {
      data: { payload, metadata },
    } = forests;

    if (metadata.code === 200) {
      yield put(setForestCoordinatesDetails(payload));
      yield call(stopLoading);
    } else {
      yield call(stopLoading);
    }
  } catch (e) {
    if (e.response) {
      yield call(stopLoading);
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function getFromCarts(userId) {
  const resp = api.get(`/api/cartItem/${userId}`);
  return resp;
}

function* getCartItems({ payload: { userId } }) {
  try {
    const forests = yield call(getFromCarts, userId);
    const {
      data: { payload, metadata },
    } = forests;

    if (metadata.code === 200) {
      yield put(setCartItems(payload));
    } else {
      const { message } = metadata;
      yield put(createNotification("error-toast", message));
    }
  } catch (e) {
    if (e.response) {
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function deleteFromCart(cartId) {
  const resp = api.delete(`/api/cartItem/${cartId}`);
  return resp;
}

function* deleteCartItem({ payload: { cartId, onSuccess, onError } }) {
  try {
    const cartDeleted = yield call(deleteFromCart, cartId);
    const {
      data: { metadata },
    } = cartDeleted;

    if (metadata.code === 200) {
      yield call(onSuccess);
      yield put(
        createNotification(
          "success-toast",
          "Forest deleted from cart successfully"
        )
      );
    } else {
      const { message } = metadata;
      yield put(createNotification("error-toast", message));
    }
  } catch (e) {
    if (e.response) {
      const {
        metadata: { message },
      } = e.response.data;
      yield call(onError);
      yield put(createNotification("error-toast", message));
    }
  }
}

function updateIntoCartItem(cartId, payload) {
  const resp = api.put(`/api/cartItem/${cartId}`, payload);
  return resp;
}

function* updateCartItem({ payload: { cartId, payload, onSuccess } }) {
  try {
    const cartUpdated = yield call(updateIntoCartItem, cartId, payload);
    const {
      data: { metadata },
    } = cartUpdated;

    if (metadata.code === 200) {
      yield call(onSuccess);
      yield put(
        createNotification("success-toast", "Gewählte Waldpatenschaft aktualisiert")
      );
    } else {
      const { message } = metadata;
      yield put(createNotification("error-toast", message));
    }
  } catch (e) {
    if (e.response) {
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function insertPaymentMethod(userId, values) {
  const resp = api.post(`/api/user/${userId}/addPaymentMethod`, values);
  return resp;
}

function* addPaymentMethod({ payload: { userId, values, onSuccess } }) {
  try {
    const methodCreated = yield call(insertPaymentMethod, userId, values);
    const {
      data: { metadata },
    } = methodCreated;

    if (metadata.code === 200) {
      yield call(onSuccess);
      yield put(
        createNotification(
          "success-toast",
          "A new payment method has been added successfully"
        )
      );
    } else {
      const { message } = metadata;
      yield put(createNotification("error-toast", message));
    }
  } catch (e) {
    if (e.response) {
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function getPaymentMethods(userId) {
  const resp = api.get(`/api/user/${userId}/paymentMethods`);
  return resp;
}

function* fetchPaymentMethods({ payload: { userId, onSuccess } }) {
  try {
    const paymentMethods = yield call(getPaymentMethods, userId);
    const {
      data: { payload, metadata },
    } = paymentMethods;

    if (metadata.code === 200) {
      var res = payload;
      yield put(setPaymentMethods(res));
      yield call(onSuccess);
    }
  } catch (e) {
    if (e.response) {
      yield put(
        createNotification("error-toast", "Failed to fetch payment methods")
      );
    }
  }
}

function makePayment(params) {
  const resp = api.post(`/api/checkout`, params);
  return resp;
}

function* paymentCheckoutAction({ payload: { params, onSuccess, onError } }) {
  try {
    const isCheckoutDone = yield call(makePayment, params);
    const {
      data: {
        metadata: { code, message },
        payload
      },
    } = isCheckoutDone;
    if (code === 200) {
      const { sessionUrl } = payload;
      yield call(onSuccess, sessionUrl);
    } else {
      yield call(onError);
      yield put(createNotification("error-toast", message));
    }
  } catch (e) {
    if (e.response) {
      yield call(onError);
      yield put(
        createNotification("error-toast", "Spende fehlgeschlagen")
      );
    }
  }
}

function getDiscount(code) {
  const resp = api.get(`/api/promotionCode/verify/${code}`);
  return resp;
}

function* getPromotionDiscount({ payload: { code, onSuccess, onError } }) {
  try {
    const isCheckoutDone = yield call(getDiscount, code);
    const {
      data: { metadata, payload },
    } = isCheckoutDone;
    if (metadata.code === 200) {
      const { percentageDiscount, couponCode } = payload[0];
      yield call(onSuccess, percentageDiscount, couponCode);
    } else {
      yield call(onError);
      yield put(createNotification("error-toast", metadata.message));
    }
  } catch (e) {
    if (e.response) {
      yield call(onError);
      yield put(
        createNotification("error-toast", "Failed to apply discount code")
      );
    }
  }
}

function getSpecies() {
  const resp = api.get(`/api/forestSpecies`);
  return resp;
}

function* getForestSpeciesList() {
  try {
    const forestSpecies = yield call(getSpecies);
    const {
      data: { payload, metadata },
    } = forestSpecies;

    if (metadata.code === 200) {
      yield put(setForestSpeciesDetails(payload));
    }
  } catch (e) {
    if (e.response) {
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function* ForestsSaga() {
  yield takeEvery(FETCH_FORESTS, getForests);
  yield takeEvery(GET_CART_ITEMS_COUNT, setCartItemsCount);
  yield takeEvery(ADD_TO_CART, addForestIntoCart);
  yield takeEvery(GET_FOREST_DETAILS, getForestDetails);
  yield takeEvery(GET_FOREST_SPECIES_DETAILS, getForestSpeciesDetails);
  yield takeEvery(GET_FOREST_COORDINATE_DETAILS, getForestCoordinatesDetails);
  yield takeEvery(GET_FOREST_SPECIES_LIST, getForestSpeciesList);
  yield takeEvery(GET_CART_ITEMS, getCartItems);
  yield takeEvery(DELETE_CART_ITEM, deleteCartItem);
  yield takeEvery(UPDATE_CART_ITEM, updateCartItem);
  yield takeEvery(ADD_PAYMENT_METHOD, addPaymentMethod);
  yield takeEvery(FETCH_PAYMENT_METHODS, fetchPaymentMethods);
  yield takeEvery(PAYMENT_CHECKOUT, paymentCheckoutAction);
  yield takeEvery(APPLY_PROMO_CODE, getPromotionDiscount);
  yield takeEvery(GET_FORESTS_METRICES, getForestMetrices);
}

export default ForestsSaga;
