export const FETCH_USER_SUBSCRIPTIONS =
  "subscriptions/FETCH_USER_SUBSCRIPTIONS";
export const SET_USER_SUBSCRIPTIONS = "subscriptions/SET_USER_SUBSCRIPTIONS";
export const RENEW_SUBSCRIPTION = "subscriptions/RENEW_SUBSCRIPTION";
export const FETCH_PAYMENTS = "subscriptions/FETCH_PAYMENTS";
export const SET_PAYMENTS = "subscriptions/SET_PAYMENTS";
export const UNSUBSCRIBEOF_SUBSCRIPTION =
  "subscriptions/UNSUBSCRIBEOF_SUBSCRIPTION";
export const FETCH_SUBS_PAYMENTS = "subscriptions/FETCH_SUBS_PAYMENTS";

export const fetchSubscriptions = (userId, stopLoading) => ({
  type: FETCH_USER_SUBSCRIPTIONS,
  payload: { userId, stopLoading },
});

export const setSubscriptions = (payload) => ({
  type: SET_USER_SUBSCRIPTIONS,
  payload,
});

export const fetchPayments = (userId, stopLoading) => ({
  type: FETCH_PAYMENTS,
  payload: { userId, stopLoading },
});
export const fetchSubsPayments = (subsId, stopLoading) => ({
  type: FETCH_SUBS_PAYMENTS,
  payload: { subsId, stopLoading },
});
export const setPayments = (payload) => ({
  type: SET_PAYMENTS,
  payload,
});

export const renewSubscription = (params, onResult) => ({
  type: RENEW_SUBSCRIPTION,
  payload: { params, onResult },
});

export const unSubscribeofSubscription = (params, onResult) => ({
  type: UNSUBSCRIBEOF_SUBSCRIPTION,
  payload: { params, onResult },
});
