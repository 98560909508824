import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { history } from "../../index";

const NoData = ({ source }) => {
  const { pathname } = useSelector((state) => state.router.location);
  const [redirectURL, setRedirectURL] = useState();

  useEffect(() => {
    if (pathname === "/dashboard/patches/new") {
      setRedirectURL("/dashboard/patches");
    } else if (pathname === "/dashboard/patches") {
      setRedirectURL("/dashboard/patches/new");
    } else if (pathname === "/dashboard/payments") {
      setRedirectURL("/dashboard/patches/new");
    } else if (pathname === "/dashboard/home") {
      setRedirectURL("/dashboard/patches/new");
    }
  }, [pathname]);

  return (
    <div className="flex column justifyCenter alignCenter w-100 h-100"
      style={{
        margin: "auto",
        maxWidth: 496,
        textAlign: "center",
      }}
    >
      <img src={source.imgSrc} alt="no-data-illustration" width={280} height={280} />
      <h2 className="textGreen">{source.title}</h2>
      <p className="textGray" style={{ textAlign: "center", width: "80%" }}>
        {source.message}
      </p>
      <Button
        className="green-button"
        style={{ marginTop: 24 }}
        onClick={() => history.push(redirectURL)}
      >
        {source.buttonText}
      </Button>
    </div>
  );
};

export default NoData;
