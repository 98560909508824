import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, Select, notification, Modal } from "antd";
import Camera from "../assets/icons/camera.svg";
import CrossIcon from "../assets/icons/cross.png";
import Placeholder from "../assets/placeholder.png";
import ArrowDown from "../assets/icons/chevron-down.svg";
import Loader from "react-loader-spinner";
import Cropper from "react-cropper";
import { createNotification } from "../store/app/actions";
import SettingsTabs from "../components/common/SettingsTabs";
import LoadingFormButton from "../components/common/LoadingFormButton";
import {
  updateProfileSettings,
  uploadProfileImage,
  updateUserAddress,
  updateUserPassword,
} from "../store/settings/actions";
import "../../src/settings.css";
export const DownArrow = ({ clickAction }) => {
  return (
    <img
      src={ArrowDown}
      alt="arrow-down"
      style={{
        height: 28,
        width: 28,
        cursor: "pointer",
        marginTop: "-0.4rem",
        marginLeft: "-0.4rem",
      }}
      onClick={clickAction}
    />
  );
};

const Settings = () => {
  const { user } = useSelector((state) => state.app);
  const [activeTabName, setActiveTabName] = useState(null);
  const formRef = useRef();
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [modalOptions, setModalOptions] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [cropper, setCropper] = useState();
  const [uploadImage, setUploadSubmitting] = useState(false);
  const [enabled, setEnabled] = useState({
    firstName: false,
    lastName: false,
    emailAddress: false,
    language: false,
  });

  const [enabledForPassword, setEnabledForPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [enabledForAddress, setEnabledForAddress] = useState({
    houseNumber: false,
    streetName: false,
    zipCode: false,
    city: false,
    country: false,
  });

  const enablingStatus = (enablerObject) => {
    if (
      enablerObject.firstName ||
      enablerObject.lastName ||
      enablerObject.language
    ) {
      return false;
    }
    return true;
  };

  const enablingStatusForPassword = (enablerObject) => {
    if (
      enablerObject.oldPassword &&
      enablerObject.newPassword &&
      enablerObject.confirmPassword
    ) {
      return false;
    }

    return true;
  };

  const enablingStatusForAddress = (enablerObject) => {
    if (
      enablerObject.houseNumber ||
      enablerObject.streetName ||
      enablerObject.zipCode ||
      enablerObject.city ||
      enablerObject.country
    ) {
      return false;
    }

    return true;
  };

  const onFinishFailed = async (error) => {
    setSubmitting(false);
    notification.error({
      message: error,
      duration: 6,
      style: {
        backgroundColor: "#fff",
        color: "#ff0000",
        zIndex: 100,
        borderRadius: 4,
      },
    });
  };

  const onFinishSuccess = async (toast) => {
    setSubmitting(false);
    notification.success({
      message: toast,
      duration: 6,
      style: {
        backgroundColor: "#fff",
        borderRadius: 4,
        color: "#274b28",
        zIndex: 100,
      },
    });
  };

  const onFinishSuccessForAddress = async (toast) => {
    setSubmitting(false);
    notification.success({
      message: toast,
      duration: 6,
      style: {
        backgroundColor: "#fff",
        borderRadius: 4,
        color: "#274b28",
        zIndex: 100,
      },
    });
  };

  const onFinishUploadingFailed = async (error) => {
    setUploadSubmitting(false);
    notification.error({
      message: error,
      duration: 6,
      style: {
        backgroundColor: "#fff",
        color: "#ff0000",
        zIndex: 100,
        borderRadius: 4,
      },
    });
  };

  const onFinishUploadingSuccess = async (toast) => {
    setUploadSubmitting(false);
    notification.success({
      message: toast,
      duration: 6,
      style: {
        backgroundColor: "#fff",
        borderRadius: 4,
        color: "#274b28",
        zIndex: 100,
      },
    });
  };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      var totalBytes = files[0].size;
      if (files[0].type === "image/jpeg" || files[0].type === "image/png") {
        if (totalBytes <= 5000000) {
          setImage(reader.result);
          setModalOptions(true);
        } else {
          dispatch(
            createNotification(
              "error-toast",
              "Please upload image file of size 5MB or below"
            )
          );
        }
      } else {
        dispatch(
          createNotification("error-toast", "Please select an image file")
        );
      }
    };
    reader.readAsDataURL(files[0]);
  };

  async function reduce_image_file_size(
    base64Str,
    MAX_WIDTH = 450,
    MAX_HEIGHT = 450
  ) {
    let resized_base64 = await new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // this will return base64 image results after resize
      };
    });
    return resized_base64;
  }

  const getCropData = async () => {
    if (typeof cropper !== "undefined") {
      setModalOptions(false);
      setUploadSubmitting(true);
      const resizedImage = await reduce_image_file_size(
        cropper.getCroppedCanvas().toDataURL()
      );
      dispatch(
        uploadProfileImage(
          resizedImage,
          user.id,
          onFinishUploadingSuccess,
          onFinishUploadingFailed
        )
      );
    }
  };

  const onCancel = () => {
    setImage("");
    setModalOptions(false);
  };

  const onFinishSettings = async (values) => {
    setSubmitting(true);
    delete values.emailAddress;
    dispatch(
      updateProfileSettings(values, user.id, onFinishSuccess, onFinishFailed)
    );
  };

  const onFinishAddress = async (values) => {
    setSubmitting(true);
    dispatch(
      updateUserAddress(
        values,
        user.id,
        onFinishSuccessForAddress,
        onFinishFailed
      )
    );
  };

  const onFinishPassword = async (values) => {
    setSubmitting(true);
    delete values.confirmPassword;
    dispatch(
      updateUserPassword(values, user.id, onFinishSuccess, onFinishFailed)
    );
  };

  const languages = ["Deutsch", "deutsch"];
  const { Option } = Select;

  useEffect(() => {
    setActiveTabName(window.localStorage.getItem("editTab"));
  }, [user, activeTabName]);

  return (
    <div className="content-body" style={{ height: "100%" }}>
      <div className="flex column w-100">
        <h2 style={{ color: "#274B28" }}>Einstellungen</h2>
        <p
          className="textGreen"
          style={{
            margin: "12px 0",
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Ändere deine Profil- und Accounteinstellungen
        </p>
      </div>

      <div className="settings-wrapper">
        <div className="settings-div">
          <SettingsTabs
            tabs={["Account", "Adresse", "Passwort"]}
            setTabName={(name) => {
              setActiveTabName(name);
              setSubmitting(false);
            }}
          />

          <div className="settings-right-wrapper">
            {activeTabName === "account" && (
              <div className="settings-right-inner-wrap">
                <h3 style={{ color: "#274B28", marginTop: 16 }}>
                  Basic Information
                </h3>

                <div className="settings-right-profile-wrap">
                  <div className="profile-pic">
                    {uploadImage ? (
                      <div
                        className="flex justifyCenter alignCenter"
                        style={{
                          width: 120,
                          height: 120,
                        }}
                      >
                        <Loader type="TailSpin" height={48} color="#274b28" />
                      </div>
                    ) : (
                      <>
                        {image && image !== "" ? (
                          <img
                            src={image}
                            alt="profile-icon"
                            style={{
                              width: 120,
                              height: 120,
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <>
                            {user && user.profileURL ? (
                              <img
                                src={user.profileURL}
                                alt="profile-icon"
                                style={{
                                  width: 120,
                                  height: 120,
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <img
                                src={Placeholder}
                                alt="profile-icon"
                                style={{
                                  width: 120,
                                  height: 120,
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      right: 12,
                      width: 51.26,
                      height: 51.26,
                      bottom: "0",
                      background: "#FFFFFF",
                      borderRadius: "50%",
                      boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <input
                      type="file"
                      id="actual-btn"
                      accept="image/x-png,image/jpeg"
                      onChange={onChange}
                      hidden
                    />
                    <label
                      className="flex column justifyCenter alignCenter h-100"
                      style={{
                        cursor: "pointer",
                      }}
                      htmlFor="actual-btn"
                    >
                      <img
                        src={Camera}
                        alt="camera-icon"
                        style={{
                          height: 22,
                          width: 22,
                        }}
                      />
                    </label>
                  </div>
                </div>
                <Form
                  ref={formRef}
                  layout="vertical"
                  className="w-100"
                  style={{ marginTop: 30 }}
                  onFinish={onFinishSettings}
                  onFinishFailed={onFinishFailed}
                >
                  <div className="form-row">
                    <Form.Item
                      className="settings-form-item"
                      style={{ marginRight: 16 }}
                      label={
                        <label className="settings-label">
                          Vorname<span>{` *`}</span>
                        </label>
                      }
                      name="firstName"
                      initialValue={user.firstName}
                      rules={[
                        {
                          required: true,
                          message: "First name is required",
                        },
                      ]}
                      required={false}
                    >
                      <Input
                        type="text"
                        className="settings-value settings-form-input"
                        onChange={(e) => {
                          e.target.value.localeCompare(user.firstName) !== 0
                            ? setEnabled({ ...enabled, firstName: true })
                            : setEnabled({ ...enabled, firstName: false });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="settings-form-item"
                      label={
                        <label className="settings-label">
                          Nachname<span>{` *`}</span>
                        </label>
                      }
                      name="lastName"
                      initialValue={user.lastName}
                      rules={[
                        {
                          required: true,
                          message: "Last name is required",
                        },
                      ]}
                      required={false}
                    >
                      <Input
                        type="text"
                        className="settings-value settings-form-input"
                        onChange={(e) => {
                          e.target.value.localeCompare(user.lastName) !== 0
                            ? setEnabled({ ...enabled, lastName: true })
                            : setEnabled({ ...enabled, lastName: false });
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-row">
                    <Form.Item
                      className="settings-form-item "
                      style={{ marginRight: 16 }}
                      label={
                        <label className="settings-label">
                          Email<span>{` *`}</span>
                        </label>
                      }
                      name="emailAddress"
                      initialValue={user.emailAddress}
                      rules={[
                        {
                          required: true,
                          message: "Please enter valid email address",
                        },
                      ]}
                      required={false}
                    >
                      <Input
                        disabled={true}
                        type="email"
                        className="settings-value settings-form-input"
                        onChange={(e) => {
                          e.target.value.localeCompare(user.emailAddress) !== 0
                            ? setEnabled({ ...enabled, emailAddress: true })
                            : setEnabled({ ...enabled, emailAddress: false });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="settings-form-item"
                      label={
                        <label className="settings-label">preference</label>
                      }
                      name="language"
                      initialValue={user.language}
                      rules={[
                        {
                          required: true,
                          message: "Please select language type",
                        },
                      ]}
                      required={false}
                    >
                      <Select
                        suffixIcon={<DownArrow />}
                        style={{
                          minWidth: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                        onSelect={(e) => {
                          e.localeCompare(user.language) !== 0
                            ? setEnabled({ ...enabled, language: true })
                            : setEnabled({ ...enabled, language: false });
                        }}
                        className="language-selector"
                        name="language"
                      >
                        {languages.map((language, index) => {
                          return (
                            <Option
                              value={language}
                              key={`option-${index + 1}`}
                              className="settings-value"
                            >
                              {language}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    // style={{
                    //   position: "absolute",
                    //   bottom: 0,
                    //   right: 0,
                    // }}
                    className="submitBtn"
                  >
                    <LoadingFormButton
                      submitting={submitting}
                      status={enablingStatus(enabled)}
                      label="Speichern"
                      style={{ width: 200 }}
                      className={
                        enablingStatus(enabled)
                          ? "update-button-disable"
                          : "update-button"
                      }
                    />
                  </div>
                </Form>
              </div>
            )}

            {activeTabName === "address" && (
              <div
                className="settings-right-inner-wrap"
                // style={{ height: "100%", minHeight: 380, position: "relative" }}
              >
                <h3 style={{ color: "#274B28", marginTop: 16 }}>Adresse</h3>
                <Form
                  ref={formRef}
                  layout="vertical"
                  style={{ width: "100%", marginTop: 30 }}
                  onFinish={onFinishAddress}
                  onFinishFailed={onFinishFailed}
                >
                  <div
                    // style={{ display: "flex", width: "100%", marginTop: 12 }}
                    className="form-row"
                  >
                    <Form.Item
                      className="settings-form-item"
                      label={
                        <label className="settings-label">
                          Straße<span>{` *`}</span>
                        </label>
                      }
                      name="streetName"
                      initialValue={
                        user && user["address"]
                          ? user["address"].streetName
                          : ""
                      }
                      rules={[
                        {
                          required: true,
                          message: "Street name is required",
                        },
                      ]}
                      required={false}
                    >
                      <Input
                        type="text"
                        className="settings-value settings-form-input"
                        onChange={(e) => {
                          e.target.value.localeCompare(
                            user && user["address"]
                              ? user["address"].streetName
                              : ""
                          ) !== 0
                            ? setEnabledForAddress({
                                ...enabledForAddress,
                                streetName: true,
                              })
                            : setEnabledForAddress({
                                ...enabledForAddress,
                                streetName: false,
                              });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="settings-form-item form-row-right-field"
                      // style={{ marginLeft: 16 }}
                      label={
                        <label className="settings-label">
                          Hausnummer<span>{` *`}</span>
                        </label>
                      }
                      name="houseNumber"
                      initialValue={
                        user && user["address"]
                          ? user["address"].houseNumber
                          : ""
                      }
                      rules={[
                        {
                          required: true,
                          message: "House no. is required",
                        },
                      ]}
                      required={false}
                    >
                      <Input
                        type="text"
                        className="settings-value settings-form-input"
                        onChange={(e) => {
                          e.target.value.localeCompare(
                            user && user["address"]
                              ? user["address"].houseNumber
                              : ""
                          ) !== 0
                            ? setEnabledForAddress({
                                ...enabledForAddress,
                                houseNumber: true,
                              })
                            : setEnabledForAddress({
                                ...enabledForAddress,
                                houseNumber: false,
                              });
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div
                    // style={{ display: "flex", width: "100%", marginTop: 12 }}
                    className="form-row"
                  >
                    <Form.Item
                      className="settings-form-item"
                      style={{ marginRight: 16 }}
                      label={
                        <label className="settings-label">
                          PLZ<span>{` *`}</span>
                        </label>
                      }
                      name="zipCode"
                      initialValue={
                        user && user["address"] ? user["address"].zipCode : ""
                      }
                      rules={[
                        {
                          required: true,
                          message: "Zipcode is required",
                        },
                      ]}
                      required={false}
                    >
                      <Input
                        type="text"
                        className="settings-value settings-form-input"
                        onChange={(e) => {
                          e.target.value.localeCompare(
                            user && user["address"]
                              ? user["address"].zipCode
                              : ""
                          ) !== 0
                            ? setEnabledForAddress({
                                ...enabledForAddress,
                                zipCode: true,
                              })
                            : setEnabledForAddress({
                                ...enabledForAddress,
                                zipCode: false,
                              });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="settings-form-item"
                      style={{ marginRight: 16 }}
                      label={
                        <label className="settings-label">
                          Stadt<span>{` *`}</span>
                        </label>
                      }
                      name="city"
                      initialValue={
                        user && user["address"] ? user["address"].city : ""
                      }
                      rules={[
                        {
                          required: true,
                          message: "City is required",
                        },
                      ]}
                      required={false}
                    >
                      <Input
                        type="text"
                        className="settings-value settings-form-input"
                        onChange={(e) => {
                          e.target.value.localeCompare(
                            user && user["address"] ? user["address"].city : ""
                          ) !== 0
                            ? setEnabledForAddress({
                                ...enabledForAddress,
                                city: true,
                              })
                            : setEnabledForAddress({
                                ...enabledForAddress,
                                city: false,
                              });
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="settings-form-item"
                      label={
                        <label className="settings-label">
                          Land<span>{` *`}</span>
                        </label>
                      }
                      name="country"
                      initialValue={
                        user && user["address"] ? user["address"].country : ""
                      }
                      rules={[
                        {
                          required: true,
                          message: "Country is required",
                        },
                      ]}
                      required={false}
                    >
                      <Input
                        type="text"
                        className="settings-value settings-form-input"
                        onChange={(e) => {
                          e.target.value.localeCompare(
                            user && user["address"]
                              ? user["address"].country
                              : ""
                          ) !== 0
                            ? setEnabledForAddress({
                                ...enabledForAddress,
                                country: true,
                              })
                            : setEnabledForAddress({
                                ...enabledForAddress,
                                country: false,
                              });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="submitBtn">
                    <LoadingFormButton
                      submitting={submitting}
                      status={enablingStatusForAddress(enabledForAddress)}
                      label="Speichern"
                      style={{ maxWidth: 220 }}
                      className={
                        enablingStatusForAddress(enabledForAddress)
                          ? "update-button-disable"
                          : "update-button"
                      }
                    />
                  </div>
                </Form>
              </div>
            )}

            {activeTabName === "password" && (
              <div
                className="settings-right-inner-wrap"
                // style={{ height: "100%", minHeight: 380, position: "relative" }}
              >
                <h3 style={{ color: "#274B28", marginTop: 16 }}>
                  Ändere dein Passwort
                </h3>
                <Form
                  ref={formRef}
                  layout="vertical"
                  style={{ width: "100%", marginTop: 30 }}
                  onFinish={onFinishPassword}
                  onFinishFailed={onFinishFailed}
                >
                  <div
                    className="form-row"
                    // style={{ display: "flex", width: "100%" }}
                  >
                    <Form.Item
                      className="settings-form-item"
                      style={{ marginRight: 16 }}
                      label={
                        <label className="settings-label">
                          Altes Passwort<span>{` *`}</span>
                        </label>
                      }
                      name="oldPassword"
                      rules={[
                        {
                          required: true,
                          message: "Old password is required",
                        },
                      ]}
                      required={false}
                    >
                      <Input.Password
                        className="settings-value settings-form-input"
                        onChange={(e) => {
                          e.target.value.length > 0
                            ? setEnabledForPassword({
                                ...enabledForPassword,
                                oldPassword: true,
                              })
                            : setEnabledForPassword({
                                ...enabledForPassword,
                                oldPassword: false,
                              });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="settings-form-item"
                      label={
                        <label className="settings-label">
                          Neues Passwort <span>(* Minimum 8 Zeichen)</span>
                        </label>
                      }
                      name="newPassword"
                      rules={[
                        {
                          required: true,
                          message: "New password is required",
                        },
                      ]}
                      required={false}
                    >
                      <Input.Password
                        className="settings-value settings-form-input"
                        onChange={(e) => {
                          e.target.value.length >= 8
                            ? setEnabledForPassword({
                                ...enabledForPassword,
                                newPassword: true,
                              })
                            : setEnabledForPassword({
                                ...enabledForPassword,
                                newPassword: false,
                              });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div
                    // style={{ display: "flex", width: "49%" }}
                    className="form-row"
                  >
                    <Form.Item
                      className="settings-form-item"
                      label={
                        <label className="settings-label">
                          Neues Passwort wiederholen <span>{` *`}</span>
                        </label>
                      }
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: "Confirm Password is required",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              !value ||
                              getFieldValue("newPassword") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "Confirm password do not match with new password"
                            );
                          },
                        }),
                      ]}
                      required={false}
                    >
                      <Input.Password
                        className="settings-value settings-form-input"
                        onChange={(e) => {
                          e.target.value.length >= 8
                            ? setEnabledForPassword({
                                ...enabledForPassword,
                                confirmPassword: true,
                              })
                            : setEnabledForPassword({
                                ...enabledForPassword,
                                confirmPassword: false,
                              });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="submitBtn">
                    <LoadingFormButton
                      submitting={submitting}
                      status={enablingStatusForPassword(enabledForPassword)}
                      label="Speichern"
                      style={{ maxWidth: 220 }}
                      className={
                        enablingStatusForPassword(enabledForPassword)
                          ? "update-button-disable"
                          : "update-button"
                      }
                    />
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        visible={modalOptions}
        onCancel={onCancel}
        footer={null}
        closable={false}
        bodyStyle={{
          backgroundColor: "#f7f7f7",
          color: "white",
          padding: "32px",
          borderRadius: 16,
        }}
        width={576}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <img
            src={CrossIcon}
            alt={"close_icon"}
            style={{ height: 20, width: 20, cursor: "pointer" }}
            onClick={onCancel}
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <Cropper
            style={{ height: 320 }}
            initialAspectRatio={1}
            src={image}
            viewMode={1}
            scaleX={1}
            scaleY={1}
            guides={true}
            minCropBoxHeight={200}
            minCropBoxWidth={200}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "24px",
          }}
        >
          <LoadingFormButton
            submitting={uploadImage}
            label="Upload Image"
            style={{ maxWidth: 220 }}
            onClick={() => getCropData()}
            className={"update-button"}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Settings;
