import initialState from "../initialState";
import { SET_FORESTS, SET_FOREST_SPECIES_DETAILS, SET_FOREST_COORDINATE_DETAILS, SET_CART_ITEMS_COUNT, SET_FOREST_DETAILS, SET_CART_ITEMS, SET_PAYMENT_METHODS, SET_FORESTS_METRICES } from "./actions";

export default function overviewReducer(
    state = initialState.app,
    { type, payload }
) {
    switch (type) {
        case SET_FORESTS:
            return { ...state, newForests: payload.forests };
        case SET_CART_ITEMS_COUNT:
            return { ...state, cartItems: payload.count };
        case SET_FOREST_DETAILS:
            return { ...state, forestDetails: payload.response };
        case SET_FOREST_SPECIES_DETAILS:
            return { ...state, forestSpecies: payload.response };
        case SET_FOREST_COORDINATE_DETAILS:
            return { ...state, forestCoordinates: payload.response };
        case SET_CART_ITEMS:
            return { ...state, cartPayload: payload.items }
        case SET_PAYMENT_METHODS:
            return { ...state, paymentMethods: payload.methods }
        case SET_FORESTS_METRICES:
            return { ...state, metrices: payload }
        default:
            return state;
    }
}
