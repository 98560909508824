import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-loader-spinner";
import { Modal } from "antd";
import CancelIcon from "../../assets/icons/close-circle.svg";
// import PDFDownload from "../../assets/icons/file-download-outline.svg";

// Payment methods Icons
import UNIONPAY from "../../assets/payment-methods/union.svg";
import AMEX from "../../assets/payment-methods/amex.svg";
import DINERS from "../../assets/payment-methods/diners.svg";
import DISCOVER from "../../assets/payment-methods/discover.svg";
import JBC from "../../assets/payment-methods/jbc.svg";
import MASTERCARD from "../../assets/payment-methods/master.svg";
import VISACARD from "../../assets/payment-methods/visa.svg";
import {
  fetchSubsPayments,
  setPayments,
} from "../../store/subscriptions/actions";
import "../../payment-history.css";

const PaymentHistory = ({
  sourceId,
  forestName,
  forestId,
  visible,
  onClose,
}) => {
  let { payments } = useSelector((state) => state.subscriptions);

  const dispatch = useDispatch();
  const [historyLoading, setHistoryLoading] = useState(true);
  const [history, setHistory] = useState(null);

  const finishLoading = () => {
    setHistoryLoading(false);
  };

  useEffect(() => {
    if (payments) {
      payments =
        payments.length > 0 &&
        payments.filter((item) => item.forestId === forestId && item);
      setHistory(payments);
    } else {
      return;
    }
  }, [payments]);

  useEffect(() => {
    dispatch(setPayments(null));
    dispatch(fetchSubsPayments(sourceId, finishLoading));
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      closable={false}
      style={{ top: 72 }}
      bodyStyle={{
        backgroundColor: "#ffffff",
        padding: 0,
        borderRadius: 8,
        minHeight: 280,
      }}
      // width={900}
      className="modal-width"
    >
      <div
        className="modal-header-div"
        // style={{ padding: 24 }}
      >
        <div className="modal-header-div1">
          <h2 className="textGreen">{forestName}</h2>
          <p
            className="textGray"
            style={{ margin: "12px 0 0", fontWeight: 700 }}
          >
            Spendenhistorie
          </p>
        </div>
        <div className="flex justifyEnd">
          <img
            src={CancelIcon}
            alt={"close_icon"}
            style={{ height: 28, width: 28, cursor: "pointer" }}
            onClick={onClose}
          />
        </div>
      </div>

      {historyLoading && (
        <div
          className="flex column alignCenter justifyCenter w-100"
          style={{ margin: "24px auto" }}
        >
          <Loader
            type="TailSpin"
            height={48}
            color="#000000"
            className="loader"
          />
          <p className="textGray" style={{ marginTop: 16 }}>
            Loading History...
          </p>
        </div>
      )}

      {!historyLoading && history && history.length === 0 && (
        <div
          className="flex column alignCenter justifyCenter w-100"
          style={{ margin: "24px auto", textAlign: "center", maxWidth: 400 }}
        >
          <h2 className="textGreen">Spendenhistorie ist nicht verfügbar.</h2>
        </div>
      )}

      {!historyLoading && history === null && (
        <div
          className="flex column alignCenter justifyCenter w-100"
          style={{ margin: "24px auto", textAlign: "center", maxWidth: 400 }}
        >
          <h2 className="textGreen">Spendenhistorie ist nicht verfügbar.</h2>
        </div>
      )}

      {!historyLoading && history && history.length > 0 && (
        <table className="history-table">
          <thead>
            <tr>
              <th>Datum</th>
              <th>Spendenzeitraum</th>
              <th>Spendenbetrag</th>
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            {history.map((transection, idx) => (
              <tr key={idx + 1}>
                <td>
                  <h4 style={{ color: "#424242" }}>
                    {transection.periodStartDate}
                  </h4>
                </td>
                <td>
                  <h5 className="textGray">
                    {transection.periodEndDate ? (`${transection.periodStartDate} - ${transection.periodEndDate}`) : (
                      `Lifetime`
                    )}
                  </h5>
                </td>
                <td>
                  <h4 style={{ color: "#424242" }}>{transection.amount}</h4>
                </td>
                {/* <td>
                  <img
                    src={PDFDownload}
                    alt={PDFDownload}
                    height={24}
                    width={24}
                  />
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Modal>
  );
};

export default PaymentHistory;
