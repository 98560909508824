export const FETCH_FORESTS = "forests/FETCH_FORESTS";
export const SET_FORESTS = "forests/SET_FORESTS";
export const ADD_TO_CART = "forests/ADD_TO_CART";
export const SET_CART_ITEMS_COUNT = "forests/SET_CART_ITEMS_COUNT";
export const GET_CART_ITEMS_COUNT = "forests/GET_CART_ITEMS_COUNT";
export const GET_FOREST_DETAILS = "forests/GET_FOREST_DETAILS";
export const SET_FOREST_DETAILS = "forests/SET_FOREST_DETAILS";
export const GET_FOREST_SPECIES_DETAILS = "forests/GET_FOREST_SPECIES_DETAILS";
export const SET_FOREST_SPECIES_DETAILS = "forests/SET_FOREST_SPECIES_DETAILS";
export const GET_FOREST_COORDINATE_DETAILS = "forests/GET_FOREST_COORDINATE_DETAILS";
export const SET_FOREST_COORDINATE_DETAILS = "forests/SET_FOREST_COORDINATE_DETAILS";
export const GET_FOREST_SPECIES_LIST = "forests/GET_FOREST_SPECIES_LIST";
export const GET_CART_ITEMS = "forests/GET_CART_ITEMS";
export const SET_CART_ITEMS = "forests/SET_CART_ITEMS";
export const DELETE_CART_ITEM = "forests/DELETE_CART_ITEM";
export const UPDATE_CART_ITEM = "forests/UPDATE_CART_ITEM";
export const FETCH_PAYMENT_METHODS = "forests/FETCH_PAYMENT_METHODS";
export const ADD_PAYMENT_METHOD = "forests/ADD_PAYMENT_METHOD";
export const SET_PAYMENT_METHODS = "forests/SET_PAYMENT_METHODS";
export const PAYMENT_CHECKOUT = "forests/PAYMENT_CHECKOUT";
export const APPLY_PROMO_CODE = "forests/APPLY_PROMO_CODE";
export const GET_FORESTS_METRICES = "forests/GET_FORESTS_METRICES";
export const SET_FORESTS_METRICES = "forests/SET_FORESTS_METRICES";

export const fetchForestsMetrices = (stopLoading) => ({
    type: GET_FORESTS_METRICES,
    payload: { stopLoading }
});

export const setForestsMetrices = (payload) => ({
    type: SET_FORESTS_METRICES,
    payload,
});

export const fetchForests = (stopLoading) => ({
    type: FETCH_FORESTS,
    payload: { stopLoading }
});

export const setForests = (forests) => ({
    type: SET_FORESTS,
    payload: { forests },
});

export const addToCart = (values, onSuccess, onError) => ({
    type: ADD_TO_CART,
    payload: { values, onSuccess, onError },
});

export const getCartCounts = (userId) => ({
    type: GET_CART_ITEMS_COUNT,
    payload: { userId },
});

export const setCartCount = (count) => ({
    type: SET_CART_ITEMS_COUNT,
    payload: { count },
});

export const getForestDetails = (forestId, stopLoading) => ({
    type: GET_FOREST_DETAILS,
    payload: { forestId, stopLoading },
});

export const setForestDetails = (response) => ({
    type: SET_FOREST_DETAILS,
    payload: { response },
});

export const getForestSpeciesDetails = (forestId, stopLoading) => ({
    type: GET_FOREST_SPECIES_DETAILS,
    payload: { forestId, stopLoading },
});

export const getForestSpeciesList = () => ({
    type: GET_FOREST_SPECIES_LIST,
})
export const setForestSpeciesDetails = (response) => ({
    type: SET_FOREST_SPECIES_DETAILS,
    payload: { response },
});

export const getForestCoordinatesDetails = (forestId, stopLoading) => ({
    type: GET_FOREST_COORDINATE_DETAILS,
    payload: { forestId, stopLoading },
});

export const setForestCoordinatesDetails = (response) => ({
    type: SET_FOREST_COORDINATE_DETAILS,
    payload: { response },
});

export const getCartItems = (userId) => ({
    type: GET_CART_ITEMS,
    payload: { userId },
});

export const setCartItems = (items) => ({
    type: SET_CART_ITEMS,
    payload: { items },
});

export const deleteCartItem = (cartId, onSuccess, onError) => ({
    type: DELETE_CART_ITEM,
    payload: { cartId, onSuccess, onError }
})

export const updateCartItemDetails = (cartId, payload, onSuccess) => ({
    type: UPDATE_CART_ITEM,
    payload: { cartId, payload, onSuccess },
});

export const fetchPaymentMethods = (userId, onSuccess) => ({
    type: FETCH_PAYMENT_METHODS,
    payload: { userId, onSuccess }
});

export const setPaymentMethods = (methods) => ({
    type: SET_PAYMENT_METHODS,
    payload: { methods },
});

export const addNewPaymentMethod = (userId, values, onSuccess) => ({
    type: ADD_PAYMENT_METHOD,
    payload: { userId, values, onSuccess },
});

export const paymentCheckout = (params, onSuccess, onError) => ({
    type: PAYMENT_CHECKOUT,
    payload: { params, onSuccess, onError },
});

export const getPromoDiscount = (code, onSuccess, onError) => ({
    type: APPLY_PROMO_CODE,
    payload: { code, onSuccess, onError },
})