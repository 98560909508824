import { put, takeEvery, call } from "redux-saga/effects";
import api from "../../services/api/ApiService";
import {
  FETCH_USER_SUBSCRIPTIONS,
  setSubscriptions,
  RENEW_SUBSCRIPTION,
  UNSUBSCRIBEOF_SUBSCRIPTION,
  FETCH_PAYMENTS,
  FETCH_SUBS_PAYMENTS,
  setPayments,
} from "./actions";
import { createNotification } from "../app/actions";
import { history } from "../..";

function fetchSubscriptions(userId) {
  const resp = api.get(`/api/user/subscriptions/${userId}`);
  return resp;
}

function* getUserSubscriptions({ payload: { userId, stopLoading } }) {
  try {
    const subscriptions = yield call(fetchSubscriptions, userId);
    const {
      data: {
        payload,
        metadata: { code, message },
      },
    } = subscriptions;
    if (code === 200) {
      yield call(stopLoading);
      yield put(setSubscriptions(payload));
    } else {
      yield call(stopLoading);
      yield put(createNotification("error-toast", message));
    }
  } catch (e) {
    if (e.response) {
      yield call(stopLoading);
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function fetchPayments(userId) {
  const resp = api.get(`/api/user/payments/${userId}`);
  return resp;
}

function* getUserPayments({ payload: { userId, stopLoading } }) {
  try {
    const subscriptions = yield call(fetchPayments, userId);
    const {
      data: {
        payload,
        metadata: { code, message },
      },
    } = subscriptions;
    if (code === 200) {
      yield call(stopLoading);
      yield put(setPayments(payload));
    } else {
      yield call(stopLoading);
      yield put(createNotification("error-toast", message));
    }
  } catch (e) {
    if (e.response) {
      yield call(stopLoading);
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function fetchSubsPayments(subsId) {
  const resp = api.get(`/api/user/subscription/${subsId}`);
  return resp;
}

function* getSubsPayments({ payload: { subsId, stopLoading } }) {
  try {
    const subscriptions = yield call(fetchSubsPayments, subsId);
    const {
      data: {
        payload,
        metadata: { code, message },
      },
    } = subscriptions;
    if (code === 200) {
      yield call(stopLoading);
      yield put(setPayments(payload));
    } else {
      yield call(stopLoading);
      yield put(createNotification("error-toast", message));
    }
  } catch (e) {
    if (e.response) {
      yield call(stopLoading);
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}

function renewSubscription(values) {
  const resp = api.post(`/api/user/subscription/renew`, values);
  return resp;
}

function* applyRenewSubscription({ payload: { params, onResult } }) {
  try {
    const subscriptions = yield call(renewSubscription, params);
    const {
      data: {
        metadata: { code, message },
      },
    } = subscriptions;
    if (code === 200) {
      yield call(onResult);
      yield put(
        createNotification(
          "success-toast",
          "Your subscription has been renewed"
        )
      );
    } else {
      yield call(onResult);
      yield put(createNotification("error-toast", message));
    }
  } catch (e) {
    if (e.response) {
      yield call(onResult);
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}
function unSubscribeSubscription(values) {
  const resp = api.post(`/api/unsubscribe`, values);
  return resp;
}

function* applyUnSubscribeofSubscription({ payload: { params, onResult } }) {
  try {
    const subscriptions = yield call(unSubscribeSubscription, params);
    const {
      data: {
        metadata: { code, message },
      },
    } = subscriptions;
    if (code === 200) {
      yield call(onResult);
      yield put(
        createNotification(
          "success-toast",
          "Your subscription has been Un-Subscribed"
        ),
        history.push("/dashboard/payments")
      );
    } else {
      //   yield call(onResult);
      yield put(createNotification("error-toast", message));
    }
  } catch (e) {
    if (e.response) {
      yield call(onResult);
      const {
        metadata: { message },
      } = e.response.data;
      yield put(createNotification("error-toast", message));
    }
  }
}
function* SubscriptionsSaga() {
  yield takeEvery(FETCH_USER_SUBSCRIPTIONS, getUserSubscriptions);
  yield takeEvery(RENEW_SUBSCRIPTION, applyRenewSubscription);
  yield takeEvery(FETCH_PAYMENTS, getUserPayments);
  yield takeEvery(FETCH_SUBS_PAYMENTS, getSubsPayments);
  yield takeEvery(UNSUBSCRIBEOF_SUBSCRIPTION, applyUnSubscribeofSubscription);
}

export default SubscriptionsSaga;
