import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import CartIcon from "../assets/icons/cart-outline.svg";
import CartIconWhite from "../assets/icons/cart-outline-blank.svg";
import ArrowRight from "../assets/icons/chevron-right.svg";
import ArrowRightWhite from "../assets/icons/chevron-right-white.svg";
import USFlag from "../assets/united-states.png";
import GermanyFlag from "../assets/germany.png";
import { history } from "../index";
import { getCartCounts } from "../store/forests/actions";
import labels from "../static-content/content";
import Languages from "./common/language-selector";

const StyledLink = styled(NavLink)`
  width: max-content;
  text-decoration: none !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  &:hover {
    width: max-content;
    text-decoration: none !important;
    cursor: pointer !important;
  }
  &.active {
    width: max-content;
    text-decoration: none !important;
    cursor: pointer !important;
  }
`;

const Breadcrumb = (props) => {
  const {
    user: { id },
  } = useSelector((state) => state.app);
  const { cartItems } = useSelector((state) => state.forests);
  const dispatch = useDispatch();
  const {
    location: { pathname, key },
  } = props;

  const [details, setDetails] = useState(null);

  useEffect(() => {
    dispatch(getCartCounts(id));
    const sidebarObj = labels.filter(
      (item) => item.language === window.localStorage.getItem("dashLang")
    )[0];
    if (sidebarObj && sidebarObj.sidebar) {
      setDetails(sidebarObj.sidebar);
    }
  }, [cartItems]);

  const checkPath = () => {
    let title = "";
    if (pathname === "/dashboard/home") {
      title = details[0].title;
    } else if (pathname === "/dashboard/patches") {
      title = details[1].title;
    } else if (pathname === "/dashboard/payments") {
      title = details[2].title;
    } else if (pathname === "/dashboard/settings") {
      title = details[3].title;
    }
    return title;
  };
  return (
    details && (
      <div className="breadcrumb">
        <StyledLink
          to={pathname}
          key={key}
          style={{
            margin: 0,
            height: "3.5em",
          }}
        >
          <div className="flex alignCenter justifyBetween w-100">
            <h4
              style={{
                color: "#274B28",
                fontWeight: 700,
                textTransform: "capitalize",
              }}
            >
              {checkPath(pathname)}
            </h4>
          </div>
        </StyledLink>
        {cartItems > 0 ? (
          <div
            className="cart-icon-container1"
            style = {{display: "none"}}
            // style={{
            //   background: "#274B28",
            //   padding: "16px 32px",
            //   cursor: "pointer",
            // }}
            onClick={() => history.push("/dashboard/patches/cart/review")}
          >
            <img src={CartIconWhite} alt="CartIcon" height={32} width={32} />
            <div
              // className="flex alignCenter justifyCenter"
              // style={{
              //   background: "#F1FFF2",
              //   borderRadius: "50%",
              //   margin: "0 16px",
              //   height: 40,
              //   width: 40,
              // }}
              className="cart-counter"
              style={{flexShrink:"0"}}
            >
              <h3 style={{ color: "#274B28" }}>{cartItems}</h3>
            </div>
            {/* <h3
                style={{ color: "#ffffff", marginRight: 16 }}
              >{`Waldpatenschaften`}</h3> */}
            <img
              src={ArrowRightWhite}
              alt="ArrowRight"
              height={32}
              width={32}
            />
          </div>
        ) : (
          <div className="flex justifyBetween alignCenter" style={{display: "none"}}>
            {/* <Languages
              languagesList={[
                { lang: "english", flag: USFlag },
                { lang: "deutsch", flag: GermanyFlag },
              ]}
            /> */}
            <div
              className="flex alignCenter justifyBetween cart-icon-container"
              style={{
                background: "#F1FFF2",
                padding: 23,
                width: 96,
                cursor: "pointer",
              }}
              onClick={() => history.push("/dashboard/patches/cart/review")}
            >
              <img src={CartIcon} alt="CartIcon" height={24} width={24} />
              {/* <h3 style={{ color: "#274B28" }}>{`Waldpatenschaften`}</h3> */}
              <img src={ArrowRight} alt="ArrowRight" height={24} width={24} />
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default Breadcrumb;
