import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoaderGif from "../components/common/LoaderGif";
import NoData from "../components/common/NoData";
import SearchBar from "../components/SearchBar";
import PatchCard from "../components/common/PatchCard";
import NoDataIllustration from "../assets/illustration/undraw_a_moment_to_relax_bbpa.svg";
import PlusIcon from "../assets/icons/plus-circle-outline-light.svg";
import { history } from "../index";
import { getForestSpeciesList } from "../store/forests/actions";
import { fetchSubscriptions } from "../store/subscriptions/actions";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { size } from "lodash";

const Patches = () => {
  const btnSize = "large";
  const {
    user: { id },
  } = useSelector((state) => state.app);
  let { forestSpecies } = useSelector((state) => state.forests);
  const { subscriptions, subscriptionsAddress } = useSelector(
    (state) => state.subscriptions
  );
  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState(false);
  const [searchedDataSource, setSearchedDataSource] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);

  const noData = {
    title:
      window.localStorage.getItem("dashLang") === "english"
        ? "Forest units currently not available"
        : "Keine Waldflächen verfügbar",
    message:
      window.localStorage.getItem("dashLang") === "english"
        ? "You haven't taken patronship of any forest units. Please sponsor one or more forest unit to see more details."
        : "Du hast noch keine Waldpatenschaft übernommen. Spende bitte um mehr Details sehen zu können.",
    imgSrc: NoDataIllustration,
    buttonText:
      window.localStorage.getItem("dashLang") === "english"
        ? "Sponsor Forest Units"
        : "Werde Waldpate",
  };

  const finishLoading = () => {
    setPageLoading(false);
  };

  useEffect(() => {
    dispatch(getForestSpeciesList());
    dispatch(fetchSubscriptions(id, finishLoading));
  }, []);

  useEffect(() => {
    if (subscriptions && subscriptionsAddress && forestSpecies) {
      let subList = subscriptions.map((item) => {
        return {
          ...item,
          address: subscriptionsAddress.filter((addressEntity) => {
            return (
              item.forest["addressId"] === addressEntity.id && addressEntity
            );
          })[0],
        };
      });

      let mySubscriptionList = subList.map((item) => {
        return {
          ...item,
          specie: forestSpecies.filter((entity) => {
            return entity["forestId"] === item["forestId"] && entity;
          })[0],
        };
      });

      setSearchedDataSource(mySubscriptionList);
      setSubscriptionList(mySubscriptionList);
    } else {
      return;
    }
  }, [subscriptions, subscriptionsAddress]);

  useEffect(() => {
    setPageLoading(true);
  }, []);

  return pageLoading ? (
    <LoaderGif />
  ) : subscriptionList && subscriptionList.length > 0 ? (
    <div>
      <div className="searchbar-div">
        <SearchBar
          searchedData={(source) => setSearchedDataSource(source)}
          dataSource={subscriptionList}
        />
        {/* <div
          className="flex"
          style={{
            background: "#274B28",
            padding: "20px 24px",
            minWidth: 0,

            height: 64,
            marginLeft: 16,
            borderRadius: 4,
            cursor: "pointer",
            boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.12)",
            opacity:
              subscriptionList && subscriptionList.length !== 0 ? 1 : 0.3,
            pointerEvents:
              subscriptionList && subscriptionList.length !== 0 ? true : "none",
          }}
          onClick={() => {
            history.push("/dashboard/patches/new");
          }}
        >
          <img
            src={PlusIcon}
            alt="PlusIcon"
            height={24}
            width={24}
            style={{ marginRight: 8 }}
          />
          <h3 style={{ color: "#ffffff", wordSpacing: 0 }}>Werde Waldpate</h3>
        </div> */}
        <Button
          style={{ height: 64 }}
          // icon={<PlusOutlined />}
          size={btnSize}
          onClick={() => {
            history.push("/dashboard/patches/new");
          }}
          className="search-btn"
        >
          Werde Waldpate
        </Button>
      </div>

      <PatchCard dataSource={searchedDataSource} species={forestSpecies} />
    </div>
  ) : (
    <NoData source={noData} />
  );
};

export default Patches;
