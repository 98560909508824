import React from "react";
import LeftArrow from "../../../assets/icons/chevron-left-light.svg";
import RightArrow from "../../../assets/icons/chevron-right-light.svg";

function Arrows({ prevSlide, nextSlide }) {
  return (
    <div className="arrows">
      <span className="previousArrow" onClick={prevSlide}>
        <img src={LeftArrow} alt="previous" height={24} width={24} />
      </span>
      <span className="nextArrow" onClick={nextSlide}>
      <img src={RightArrow} alt="next" height={24} width={24} />
      </span>
    </div>
  );
}

export default Arrows;
