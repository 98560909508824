import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "antd";
import LoadingFormButton from "./LoadingFormButton";
import { deleteCartItem } from "../../store/forests/actions";

const DeleteCartItem = (props) => {
    const dispatch = useDispatch();
    const { showModal, onClose, sourceID, afterSubmit } = props;
    const [submitting, setSubmitting] = useState(false);

    const onFinishFailed = async () => {
        setSubmitting(false);
        onClose();
    };

    const onFinishSuccess = async () => {
        setSubmitting(false);
        onClose();
        afterSubmit();
    };

    const deleteItem = () => {
        setSubmitting(true);
        dispatch(deleteCartItem(sourceID, onFinishSuccess, onFinishFailed))
    };



    return (
        <Modal
            visible={showModal}
            onCancel={onClose}
            footer={null}
            closable={false}
            style={{ top: 172 }}
            bodyStyle={{ backgroundColor: "#ffffff", borderRadius: 8, padding: "32px 0 0" }}
            width={576}
            height={400}
        >
            <div style={{ borderBottom: "1px solid #E0E0E0", textAlign: "center" }}>
                <h2 className="textGreeb" style={{ padding: "48px 0" }}>
                    Are you sure to delete this cart item
                </h2>
            </div>

            <div className="flex justifyEnd w-100"
                style={{
                    padding: "16px 24px 16px 0",
                }}
            >
                <div
                    style={{
                        background: "#F5F5F5",
                        borderRadius: 100,
                        padding: "10px 24px 8px",
                        cursor: "pointer",
                        marginRight: 16,
                    }}
                    onClick={onClose}
                >
                    <h4 style={{ color: "#757575" }}>Cancel</h4>
                </div>

                <LoadingFormButton
                    submitting={submitting}
                    onClick={deleteItem}
                    label="Delete"
                    className="delete-button"
                />
            </div>
        </Modal>
    );
};

export default DeleteCartItem;
