import React from "react";

const ResetPasswordMessage = () => {
  return (
    <div className="container">
      <div className="container_404">
        <h1
          style={{
            fontSize: 28,
            lineHeight: "140%",
            color: "#ffffff",
          }}
        >
          Password recovery email has been sent
        </h1>
        <p
          style={{
            padding: 0,
            margin: "32px 0px 0px",
            color: "#ffffff",
            fontSize: 18,
            wordSpacing: 0,
            lineHeight: "160%",
          }}
        >
          A verification link has been sent to your email address, please read the instructions and do click
          to complete the reset password process.
        </p>
      </div>
    </div>
  );
};

export default ResetPasswordMessage;
