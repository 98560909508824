import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import CartIcon from "../assets/icons/cart-outline.svg";
import CartIconWhite from "../assets/icons/cart-outline-blank.svg";
import ArrowRight from "../assets/icons/chevron-right.svg";
import ArrowRightWhite from "../assets/icons/chevron-right-white.svg";
import { history } from "../index";
import { getCartCounts } from "../store/forests/actions";
import labels from "../static-content/content";

const StyledLink = styled(NavLink)`
  width: max-content;
  text-decoration: none !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  &:hover {
    width: max-content;
    text-decoration: none !important;
    cursor: pointer !important;
  }
  &.active {
    width: max-content;
    text-decoration: none !important;
    cursor: pointer !important;
  }
`;

const NewPatchesBreadcrumb = (props) => {
  const {
    user: { id },
  } = useSelector((state) => state.app);
  const { cartItems } = useSelector((state) => state.forests);
  const { forestDetails } = useSelector((state) => state.forests);
  const dispatch = useDispatch();
  const {
    location: { pathname, search },
  } = props;
  const queryParam = search.replace("?", "");
  const { isNew } =
    queryParam &&
    JSON.parse(
      '{"' +
        queryParam
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  const subPath = `${pathname}${search}`;
  const [details, setDetails] = useState(null);

  useEffect(() => {
    dispatch(getCartCounts(id));
    const { sidebar } = labels.filter(
      (item) => item.language === window.localStorage.getItem("dashLang")
    )[0];
    setDetails(sidebar);
  }, [cartItems]);

  return (
    <div className="breadcrumb">
      {isNew === "true" ? (
        <div className="new-patch-breadcrumb">
          <div className="breadcrumb-text-div">
            <StyledLink
              to={`/dashboard/patches`}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h4 style={{ color: "#2196F3", textTransform: "capitalize" }}>
                {details && details[1].title}
              </h4>
            </StyledLink>

            <img
              src={ArrowRight}
              alt={ArrowRight}
              height={24}
              width={24}
              style={{ margin: "0 10px" }}
            />
            <StyledLink
              to={`/dashboard/patches/new`}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h4
                style={{
                  color: "#274B28",
                }}
              >
                {window.localStorage.getItem("dashLang") === "english"
                  ? "Available Forests"
                  : "Verfügbare Wälder"}
              </h4>
            </StyledLink>
            <img
              src={ArrowRight}
              alt={ArrowRight}
              height={24}
              width={24}
              style={{ margin: "0 10px" }}
            />

            <StyledLink to={subPath} key={search}>
              <h4 style={{ color: "#274B28" }}>
                {forestDetails && forestDetails.name}
              </h4>
            </StyledLink>
          </div>

          {cartItems > 0 ? (
            <div
              className="cart-icon-container1"
              style = {{display: "none"}}
              // style={{
              //   background: "#274B28",
              //   padding: "16px 32px",
              //   cursor: "pointer",
              // }}
              height="100%"
              onClick={() => history.push("/dashboard/patches/cart/review")}
            >
              <img src={CartIconWhite} alt="CartIcon" height={25} width={25} />
              <div
                style={{
                  background: "#F1FFF2",
                  borderRadius: "50%",
                  margin: "0 16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 40,
                  width: 40,
                }}
              >
                <h3 style={{ color: "#274B28" }}>{cartItems}</h3>
              </div>
              {/* <h3
                style={{ color: "#ffffff", marginRight: 16 }}
              >{`Waldpatenschaften`}</h3> */}
              <img
                src={ArrowRightWhite}
                alt="ArrowRight"
                height={25}
                width={25}
              />
            </div>
          ) : (
            <div
              style={{
                background: "#F1FFF2",
                padding: 23,
                // width: 215,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                display: "none"
              }}
              onClick={() => history.push("/dashboard/patches/cart/review")}
            >
              <img src={CartIcon} alt="CartIcon" height={24} width={24} />
              {/* <h3 style={{ color: "#274B28" }}>{`Waldpatenschaften`}</h3> */}
              <img src={ArrowRight} alt="ArrowRight" height={24} width={24} />
            </div>
          )}
        </div>
      ) : (
        <div className="new-patch-breadcrumb">
          <div className="breadcrumb-text-div">
            <StyledLink
              to={`/dashboard/patches`}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h4 style={{ color: "#2196F3", textTransform: "capitalize" }}>
                {details && details[1].title}
              </h4>
            </StyledLink>

            <img
              src={ArrowRight}
              alt={ArrowRight}
              height={24}
              width={24}
              // style={{ margin: "0 10px" }}
            />
            {pathname === "/dashboard/patches/new" && (
              <StyledLink
                to={`/dashboard/patches/new`}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h4
                  style={{
                    color: "#274B28",
                  }}
                >
                  {window.localStorage.getItem("dashLang") === "english"
                    ? "Available Forests"
                    : "Verfügbare Wälder"}
                </h4>
              </StyledLink>
            )}

            {isNew !== "false" && pathname !== "/dashboard/patches/new" && (
              <img
                src={ArrowRight}
                alt={ArrowRight}
                height={24}
                width={24}
                style={{ margin: "0 10px" }}
              />
            )}

            {forestDetails &&
              forestDetails.name &&
              pathname !== "/dashboard/patches/new" && (
                <StyledLink to={subPath} key={search}>
                  <h4 style={{ color: "#274B28" }}>{forestDetails.name}</h4>
                </StyledLink>
              )}
          </div>
          {cartItems > 0 ? (
            <div
              // style={{
              //   background: "#274B28",
              //   padding: "16px 32px",
              //   display: "flex",
              //   justifyContent: "space-between",
              //   alignItems: "center",
              //   cursor: "pointer",
              // }}
              className="cart-icon-container1"
              style = {{display: "none"}}
              onClick={() => history.push("/dashboard/patches/cart/review")}
            >
              <img src={CartIconWhite} alt="CartIcon" height={32} width={32} />
              <div
                // style={{
                //   background: "#F1FFF2",
                //   borderRadius: "50%",
                //   margin: "0 16px",
                //   display: "flex",
                //   justifyContent: "center",
                //   alignItems: "center",
                //   height: 40,
                //   width: 40,
                // }}
                className="cart-counter"
              >
                <h3 style={{ color: "#274B28" }}>{cartItems}</h3>
              </div>
              {/* <h3
                style={{ color: "#ffffff", marginRight: 16 }}
              >{`Waldpatenschaften`}</h3> */}
              <img
                src={ArrowRightWhite}
                alt="ArrowRight"
                height={32}
                width={32}
              />
            </div>
          ) : (
            <div
              style={{
                background: "#F1FFF2",
                padding: 23,
                // width: 215,
                display: "none", //flex
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => history.push("/dashboard/patches/cart/review")}
            >
              <img src={CartIcon} alt="CartIcon" height={24} width={24} />
              {/* <h3 style={{ color: "#274B28" }}>{`Waldpatenschaften`}</h3> */}
              <img src={ArrowRight} alt="ArrowRight" height={24} width={24} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NewPatchesBreadcrumb;
