import React, { useState, useEffect } from "react";
import SliderContent from "./SliderContent";
import Dots from "./Dots";
import Arrows from "./Arrows";
import "./slider.css";
import { history } from "../../../index";

function Slider({ dataSource, setOpen }) {
  const { images } = dataSource;
  const [activeIndex, setActiveIndex] = useState(0);
  const len = images.length - 1;
  const { location: { search } } = history;
  const queryParam = search.replace("?", "");
  const { isNew } = queryParam && JSON.parse('{"' + queryParam.replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className="large-slider-container">
      <SliderContent
        activeIndex={activeIndex}
        source={dataSource}
        isNew={isNew}
        buyNow={setOpen}
      />
      <Arrows
        prevSlide={() =>
          setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
        }
        nextSlide={() =>
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        }
      />
      <Dots
        activeIndex={activeIndex}
        sliderImages={images}
        onclick={(activeIndex) => setActiveIndex(activeIndex)}
      />
    </div>
  );
}

export default Slider;
