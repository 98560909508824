import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Input, notification, Row, Col } from "antd";
import Loader from "react-loader-spinner";
import NoDataIllustration from "../assets/illustration/empty_cart.png";
import RadioBoxBlank from "../assets/icons/radiobox-blank.svg";
import RadioBoxMarked from "../assets/icons/radiobox-marked.svg";
// Icons
import CountriesIcon from "../assets/icons/map-check-outline.svg";
import DollarIcon from "../assets/icons/currency-usd.svg";
import LandRestoredIcon from "../assets/icons/island.svg";
import CashUSD from "../assets/icons/cash-usd-outline.svg";
import AreaOutline from "../assets/icons/area-check-outline.svg";
import PencileOutline from "../assets/icons/pencil-outline.svg";
import TrashIcon from "../assets/icons/trash-can-outline.svg";
import CircleTick from "../assets/icons/check-circle.svg";
import ProfileIcon from "../assets/icons/account-circle-outline.svg";
import GiftIcon from "../assets/icons/gift-outline.svg";
import LoadingImageGif from "../assets/loader.gif";
import LoadingFormButton from "../components/common/LoadingFormButton";
import ReceiptIcon from "../assets/icons/receipt.svg";
import CreditCardOutline from "../assets/icons/credit-card-outline.svg";
import PlusIcon from "../assets/icons/plus-circle-outline.svg";
import { history } from "../index";
import AddNewPaymentMethod from "../components/common/AddNewPaymentMethod";
// Payment methods Icons
import UNIONPAY from "../assets/payment-methods/union.svg";
import AMEX from "../assets/payment-methods/amex.svg";
import DINERS from "../assets/payment-methods/diners.svg";
import DISCOVER from "../assets/payment-methods/discover.svg";
import JBC from "../assets/payment-methods/jbc.svg";
import MASTERCARD from "../assets/payment-methods/master.svg";
import VISACARD from "../assets/payment-methods/visa.svg";
// Selector Methods
import { useSelector, useDispatch } from "react-redux";
import {
  getCartItems,
  getForestDetails,
  setForestDetails,
  fetchPaymentMethods,
  paymentCheckout,
  getPromoDiscount,
} from "../store/forests/actions";
import EditModal from "../components/common/edit-modal";
import DeleteCartItem from "../components/common/DeleteModal";
import { updateUserAddress } from "../store/settings/actions";
import "../cart.css";
// import "../payment-card.css";

const Cart = (props) => {
  const {
    user: { id, firstName, lastName, emailAddress, address, customerId },
  } = useSelector((state) => state.app);
  const { user, cartPayload, forestDetails, paymentMethods } = useSelector(
    (state) => state.forests
  );
  const [cartItems, setCartItems] = useState([]);
  const [editForestDetails, setEditForestDetails] = useState(null);
  const dispatch = useDispatch();
  const {
    location: { pathname },
  } = props;
  const [sumbit1, setSubmit1] = useState(
    JSON.parse(window.localStorage.getItem("submit1")) || false
  );
  const [submit2, setSubmit2] = useState(
    JSON.parse(window.localStorage.getItem("submit2")) || false
  );
  const [submit3, setSubmit3] = useState(false);

  const [submitCheckout, setSubmitCheckout] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [activePaymentMethods, setActivePaymentMethods] = useState([]);
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState(false);
  const [editModal, showEditModal] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [cartId, setCartId] = useState("");
  const [editPayload, setEditPayload] = useState(null);
  const [isPromoCodeEntered, setPromoCodeEntered] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [discountedPercentage, setDiscountedPercentage] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [sendPromoCode, setSendPromoCode] = useState(false);
  const [promoCodeUid, setPromoCodeUid] = useState(null);
  const [afterDiscountAmount, setAfterDiscountAmount] = useState(0);
  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const formRef = useRef();

  const onEnterPromoCode = (value) => {
    setPromoCodeEntered(true);
    setPromoCode(value);
  };

  const onFinishAddress = async (values) => {
    setSubmitting(true);
    dispatch(
      updateUserAddress(
        values,
        user.id,
        onFinishSuccessForAddress,
        onFinishFailed
      )
    );
  };

  const onPromoFailure = () => {
    setPromoCode("");
    setPromoCodeEntered(false);
    setSendPromoCode(false);
  };

  const onPromoSuccess = (value, promoId) => {
    setPromoCodeUid(promoId);
    setDiscountedPercentage(value);
    setPromoCode("");
    setPromoCodeEntered(false);
    setSendPromoCode(false);
  };

  const onApplyPromotionCode = () => {
    if (promoCode !== "") {
      setSendPromoCode(true);
      dispatch(getPromoDiscount(promoCode, onPromoSuccess, onPromoFailure));
    } else {
      setPromoCodeEntered(false);
      setSendPromoCode(false);
    }
  };

  const [showReceiverForm, setShowReceiverForm] = useState(
    JSON.parse(window.localStorage.getItem("gift")) || false
  );

  const [information, setInformation] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    receiverFirstName: "",
    receiverLastName: "",
    receiverEmail: "",
  });

  const open = () => setVisible(true);

  const onClose = () => setVisible(false);

  const noData = {
    title: "Noch keine Wäldstücke ausgewählt",
    message: "Füge Waldstücke deiner Waldpatenschaften hinzu.",
    imgSrc: NoDataIllustration,
    buttonText: "Waldpatenschaft",
  };

  const finishLoading = () => {
    setPageLoading(false);
  };

  const stopPageLoading = () => {
    setTimeout(finishLoading, 2000);
  };

  const proceedToNextStep2 = () => {
    setSubmitting(true);
    window.localStorage.setItem("submit1", true);
    setSubmit1(true);
    history.push("/dashboard/patches/cart/personal-information");
  };

  const stopEditLoader = () => {
    showEditModal(true);
    setEditForestDetails(forestDetails);
    const items =
      cartItems && cartItems.map((item, idx) => ({ ...item, isEdit: false }));
    setCartItems(items);
  };

  const getForestDetailsForEdit = (index) => {
    const { forestId } = cartItems[index];
    dispatch(setForestDetails(null));
    setEditPayload(cartItems[index]);
    const items =
      cartItems &&
      cartItems.map((item, idx) => ({
        ...item,
        isEdit: index === idx ? true : false,
      }));
    setCartItems(items);
    dispatch(getForestDetails(forestId, stopEditLoader));
  };

  const benefits = [
    {
      iconSrc: LandRestoredIcon,
      title: "Artenvielfalt",
      description:
        "Dein Waldstück ist Wildnis und Zuhause zugleich. Ein Reservat der Sicherheit. Für Tiere und Pflanzen",
    },
    {
      iconSrc: CountriesIcon,
      title: "Klimaschutz",
      description:
        "Mischwälder sind wiederstandsfähiger und speichern mehr CO₂. Sie sind ein wichtiger Teil der Lösung.",
    },
    {
      iconSrc: DollarIcon,
      title: "Nachhaltig",
      description:
        "Deine Spende erschafft Arbeitsplätze und hilft und weltweit noch mehr erodierte Flächen in Wildnis zu verwandeln.",
    },
  ];

  const [enabled, setEnabled] = useState({
    receiverFirstName: false,
    receiverLastName: false,
    receiverEmail: false,
  });

  const enablingStatus = (enablerObject) => {
    if (
      enablerObject.receiverFirstName &&
      enablerObject.receiverLastName &&
      enablerObject.receiverEmail
    ) {
      return false;
    }
    return true;
  };

  // const onChange = (checked) => {
  //   window.localStorage.setItem("gift", checked);
  //   setShowReceiverForm(checked);
  // };

  const totalAmount = () => {
    let amount = 0;
    for (var i = 0; i < cartItems.length; i++) {
      amount = amount + cartItems[i].amountToPay;
    }

    return amount;
  };

  const accumulatedTotalAmount = () => {
    let amount = 0;
    for (var i = 0; i < cartItems.length; i++) {
      amount = amount + cartItems[i].amountToPay;
    }
    amount = amount - discountedPercentage / 100;
    return amount;
  };
  let updateAddressObj = {
    streetName: streetName,
    houseNumber: houseNumber,
    zipCode: zipCode,
    city: city,
    country: country,
  };
  function validateObject() {
    if (
      updateAddressObj.streetName &&
      updateAddressObj.houseNumber &&
      updateAddressObj.zipCode &&
      updateAddressObj.city &&
      updateAddressObj.country
    ) {
      return true;
    }
    return false;
  }

  const onDoneForStep2 = async () => {
    setSubmitting(true);
    setSubmit2(true);
    if (validateObject()) {
      dispatch(
        updateUserAddress(
          updateAddressObj,
          id,
          onFinishSuccessForAddress,
          onFinishFailed
        )
      );

      const receiverInformation = {
        firstName: information.receiverFirstName,
        lastName: information.receiverLastName,
        emailAddress: information.receiverEmail,
      };
      if (showReceiverForm) {
        window.localStorage.setItem(
          "receiver",
          JSON.stringify(receiverInformation)
        );
        setSubmitting(false);
        window.localStorage.setItem("submit2", true);
        history.push("/dashboard/patches/cart/payment");
      } else {
        window.localStorage.removeItem("receiver");
        setSubmitting(false);
        window.localStorage.setItem("submit2", true);
        history.push("/dashboard/patches/cart/payment");
      }
    } else {
      onFinishFailed("Bitte fülle alle Pflichtfelder aus");
    }
  };

  const selectPaymentPlan = (index) => {
    setActive(true);
    const methods = activePaymentMethods.map((item, idx) => ({
      ...item,
      isActive: idx === index ? true : false,
    }));
    setActivePaymentMethods(methods);
  };

  const onSuccessFailure = () => {
    setSubmitCheckout(false);
  };

  const onSuccessCheckout = async (sessionUrl) => {
    window.location.replace(sessionUrl);
    setSubmitCheckout(false);
    window.localStorage.removeItem("submit1");
    window.localStorage.removeItem("submit2");
    window.localStorage.removeItem("submit3");
    window.localStorage.removeItem("gift");
    history.push("/dashboard/congratulations");
  };

  useEffect(() => {
    setPageLoading(true);
    stopPageLoading();
  }, []);

  useEffect(() => {
    dispatch(getCartItems(id));
    dispatch(fetchPaymentMethods(id));
  }, [pageLoading]);

  useEffect(() => {
    if (cartPayload) {
      var items = cartPayload;
      if (items) {
        items = items && items.map((item) => ({ ...item, isEdit: false }));
        setCartItems(items);
      }
    }
  }, [cartPayload]);

  useEffect(() => {
    if (paymentMethods) {
      var methods = paymentMethods;
      if (methods) {
        methods =
          methods && methods.map((item) => ({ ...item, isActive: false }));
        setActivePaymentMethods(methods);
      }
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (discountedPercentage !== 0) {
      var disc = discountedPercentage;
      let amount = 0;
      for (var i = 0; i < cartItems.length; i++) {
        amount = amount + cartItems[i].amountToPay;
      }
      const discount = (amount * disc) / 100;
      const newTotal = amount - (amount * disc) / 100;
      setDiscountedAmount(discount.toFixed(2));
      setAfterDiscountAmount(newTotal);
    }
  }, [discountedPercentage]);

  const onCheckout = async () => {
    setSubmitCheckout(true);
    const subscriptions = cartItems.map((item) => {
      if (item.subscription["subscriptionType"] === "lifetime") {
        return {
          quantity: item.areaInSqMeters,
          price: parseInt(item.amountToPay * 100),
          type: item.subscription["subscriptionType"],
        };
      }
      return {
        quantity: item.areaInSqMeters,
        price: item.subscription["priceId"],
        type: item.subscription["subscriptionType"],
      };
    });
    const submitParams = {
      userId: id,
      customerId: customerId,
      cartItems: cartItems,
      promoCodeId: promoCodeUid,
      subscriptions,
      discountGiven: Number(discountedAmount),
      discount: Number(discountedPercentage),
      totalAmount: totalAmount().toFixed(2),
    };
    dispatch(
      paymentCheckout(submitParams, onSuccessCheckout, onSuccessFailure)
    );
  };
  const onFinishFailed = async (error) => {
    setSubmitting(false);
    notification.error({
      message: error,
      duration: 6,
      style: {
        backgroundColor: "#fff",
        color: "#ff0000",
        zIndex: 100,
        borderRadius: 4,
      },
    });
  };
  const onFinishSuccessForAddress = async (toast) => {
    notification.success({
      message: toast,
      duration: 6,
      style: {
        backgroundColor: "#fff",
        borderRadius: 4,
        color: "#274b28",
        zIndex: 100,
        wordSpacing: "-5px",
      },
    });
  };

  useEffect(() => {
    setStreetName(
      address && address["streetName"] ? address["streetName"] : ""
    );
    setZipCode(address && address["zipCode"] ? address["zipCode"] : "");
    setHouseNumber(
      address && address["houseNumber"] ? address["houseNumber"] : ""
    );
    setCountry(address && address["country"] ? address["country"] : "");
    setCity(address && address["city"] ? address["city"] : "");
  }, []);

  return pageLoading ? (
    <div className="flex column justifyCenter alignCenter wv-100 hv-100">
      <img src={LoadingImageGif} alt="loading-gif" height={120} width={120} />
    </div>
  ) : cartItems && cartItems.length > 0 ? (
    <div className="content-body cart-wrapper">
      <div className="cart-wrapper2">
        {/* Navigation Header */}
        <div className="cart-progress-bar" style={{ display: "none" }}>
          <div className="flex justifyCenter alignCenter">
            {sumbit1 ? (
              <div
                style={{
                  border: "1px solid #ffffff",
                  borderRadius: "50%",
                  background: "#ffffff",
                }}
              >
                <img src={CircleTick} alt="check-mark" height={32} width={32} />
              </div>
            ) : (
              <div
                className="flex justifyCenter alignCenter"
                style={{
                  height: 40,
                  width: 40,
                  opacity:
                    pathname === "/dashboard/patches/cart/review" ? 1 : 0.4,
                  background: "#ffffff",
                  color: "#1C75BC",
                  borderRadius: "50%",
                  fontSize: 20,
                  lineHeight: "28px",
                }}
              >
                1
              </div>
            )}

            <p
              style={{
                color: "#ffffff",
                fontSize: 16,
                lineHeight: "20px",
                margin: "0 0 0 16px",
                opacity: sumbit1 ? 1 : 0.4,
              }}
            >
              {/* Kontrollier deine ausgewählten Waldstücke */}
            </p>
          </div>

          <div
            style={{
              margin: "6px 24px 0",
              background: "#ffffff",
              width: 32,
              height: 2,
            }}
          />

          <div className="flex justifyCenter alignCenter">
            {submit2 ? (
              <div
                style={{
                  border: "1px solid #ffffff",
                  borderRadius: "50%",
                  background: "#ffffff",
                }}
              >
                <img src={CircleTick} alt="check-mark" height={32} width={32} />
              </div>
            ) : (
              <div
                className="flex justifyCenter alignCenter"
                style={{
                  height: 40,
                  width: 40,
                  background: "#ffffff",
                  opacity:
                    pathname === "/dashboard/patches/cart/personal-information"
                      ? 1
                      : 0.4,
                  color: "#1C75BC",
                  borderRadius: "50%",
                  fontSize: 20,
                  lineHeight: "28px",
                }}
              >
                2
              </div>
            )}

            <p
              style={{
                color: "#ffffff",
                fontSize: 16,
                lineHeight: "20px",
                margin: "0 0 0 16px",
                opacity: submit2 ? 1 : 0.4,
              }}
            >
              {/* Kontrollier deine persönlichen Daten */}
            </p>
          </div>

          <div
            style={{
              margin: "6px 24px 0",
              background: "#ffffff",
              width: 32,
              height: 2,
            }}
          />

          <div className="flex justifyCenter alignCenter">
            {submit3 ? (
              <div
                style={{
                  border: "1px solid #ffffff",
                  borderRadius: "50%",
                  background: "#ffffff",
                }}
              >
                <img src={CircleTick} alt="check-mark" height={32} width={32} />
              </div>
            ) : (
              <div
                className="flex justifyCenter alignCenter"
                style={{
                  height: 40,
                  width: 40,
                  background: "#ffffff",
                  opacity:
                    pathname === "/dashboard/patches/cart/payment" ? 1 : 0.4,
                  color: "#1C75BC",
                  borderRadius: "50%",
                  fontSize: 20,
                  lineHeight: "28px",
                }}
              >
                3
              </div>
            )}
            <p
              style={{
                color: "#ffffff",
                fontSize: 18,
                lineHeight: "23px",
                margin: "0 0 0 16px",
                opacity:
                  pathname === "/dashboard/patches/cart/payment" ? 1 : 0.4,
              }}
            >
              {/* Spendenmethode */}
            </p>
          </div>
        </div>

        {/* Step:1 */}
        {pathname === "/dashboard/patches/cart/review" && (
          <div className="cart-main-conaitner">
            <div className="container-inner">
              <div className="top-row">
                <div className="flex column">
                  <div className="flex alignCenter">
                    <h2 style={{ color: "#274B28", fontWeight: 900 }}>
                      Ausgewählte Waldstücke
                    </h2>
                    <div
                      className="flex justifyCenter alignCenter"
                      style={{
                        background: "#274B28",
                        borderRadius: "50%",
                        margin: "0 16px",
                        height: 40,
                        width: 40,
                      }}
                    >
                      <h3 style={{ color: "#F1FFF2" }}>{cartItems.length}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      margin: "12px 0px",
                    }}
                  >
                    <h1
                      style={{
                        color: "#757575",
                        // margin: "12px 0",
                        fontSize: 18,
                        lineHeight: "23px",
                      }}
                    >
                      Ändere oder lösche deine Waldstücke
                    </h1>
                  </div>
                </div>

                {/* <div
                  className="flex alignCenter w-100"
                  style={{
                    background: "#ffffff",
                    boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.25)",
                    borderRadius: 8,
                    maxWidth: 320,
                    height: 56,
                    padding: "0 24px",
                  }}
                >
                  <Switch
                    defaultChecked={showReceiverForm}
                    onChange={onChange}
                  />
                  <p
                    style={{
                      fontSize: 14,
                      lineHeight: "140%",
                      color: "#757575",
                      letterSpacing: "0.002em",
                      margin: "0 0 0 16px",
                    }}
                  >
                    This is a gift for someone
                  </p>
                </div> */}
              </div>

              {cartItems &&
                cartItems.map((item, index) => (
                  <div key={index + 1}>
                    <div className="cart-pymnt" key={`item-card-` + index + 1}>
                      <div className="col1col2-div">
                        <div className="col1">
                          <h3 className="textGreen" style={{ fontWeight: 700 }}>
                            {item.forest.name}
                          </h3>
                          <p
                            className="textGray"
                            style={{
                              fontSize: 16,
                              lineHeight: "20px",
                              margin: "8px 0 0",
                              textAlign: "left",
                              textTransform: "capitalize",
                            }}
                          >
                            {`${item.subscriptionType} Subscription`}
                          </p>
                        </div>
                        <div className="col2">
                          <div className="pymnt-details">
                            <div className="item1">
                              <div className="payment-circle-icon">
                                <img
                                  src={AreaOutline}
                                  alt={AreaOutline}
                                  height={24}
                                  width={24}
                                />
                              </div>
                              <div className="second-div">
                                <h4 className="textGreen">
                                  {item.areaInSqMeters}
                                </h4>
                                <p
                                  className="textGreen"
                                  style={{
                                    fontSize: 12,
                                    lineHeight: "16px",
                                    margin: "4px 0 0",
                                  }}
                                >
                                  Quadratmeter
                                </p>
                              </div>
                            </div>
                            <div className="item2">
                              <div className="payment-circle-icon">
                                <img
                                  src={CashUSD}
                                  alt={CashUSD}
                                  height={24}
                                  width={24}
                                />
                              </div>
                              <div className="second-div">
                                <h4 className="textGreen">
                                  {`${item.currency} ${item.amountToPay.toFixed(
                                    2
                                  )}`}
                                </h4>
                                <p
                                  className="textGreen"
                                  style={{
                                    fontSize: 12,
                                    lineHeight: "16px",
                                    margin: "4px 0 0",
                                  }}
                                >
                                  Spendenbetrag
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col3" style={{ marginLeft: 24 }}>
                        <div
                          className=""
                          style={{
                            marginRight: 50,
                          }}
                          onClick={() => {
                            getForestDetailsForEdit(index);
                          }}
                        >
                          <div className="payment-light-circle-icon">
                            {item.isEdit ? (
                              <Loader
                                type="TailSpin"
                                height={24}
                                color="#274B28"
                                className="loader"
                              />
                            ) : (
                              <img
                                src={PencileOutline}
                                alt={PencileOutline}
                                className="eidt-icon"
                              />
                            )}
                          </div>
                        </div>
                        <div
                          className=""
                          onClick={() => {
                            showDeleteModal(true);
                            setCartId(item.id);
                          }}
                        >
                          <div
                            className="trash-icon"
                            style={{
                              marginLeft: "10px",
                              marginRight: "8px",
                              display: "none",
                            }}
                          >
                            <img
                              src={TrashIcon}
                              alt={TrashIcon}
                              className="delete-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            
            {/* Benefits */}
            <div className="right-div">
              <div className="inner-div">
                <h5 style={{ color: "#274B28", marginBottom: 24 }}>
                  Vorteile unserer Wälder
                </h5>
                {benefits &&
                  benefits.map((value, index) => (
                    <div
                      key={index + 1}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        padding: "16px 0 24px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            padding: 16,
                            borderRadius: 26,
                          }}
                        >
                          <img
                            src={value.iconSrc}
                            alt={`icons`}
                            height={24}
                            width={24}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: 16,
                          }}
                        >
                          <h3 style={{ color: "#274B28" }}>{value.title}</h3>
                          <p
                            className="small-p"
                            style={{ marginTop: 8, textAlign: "left" }}
                          >
                            {value.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}

        {/* Step: 1 => Footer */}
        {pathname === "/dashboard/patches/cart/review" && (
          <div className="cart-footer">
            <div className="footer-div1">
              <span className="textGray p1">{`Gesamt Spendenbetrag: `}</span>
              <span className="p2">
                {`${cartItems[0].currency} ${totalAmount().toFixed(2)}`}
              </span>
            </div>

            {/* <LoadingFormButton
              submitting={submitting}
              label="Weiter"
              onClick={proceedToNextStep2}
              style={{ width: 240, height: 44 }}
              className={"update-button"}
            /> */}
            <LoadingFormButton
              submitting={submitCheckout}
              label="Spenden"
              onClick={onCheckout}
              style={{ width: 240, height: 44 }}
              className={"update-button"}
            />
          </div>
        )}

        {/* Step: 2 */}
        {pathname === "/dashboard/patches/cart/personal-information" && (
          <div className="step2_wrapper">
            <div className="step2_content">
              <div className="content_header">
                <img
                  src={ProfileIcon}
                  alt="profile-icon"
                  height={32}
                  width={32}
                  style={{ marginRight: 24 }}
                />
                <h4 className="textGreen">Persönliche Informationen</h4>
              </div>
              <div className="step2_content_inner-div">
                {/* <div className="flex justifyBetween w-100"> */}
                <Row gutter={[10, 10]}>
                  <Col lg={12} sm={24} xs={24}>
                    <Form.Item
                      className="w-100 h-100 flex column"
                      style={{
                        position: "relative",
                        padding: 0,
                        border: "none",
                        marginRight: 32,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Vorname ist ein Pflichtfeld",
                        },
                      ]}
                      required={false}
                    >
                      <p
                        style={{
                          color: "#9E9E9E",
                          fontSize: 16,
                          lineHeight: "20px",
                          margin: 0,
                          wordSpacing: -2.5,
                        }}
                        className="text-align-left"
                      >
                        Vorname
                      </p>
                      <Input
                        type="text"
                        value={firstName}
                        style={{
                          height: 64,
                          color: "#424242",
                          background: "#FAFAFA",
                          borderRadius: 8,
                          border: "1px solid #E0E0E0",
                          fontSize: 18,
                          paddingLeft: 24,
                          lineHeight: "23px",
                          boxShadow: "none",
                          marginTop: 10,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} sm={24} xs={24}>
                    <Form.Item
                      className="w-100 h-100"
                      style={{
                        position: "relative",
                        padding: 0,
                        border: "none",
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Nachname ist ein Pflichtfeld",
                        },
                      ]}
                      required={false}
                    >
                      <p
                        style={{
                          color: "#9E9E9E",
                          fontSize: 16,
                          lineHeight: "20px",
                          margin: 0,
                          wordSpacing: -2.5,
                        }}
                        className="text-align-left"
                      >
                        Nachname
                      </p>
                      <Input
                        type="text"
                        value={lastName}
                        style={{
                          height: 64,
                          color: "#424242",
                          background: "#FAFAFA",
                          borderRadius: 8,
                          border: "1px solid #E0E0E0",
                          fontSize: 18,
                          paddingLeft: 24,
                          lineHeight: "23px",
                          boxShadow: "none",
                          marginTop: 10,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {/* </div> */}
                  {/* <div className="flex justifyBetween w-100"> */}
                  <Col lg={12} sm={24} xs={24}>
                    <Form.Item
                      className="w-100 h-100"
                      style={{
                        position: "relative",
                        padding: 0,
                        border: "none",
                        // maxWidth: address && "48.5%",
                        marginRight: address === null ? 0 : 32,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Adresse ist ein Pflichfeld",
                        },
                      ]}
                      required={false}
                    >
                      <p
                        style={{
                          color: "#9E9E9E",
                          fontSize: 16,
                          lineHeight: "20px",
                          margin: 0,
                          wordSpacing: -2.5,
                        }}
                        className="text-align-left"
                      >
                        Email-Adresse
                      </p>
                      <Input
                        type="email"
                        value={emailAddress}
                        style={{
                          height: 64,
                          color: "#424242",
                          background: "#FAFAFA",
                          borderRadius: 8,
                          border: "1px solid #E0E0E0",
                          fontSize: 18,
                          paddingLeft: 24,
                          lineHeight: "23px",
                          boxShadow: "none",
                          marginTop: 10,
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* </div> */}
              </div>
            </div>
            {/* address fields */}
            <div className="step2_content mt">
              <div className="content_header">
                <img
                  src={ProfileIcon}
                  alt="address-icon"
                  height={32}
                  width={32}
                  style={{ marginRight: 24 }}
                />

                <h4 className="textGreen">Anschrift</h4>
              </div>
              <div className="step2_content_inner-div">
                <Form
                  ref={formRef}
                  layout="vertical"
                  style={{ width: "100%", marginTop: 30 }}
                  onFinish={onFinishAddress}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[10, 10]}>
                    <Col lg={12} sm={24} xs={24}>
                      <Form.Item
                        className="w-100 h-100 flex column"
                        style={{
                          position: "relative",
                          padding: 0,
                          border: "none",
                          marginRight: 32,
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Straße ist ein Pflichfeld",
                          },
                        ]}
                      >
                        <p
                          style={{
                            color: "#9E9E9E",
                            fontSize: 16,
                            lineHeight: "20px",
                            margin: 0,
                            wordSpacing: -2.5,
                          }}
                          className="text-align-left"
                        >
                          Straße
                        </p>
                        <Input
                          type="text"
                          value={streetName}
                          style={{
                            height: 64,
                            color: "#424242",
                            background: "#FAFAFA",
                            borderRadius: 8,
                            border: "1px solid #E0E0E0",
                            fontSize: 18,
                            paddingLeft: 24,
                            lineHeight: "23px",
                            boxShadow: "none",
                            marginTop: 10,
                          }}
                          required={true}
                          onChange={(event) => {
                            setStreetName(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={12} sm={24} xs={24}>
                      <Form.Item
                        className="w-100 h-100"
                        style={{
                          position: "relative",
                          padding: 0,
                          border: "none",
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Hausnummer ist ein Pflichtfeld",
                          },
                        ]}
                        required={true}
                      >
                        <p
                          style={{
                            color: "#9E9E9E",
                            fontSize: 16,
                            lineHeight: "20px",
                            margin: 0,
                            wordSpacing: -2.5,
                          }}
                          className="text-align-left"
                        >
                          Hausnummer
                        </p>
                        <Input
                          type="text"
                          value={houseNumber}
                          style={{
                            height: 64,
                            color: "#424242",
                            background: "#FAFAFA",
                            borderRadius: 8,
                            border: "1px solid #E0E0E0",
                            fontSize: 18,
                            paddingLeft: 24,
                            lineHeight: "23px",
                            boxShadow: "none",
                            marginTop: 10,
                          }}
                          required={true}
                          onChange={(event) => {
                            setHouseNumber(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={12} sm={24} xs={24}>
                      <Form.Item
                        className="w-100 h-100"
                        style={{
                          position: "relative",
                          padding: 0,
                          border: "none",
                          // maxWidth: "48.5%",
                          marginRight: 32,
                        }}
                        rules={[
                          {
                            required: true,
                            message: "PLZ ist ein Pflichtfeld",
                          },
                        ]}
                        required={false}
                      >
                        <p
                          style={{
                            color: "#9E9E9E",
                            fontSize: 16,
                            lineHeight: "20px",
                            margin: 0,
                            wordSpacing: -2.5,
                          }}
                          className="text-align-left"
                        >
                          PLZ
                        </p>
                        <Input
                          type="email"
                          value={zipCode}
                          style={{
                            height: 64,
                            color: "#424242",
                            background: "#FAFAFA",
                            borderRadius: 8,
                            border: "1px solid #E0E0E0",
                            fontSize: 18,
                            paddingLeft: 24,
                            lineHeight: "23px",
                            boxShadow: "none",
                            marginTop: 10,
                          }}
                          required={false}
                          onChange={(event) => {
                            setZipCode(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={12} sm={24} xs={24}>
                      <Form.Item
                        className="w-100 h-100"
                        style={{
                          position: "relative",
                          padding: 0,
                          border: "none",
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Stadt ist ein Pflichtfeld",
                          },
                        ]}
                        required={true}
                      >
                        <p
                          style={{
                            color: "#9E9E9E",
                            fontSize: 16,
                            lineHeight: "20px",
                            margin: 0,
                            wordSpacing: -2.5,
                          }}
                          className="text-align-left"
                        >
                          Stadt
                        </p>
                        <Input
                          type="text"
                          value={city}
                          style={{
                            height: 64,
                            color: "#424242",
                            background: "#FAFAFA",
                            borderRadius: 8,
                            border: "1px solid #E0E0E0",
                            fontSize: 18,
                            paddingLeft: 24,
                            lineHeight: "23px",
                            boxShadow: "none",
                            marginTop: 10,
                          }}
                          required={false}
                          onChange={(event) => {
                            setCity(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} sm={24} xs={24}>
                      <Form.Item
                        className="w-100 h-100"
                        style={{
                          position: "relative",
                          padding: 0,
                          border: "none",
                          // marginLeft: 20,
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Land ist ein Pflichtfeld",
                          },
                        ]}
                        required={true}
                      >
                        <p
                          style={{
                            color: "#9E9E9E",
                            fontSize: 16,
                            lineHeight: "20px",
                            margin: 0,
                            wordSpacing: -2.5,
                          }}
                          className="text-align-left"
                        >
                          Land
                        </p>
                        <Input
                          type="text"
                          value={country}
                          style={{
                            height: 64,
                            color: "#424242",
                            background: "#FAFAFA",
                            borderRadius: 8,
                            border: "1px solid #E0E0E0",
                            fontSize: 18,
                            paddingLeft: 24,
                            lineHeight: "23px",
                            boxShadow: "none",
                            marginTop: 10,
                          }}
                          onChange={(event) => {
                            setCountry(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
            {showReceiverForm && (
              <div
                className="w-100 flex column justifyStart"
                style={{
                  maxWidth: 992,
                  minWidth: 900,
                  marginTop: 32,
                  borderRadius: 8,
                  boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.12)",
                }}
              >
                <div
                  className="flex justifyStart alignCenter"
                  style={{
                    background: "#F1FFF2",
                    borderRadius: "8px 8px 0px 0px",
                    padding: 24,
                  }}
                >
                  <img
                    src={GiftIcon}
                    alt="giftbox-icon"
                    height={32}
                    width={32}
                    style={{ marginRight: 24 }}
                  />
                  <h4 className="textGreen">Geschenkempfänger</h4>
                </div>
                <div
                  className="flex column justifyStart alignCenter"
                  style={{
                    background: "#FFFFFF",
                    borderRadius: "0px 0px 8px 8px",
                    padding: 24,
                  }}
                >
                  <div className="flex justifyBetween w-100">
                    <Form.Item
                      className="w-100 h-100"
                      style={{
                        position: "relative",
                        padding: 0,
                        border: "none",
                        marginRight: 32,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Vorname ist ein Pflichtfeld",
                        },
                      ]}
                      required={false}
                    >
                      <p
                        style={{
                          color: "#9E9E9E",
                          fontSize: 16,
                          lineHeight: "20px",
                          margin: 0,
                          wordSpacing: -2.5,
                        }}
                        className="text-align-left"
                      >
                        Vorname
                      </p>
                      <Input
                        type="text"
                        onChange={(e) => {
                          if (
                            e.target.value !== "" &&
                            e.target.value !== null
                          ) {
                            setEnabled({ ...enabled, receiverFirstName: true });
                            setInformation({
                              ...information,
                              receiverFirstName: e.target.value,
                            });
                          } else {
                            setEnabled({
                              ...enabled,
                              receiverFirstName: false,
                            });
                            setInformation({
                              ...information,
                              receiverFirstName: e.target.value,
                            });
                          }
                        }}
                        style={{
                          height: 64,
                          color: "#424242",
                          background: "#FAFAFA",
                          borderRadius: 8,
                          border: "1px solid #E0E0E0",
                          fontSize: 18,
                          paddingLeft: 24,
                          lineHeight: "23px",
                          boxShadow: "none",
                          marginTop: 10,
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="w-100 h-100"
                      style={{
                        position: "relative",
                        padding: 0,
                        border: "none",
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Nachname ist ein Pflichtfeld",
                        },
                      ]}
                      required={false}
                    >
                      <p
                        style={{
                          color: "#9E9E9E",
                          fontSize: 16,
                          lineHeight: "20px",
                          margin: 0,
                          wordSpacing: -2.5,
                        }}
                        className="text-align-left"
                      >
                        Nachname
                      </p>
                      <Input
                        type="text"
                        onChange={(e) => {
                          if (
                            e.target.value !== "" &&
                            e.target.value !== null
                          ) {
                            setEnabled({ ...enabled, receiverLastName: true });
                            setInformation({
                              ...information,
                              receiverLastName: e.target.value,
                            });
                          } else {
                            setEnabled({ ...enabled, receiverLastName: false });
                            setInformation({
                              ...information,
                              receiverLastName: e.target.value,
                            });
                          }
                        }}
                        style={{
                          height: 64,
                          color: "#424242",
                          background: "#FAFAFA",
                          borderRadius: 8,
                          border: "1px solid #E0E0E0",
                          fontSize: 18,
                          paddingLeft: 24,
                          lineHeight: "23px",
                          boxShadow: "none",
                          marginTop: 10,
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="flex w-100 justifyBetween">
                    <Form.Item
                      className="w-100 h-100"
                      style={{
                        maxWidth: "48.5%",
                        position: "relative",
                        padding: 0,
                        border: "none",
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Email ist ein Pflichtfeld",
                        },
                      ]}
                      required={false}
                    >
                      <p
                        style={{
                          color: "#9E9E9E",
                          fontSize: 16,
                          lineHeight: "20px",
                          margin: 0,
                          wordSpacing: -2.5,
                        }}
                        className="text-align-left"
                      >
                        Email-Adresse
                      </p>
                      <Input
                        type="email"
                        onChange={(e) => {
                          if (
                            e.target.value !== "" &&
                            e.target.value !== null
                          ) {
                            setEnabled({ ...enabled, receiverEmail: true });
                            setInformation({
                              ...information,
                              receiverEmail: e.target.value,
                            });
                          } else {
                            setEnabled({ ...enabled, receiverEmail: false });
                            setInformation({
                              ...information,
                              receiverEmail: e.target.value,
                            });
                          }
                        }}
                        style={{
                          height: 64,
                          color: "#424242",
                          background: "#FAFAFA",
                          borderRadius: 8,
                          border: "1px solid #E0E0E0",
                          fontSize: 18,
                          paddingLeft: 24,
                          lineHeight: "23px",
                          boxShadow: "none",
                          marginTop: 10,
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Step: 2 => Footer */}
        {pathname === "/dashboard/patches/cart/personal-information" && (
          <div className="cart-footer">
            <div
              className="buyNow"
              style={{
                cursor: "pointer",
                marginRight: 16,
                width: 240,
                height: 44,
              }}
              onClick={() => {
                window.localStorage.setItem("submit1", false);
                history.push("/dashboard/patches/cart/review");
              }}
            >
              <h4 className="textGreen">Zurück</h4>
            </div>

            {!showReceiverForm ? (
              <LoadingFormButton
                submitting={submitting}
                label="Weiter"
                onClick={onDoneForStep2}
                style={{ width: 240, height: 40 }}
                className={"update-button"}
              />
            ) : (
              <>
                {enablingStatus(enabled) ? (
                  <div
                    className="flex justifyCenter alignCenter"
                    style={{
                      background: "#F5F5F5",
                      borderRadius: 100,
                      cursor: "not-allowed",
                      marginRight: 16,
                      width: 240,
                      height: 44,
                    }}
                  >
                    <h4 className="textGray">Spende abschließen</h4>
                  </div>
                ) : (
                  <LoadingFormButton
                    submitting={submitting}
                    label="Weiter"
                    onClick={onDoneForStep2}
                    style={{ width: 240, height: 40 }}
                    className={"update-button"}
                  />
                )}
              </>
            )}
          </div>
        )}

        {/* Step: 3 */}
        {pathname === "/dashboard/patches/cart/payment" && (
          <div className="step3_wrapper">
            {/* Payment Methods */}
            <div className="step3_left_div">
              <div className="step3_left_div_inner-wrap">
                <div className="step3_left_div_inner-wrap2 ">
                  <div className="step3_left_div_header">
                    <img
                      src={CreditCardOutline}
                      alt="credit-card-outline"
                      height={32}
                      width={32}
                      style={{ marginRight: 24 }}
                    />
                    <h4 className="textGreen">Wähle deine Spendenmethode</h4>
                  </div>
                  <div className="step3_left_div_content">
                    {activePaymentMethods &&
                      activePaymentMethods.map((payment, pIndex) => (
                        <div
                          className="step3_pay_card"
                          onClick={() => {
                            selectPaymentPlan(pIndex);
                          }}
                          style={{
                            background: payment.isActive
                              ? "#F1FFF2"
                              : "#ffffff",

                            border: payment.isActive
                              ? "1px solid #274B28"
                              : "1px solid #E0E0E0",
                          }}
                        >
                          <div className="step3_pay_card_div1 ">
                            {payment.isActive ? (
                              <img
                                src={RadioBoxMarked}
                                alt="radi-box-marked"
                                height={24}
                                width={24}
                              />
                            ) : (
                              <img
                                src={RadioBoxBlank}
                                alt="radio-box-blank"
                                height={24}
                                width={24}
                              />
                            )}
                          </div>

                          <div className="step3_pay_card_div2">
                            {payment.brand === "amex" && (
                              <img
                                src={AMEX}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            {payment.brand === "mastercard" && (
                              <img
                                src={MASTERCARD}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            {payment.brand === "discover" && (
                              <img
                                src={DISCOVER}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            {payment.brand === "diners" && (
                              <img
                                src={DINERS}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            {payment.brand === "jcb" && (
                              <img
                                src={JBC}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            {payment.brand === "unionpay" && (
                              <img
                                src={UNIONPAY}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            {payment.brand === "visa" && (
                              <img
                                src={VISACARD}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            <div
                              className="flex column"
                              style={{
                                marginLeft: 16,
                              }}
                            >
                              {payment.brand === "amex" && (
                                <h4 className="textGray">
                                  {`American Express Card ${payment.cardNumber}`}
                                </h4>
                              )}

                              {payment.brand === "mastercard" && (
                                <h4 className="textGray">
                                  {`Master Card ${payment.cardNumber}`}
                                </h4>
                              )}

                              {payment.brand === "discover" && (
                                <h4 className="textGray">
                                  {`Discover Card ${payment.cardNumber}`}
                                </h4>
                              )}

                              {payment.brand === "diners" && (
                                <h4 className="textGray">
                                  {`DINERS Club Card ${payment.cardNumber}`}
                                </h4>
                              )}

                              {payment.brand === "jcb" && (
                                <h4 className="textGray">
                                  {`JBC Card ${payment.cardNumber}`}
                                </h4>
                              )}

                              {payment.brand === "unionpay" && (
                                <h4 className="textGray">
                                  {`Union Pay Card ${payment.cardNumber}`}
                                </h4>
                              )}

                              {payment.brand === "visa" && (
                                <h4 className="textGray">
                                  {`Visa Card  ${payment.cardNumber}`}
                                </h4>
                              )}

                              <p
                                className="small-p textGray"
                                style={{
                                  margin: "8px 0 0",
                                }}
                              >
                                {`Expires ${
                                  payment.expiryMonth <= 9
                                    ? `0${payment.expiryMonth}`
                                    : payment.expiryMonth
                                }-${payment.expiryYear}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}

                    <div className="step3_pay_card_btn" onClick={() => open()}>
                      <img
                        src={PlusIcon}
                        alt="plus-icon"
                        height={24}
                        width={24}
                      />
                      <p
                        style={{
                          color: "#424242",
                          margin: "0 0 0 16px",
                          fontSize: 16,
                          lineHeight: "20px",
                          textTransform: "capitalize",
                        }}
                      >
                        {`Add new payment method`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Cart Summary */}
            <div className="step3_right_div">
              <div className="step3_right_inner_div">
                <div className="flex justifyStart alignCenter w-100">
                  <img
                    src={ReceiptIcon}
                    alt="recepit-icon"
                    height={32}
                    width={32}
                    style={{ marginRight: 24 }}
                  />
                  <h3 className="textGreen">Übersicht</h3>
                </div>

                {cartItems &&
                  cartItems.map((detail, index) => (
                    <div key={index + 1}>
                      <div
                        className="flex justifyCenter alignStart w-100"
                        style={{
                          marginTop: 24,
                        }}
                        key={`payment-card-` + index + 1}
                      >
                        <div className="flex column justifyCenter alignStart w-100">
                          <p
                            style={{
                              color: "#424242",
                              fontSize: 16,
                              lineHeight: "20px",
                              margin: 0,
                            }}
                          >
                            {`${detail.forest.name}`}
                          </p>
                          <p
                            style={{
                              color: "#757575",
                              fontSize: 14,
                              lineHeight: "18px",
                              margin: "8px 0 0",
                            }}
                          >
                            {`${detail.areaInSqMeters} qm`}
                          </p>
                        </div>

                        <div
                          className="flex justifyEnd w-100"
                          style={{
                            maxWidth: 100,
                          }}
                        >
                          <p
                            style={{
                              color: "#274B28",
                              fontSize: 16,
                              lineHeight: "20px",
                              fontWeight: 700,
                              margin: 0,
                            }}
                          >{`${detail.currency} ${detail.amountToPay.toFixed(
                            2
                          )}`}</p>
                        </div>
                      </div>
                    </div>
                  ))}

                <div
                  style={{
                    borderTop: "1px solid #E0E0E0",
                    borderBottom: "1px solid #E0E0E0",
                    padding: "8px 0px 24px",
                    margin: "24px 0",
                  }}
                >
                  <div
                    className="flex justifyBetween alignCenter w-100"
                    style={{
                      marginTop: 16,
                    }}
                  >
                    <p
                      style={{
                        color: "#424242",
                        margin: 0,
                      }}
                    >
                      Sub Total
                    </p>
                    <h4
                      className="textGreen"
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {`EUR ${totalAmount().toFixed(2)}`}
                    </h4>
                  </div>
                </div>

                <div className="flex justifyBetween alignCenter w-100">
                  <Form.Item
                    className="h-100 w-100"
                    style={{
                      position: "relative",
                      padding: 0,
                      border: "none",
                    }}
                    required={false}
                  >
                    <p
                      className="textGray"
                      style={{
                        fontSize: 16,
                        lineHeight: "20px",
                        margin: 0,
                        wordSpacing: -2.5,
                      }}
                    >
                      Discount Code
                    </p>
                    <Input
                      type="text"
                      value={promoCode}
                      style={{
                        height: 40,
                        color: "#424242",
                        background: "#FAFAFA",
                        borderRadius: 4,
                        border: "1px solid #E0E0E0",
                        fontSize: 18,
                        paddingLeft: 16,
                        lineHeight: "23px",
                        boxShadow: "none",
                        marginTop: 16,
                      }}
                      onChange={(e) => {
                        onEnterPromoCode(e.target.value);
                      }}
                    />
                  </Form.Item>
                  {!isPromoCodeEntered ? (
                    <div
                      className="flex justifyCenter alignCenter"
                      style={{
                        background: "#F5F5F5",
                        borderRadius: 4,
                        border: "1px solid #E0E0E0",
                        cursor: "not-allowed",
                        marginLeft: 16,
                        marginTop: 12,
                        height: 40,
                        width: 150,
                      }}
                    >
                      <p className="small-p textGray">Anwenden</p>
                    </div>
                  ) : (
                    <>
                      {sendPromoCode ? (
                        <div
                          className="flex justifyCenter alignCenter"
                          style={{
                            background: "#F1FFF2",
                            borderRadius: 4,
                            border: "1px solid #000000",
                            cursor: "not-allowed",
                            marginLeft: 16,
                            marginTop: 12,
                            height: 40,
                            width: 150,
                          }}
                        >
                          <Loader
                            type="TailSpin"
                            height={24}
                            color="#000000"
                            className="loader"
                          />
                        </div>
                      ) : (
                        <div
                          className="flex justifyCenter alignCenter"
                          style={{
                            background: "#F1FFF2",
                            borderRadius: 4,
                            border: "1px solid #000000",
                            cursor: "pointer",
                            marginLeft: 16,
                            marginTop: 12,
                            height: 40,
                            width: 150,
                          }}
                          onClick={onApplyPromotionCode}
                        >
                          <p className="small-p textGreen">Anwenden</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {discountedPercentage !== 0 && (
                  <div className="flex justifyBetween alignCenter w-100">
                    <p
                      style={{
                        color: "#424242",
                        fontSize: 16,
                        lineHeight: "20px",
                        margin: 0,
                      }}
                    >
                      Discounted Percentages
                    </p>
                    <h3 className="textGreen" style={{ fontWeight: 800 }}>
                      {`${discountedPercentage}%`}
                    </h3>
                  </div>
                )}
                {discountedAmount !== 0 && (
                  <div
                    className="flex justifyBetween alignCenter w-100"
                    style={{ margin: "16px 0" }}
                  >
                    <p
                      style={{
                        color: "#424242",
                        fontSize: 16,
                        lineHeight: "20px",
                        margin: 0,
                      }}
                    >
                      Nachlass
                    </p>
                    <h3 className="textGreen" style={{ fontWeight: 800 }}>
                      {`EUR ${discountedAmount}`}
                    </h3>
                  </div>
                )}
                <div className="flex justifyBetween alignCenter w-100">
                  <p
                    style={{
                      color: "#424242",
                      fontSize: 16,
                      lineHeight: "20px",
                      margin: 0,
                    }}
                  >
                    Gesamtspende
                  </p>
                  {discountedPercentage === 0 ? (
                    <h3 className="textGreen" style={{ fontWeight: 800 }}>
                      {`EUR ${accumulatedTotalAmount().toFixed(2)}`}
                    </h3>
                  ) : (
                    <h3 className="textGreen" style={{ fontWeight: 800 }}>
                      {`EUR ${afterDiscountAmount.toFixed(2)}`}
                    </h3>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Step: 3 => Footer */}
        {pathname === "/dashboard/patches/cart/payment" && (
          <div
            className="flex justifyCenter alignCenter w-100"
            style={{
              position: "fixed",
              bottom: 0,
              padding: 32,
              height: 80,
              background: "#ffffff",
            }}
          >
            <div
              className="buyNow"
              style={{
                cursor: "pointer",
                marginRight: 16,
                width: 240,
                height: 44,
              }}
              onClick={() => {
                window.localStorage.setItem("submit2", false);
                history.push("/dashboard/patches/cart/personal-information");
              }}
            >
              <h4 className="textGreen">Zurück</h4>
            </div>

            {!active ? (
              <div
                className="flex justifyCenter alignCenter"
                style={{
                  background: "#F5F5F5",
                  borderRadius: 100,
                  cursor: "not-allowed",
                  marginRight: 16,
                  width: 240,
                  height: 44,
                }}
              >
                <h4 className="textGray">Spenden</h4>
              </div>
            ) : (
              <LoadingFormButton
                submitting={submitCheckout}
                label="Spenden"
                onClick={onCheckout}
                style={{ width: 240, height: 44 }}
                className={"update-button"}
              />
            )}
          </div>
        )}
      </div>

      {editModal && (
        <EditModal
          visible={editModal}
          payload={editPayload}
          forest={editForestDetails}
          onClose={() => showEditModal(false)}
          afterSubmit={() => {
            setPageLoading(true);
            stopPageLoading();
          }}
        />
      )}

      <DeleteCartItem
        showModal={deleteModal}
        sourceID={cartId}
        onClose={() => showDeleteModal(false)}
        afterSubmit={() => {
          setPageLoading(true);
          stopPageLoading();
        }}
      />

      <AddNewPaymentMethod
        showModal={visible}
        onClose={() => onClose()}
        afterSubmit={() => {
          setPageLoading(true);
          stopPageLoading();
        }}
      />
    </div>
  ) : (
    <div
      className="flex column justifyCenter alignCenter h-100 w-100"
      style={{
        minHeight: "90vh",
        maxWidth: 576,
        textAlign: "center",
        margin: "72px auto 0",
      }}
    >
      <img src={noData.imgSrc} alt="no-data-illustration" />
      <div style={{ marginTop: 32 }}>
        <h1 style={{ color: "#274B28" }}>{noData.title}</h1>
        <p style={{ color: "#9E9E9E", textAlign: "center" }}>
          {noData.message}
        </p>
        <Button
          className="green-button"
          onClick={() => history.push("/dashboard/patches/new")}
        >
          {noData.buttonText}
        </Button>
      </div>
    </div>
  );
};

export default Cart;
