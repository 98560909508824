// import React from "react";
// import { Modal } from "antd";
// import LoadingFormButton from "../LoadingFormButton";
import React, { useState } from "react";
import { Modal } from "antd";
import LoadingFormButton from "../LoadingFormButton";

const UnSubModal = (props) => {
  const { showModal, closeModal, unsubscribe, payment } = props;

  const onFinishFailed = async (error) => {
    setSubmitting(false);
    console.error("error", error);
  };

  const onFinishSuccess = async () => {
    setSubmitting(false);
  };

  const onFinish = async (event) => {
    try {
      setSubmitting(true);
      await unsubscribe(event, payment);
      onFinishSuccess();
    } catch (error) {
      onFinishFailed(error);
    }
  };

  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal
      visible={showModal}
      onCancel={closeModal}
      footer={null}
      closable={false}
      style={{ top: 48 }}
      bodyStyle={{ backgroundColor: "#ffffff", padding: 24, borderRadius: 8 }}
      width={576}
      height={276}
    >
      <h4
        style={{
          color: "#424242",
        }}
      >
        Edit Forest Details
      </h4>

      <p
        className="small-p"
        style={{
          color: "#757575",
          margin: "16px 0 0",
        }}
      >
        Are you Sure to Un-Subscribe!
      </p>

      <div className="update-form" style={{ display: "flex" }}>
        {/* <Form
          ref={formRef}
          layout="vertical"
          style={{ width: "100%", marginTop: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
         > */}
        <div
          style={{
            marginTop: 24,
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div
            style={{
              background: "#F5F5F5",
              borderRadius: 100,
              padding: "10px 24px 8px",
              cursor: "pointer",
              marginRight: 16,
            }}
            onClick={closeModal}
          >
            <h4 style={{ color: "#757575" }}>Cancel</h4>
          </div>

          <LoadingFormButton
            submitting={submitting}
            onClick={(event) => {
              onFinish(event);
            }}
            status={false}
            label="Submit"
            className={"update-button"}
          />
        </div>
        {/* </Form> */}
      </div>
    </Modal>
  );
};

export default UnSubModal;
