import React, { useState, useEffect } from "react";
import PerformanceIcon from "../../assets/icons/green-arrow-performance.svg";
import BulbIcon from "../../assets/icons/lightbulb-on-outline.svg";
import Slider from "./small-carousel/Slider";
import { history } from "../../index";

const PatchCard = ({ newPatch = false, dataSource, species, afterUpdate }) => {
  const [patches, setPatches] = useState(null);

  useEffect(() => {
    setPatches(dataSource);
  }, [dataSource]);

  return (
    <div>
      {patches &&
        patches.map((patch, index) => {
          return (
            <div
              className="patch-card"
              onClick={() => {
                history.push(
                  `/dashboard/patch/details?id=${patch.forestId}&isNew=${newPatch}`
                );
              }}
            >
              <div className="slider-div">
                <Slider dataSource={patch.forest["images"]} />
              </div>
              <div className="patch-card-right">
                <h3 className="h3-text">
                  {patch.forest["name"]}
                  <span>
                    {/* <img
                      src={ViewDetailsIcon}
                      alt={ViewDetailsIcon}
                      height={20}
                      width={20}
                      style={{ marginLeft: 16, cursor: "pointer" }}
                      onClick={() => {
                        history.push(
                          `/dashboard/patch/details?id=${patch.forestId}&isNew=${newPatch}`
                        );
                      }}
                    /> */}
                  </span>
                </h3>
                {patch["address"] && (
                  <p
                    className="textGray"
                    style={{ textTransform: "capitalize", textAlign: "left" }}
                  >
                    {`${patch["address"].city} | ${patch["address"].country}`}
                  </p>
                )}
                <div className="patch-bottom-div">
                  <div className="patch-bottom-left-div">
                    <div className="patch-btn1">
                      <img
                        src={PerformanceIcon}
                        alt={PerformanceIcon}
                        height={18}
                        width={18}
                        style={{ marginRight: "12px" }}
                      />
                      <span>{`Nachhaltig`}</span>
                    </div>

                    <div className="patch-btn2">
                      <img
                        src={BulbIcon}
                        alt={BulbIcon}
                        height={18}
                        width={18}
                        style={{ marginRight: "12px" }}
                      />
                      <span className="small-p textGreen">
                        <strong style={{ color: "#000000" }}>
                          {`${patch.specie ? patch.specie.count : 0} `}
                        </strong>
                        Arten
                      </span>
                    </div>
                  </div>
                  {/* <div className="bottom-right-div">
                    <h3 className="details-btn">Details</h3>
                    <DashOutlined
                      style={{
                        border: "1px solid green",
                        borderRadius: 100,
                        width: 44,
                        height: 44,
                        border: "1px solid #274B28",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "44px",
                      }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PatchCard;
