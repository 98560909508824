export default [
  {
    language: "deutsch",
    sidebar: [
      { title: "dashboard", url: "/dashboard/home" },
      { title: "waldstücke", url: "/dashboard/patches" },
      { title: "spendenpläne", url: "/dashboard/payments" },
      { title: "einstellungen", url: "/dashboard/settings" },
    ],
    dashboard: {
      title: "Dashboard",
      visitText: "Statistiken für:",
      card1: {
        title: "Leben auf Land",
        description: "Daten zu deinen terrestrischen Ökosystemen",
      },
      card2: {
        title: "Karte",
        description: "Der Standort deiner Wälder",
      },
      card3: {
        title: "Klimaschutz",
        description: "Die Klimaschutzwirkung deiner Waldstücke",
      },
      card4: {
        title: "Sauberes Wasser und Sanitärversorgung",
        description: "Informationen zu Wasserreinigung und Feuchtigkeit",
      },
      card5: {
        title: "Wirtschaftliche Wirkung",
        description: "Informationen zu wirtschaftlichen Einfluss deiner Waldstücke",
      },
    },
    forests: {
      title: "Waldstücke",
    },
    payments: {
      title: "Wiederkehrende Spenden",
    },
    settings: {
      title: "Settings",
      subTitle: "Einstellungen",
      description: "Ändere deine Profil und Accounteinstellungen",
    },
  },
  {
    language: "english",
    sidebar: [
      { title: "dashboard", url: "/dashboard/home" },
      { title: "forest patches", url: "/dashboard/patches" },
      { title: "donation plans", url: "/dashboard/payments" },
      { title: "settings", url: "/dashboard/settings" },
      // { title: "patch details", url: "/dashboard/patch/details" },
    ],
    dashboard: {
      title: "dashboard",
      visitText: "Viewing statistics for",
      card1: {
        title: "Life on land",
        description: "Data related to terrestrial ecosystems",
      },
      card2: {
        title: "Map View",
        description: "The location of your forests",
      },
      card3: {
        title: "Climate Action",
        description: "Climate impact of your forest patches",
      },
      card4: {
        title: "Clean Water and Sanitisation",
        description: "Water cycle and humidity information",
      },
      card5: {
        title: "Economic Growth",
        description: "Information about the economic impact of your forests",
      },
    },
    forests: {
      title: "Forest units",
    },
    payments: {
      title: "Donation plans",
    },
    settings: {
      title: "Settings",
      subTitle: "Account settings",
      description: "Change your profile and account settings",
    },
  },
];
