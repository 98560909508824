import React, { useState } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import CrossIcon from "../assets/icons/cross.png";

const SearchBar = ({ searchedData, dataSource }) => {
  const [showCross, setShowCross] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  // clear search input and hide cross icon
  const clearSearch = () => {
    setShowCross(false);
    setSearchInput("");
    searchedData(dataSource);
  };

  const isArray = (a) => {
    return !!a && a.constructor === Array;
  };

  const searchItem = (items, searchText) => {
    const filteredArr = items;
    var result = [];
    // result = filteredArr.filter((item) =>
    //   Object.keys(item).some(
    //     (k) =>
    //       item[k] !== null && !isBoolean(item[k]) &&
    //       item[k].toString().toLowerCase().includes(searchText.toLowerCase())
    //   )
    // );

    // filter from user object fields
    // if (result.length === 0) {
    //   result = filteredArr.filter((item) =>
    //     Object.keys(item["user"]).some(
    //       (k) =>
    //         item["user"][k] !== null &&
    //         item["user"][k].toString().toLowerCase().includes(searchText.toLowerCase())
    //     )
    //   );
    //   if (result.length > 0) {
    //     return result;
    //   }
    // }
    // filter from forest object fields
    if (result.length === 0) {
      result = filteredArr.filter((item) =>
        Object.keys(item["forest"]).some(
          (k) =>
            item["forest"][k] !== null &&
            !isArray(item["forest"][k]) &&
            item["forest"][k]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
        )
      );
      if (result.length > 0) {
        return result;
      }
    }
    // filter from payment method object fields
    if (result.length === 0) {
      result = filteredArr.filter((item) =>
        Object.keys(item["paymentMethod"]).some(
          (k) =>
            item["paymentMethod"][k] !== null &&
            item["paymentMethod"][k]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
        )
      );
      if (result.length > 0) {
        return result;
      }
    }
    // filter from subscription object fields
    if (result.length === 0) {
      result = filteredArr.filter((item) =>
        Object.keys(item["subscriptionPlan"]).some(
          (k) =>
            item["subscriptionPlan"][k] !== null &&
            item["subscriptionPlan"][k]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
        )
      );
      if (result.length > 0) {
        return result;
      }
    }
    // filter from address object fields
    if (result.length === 0) {
      result = filteredArr.filter((item) =>
        Object.keys(item["address"]).some(
          (k) =>
            item["address"][k] !== null &&
            item["address"][k]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
        )
      );
      if (result.length > 0) {
        return result;
      }
    }
    return result;
  };

  const handleChange = (e) => {
    const searchText = e.target.value;
    setShowCross(true);
    setSearchInput(searchText);
    const rs = searchItem(dataSource, searchText);
    searchedData(rs);
  };

  return (
    <div className="search-field">
      <Input
        type="text"
        disabled={dataSource.length === 0 ? true : false}
        value={searchInput}
        onChange={handleChange}
        prefix={<SearchOutlined />}
        placeholder={`Suche`}
        // style={{
        //   borderRadius: 8,
        //   backgroundColor: "#FFFFFF",
        //   border: "none",
        //   boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.12)",
        //   padding: "11px 28px",
        // }}
        className="search-field-wrap"
      />
      {showCross && (
        <img
          src={CrossIcon}
          alt="cross-icon"
          style={{
            height: 16,
            width: 16,
            position: "absolute",
            right: 16,
            top: 0,
            opacity: 0.5,
            cursor: "pointer",
            marginTop: 24,
            zIndex: 100,
          }}
          onClick={clearSearch}
        />
      )}
    </div>
  );
};

export default SearchBar;
