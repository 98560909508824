import { all, fork } from "redux-saga/effects";
import appSagas from "./app/sagas";
import forestsSaga from "./forests/sagas";
import subscriptionSaga from "./subscriptions/sagas";
import settingSagas from "./settings/sagas";

export default function* rootSaga(services = {}) {
  yield all([
    fork(appSagas, services), 
    fork(forestsSaga, services),
    fork(subscriptionSaga, services),
    fork(settingSagas, services)
  ]);
}
