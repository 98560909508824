import React from "react";
import { Button } from "antd";
import Loader from "react-loader-spinner";

const LoadingButton = ({ label, btnClass, submitting, status, ...rest }) => (
  <Button className={btnClass} htmlType="submit" disabled={status} {...rest} >
    {submitting ? (
      <Loader type="TailSpin" height={32} color="#ffffff" className="loader"
        />
    ) : (
      label
    )}
  </Button>
);

export default LoadingButton;
