import React, { useState, useEffect } from "react";
import LoaderGif from "../components/common/LoaderGif";
import NoData from "../components/common/NoData";
import Selector from "../components/common/UnitSelector";
import CardView from "../components/common/Card";

// Illustration
import NoDataIllustration from "../assets/illustration/no-data-illustration.svg";
// Graph Images
import BioDiversityChart from "../assets/graph-images/BD-Chart.svg";
import HumidityBD from "../assets/graph-images/humidity-BD-chart.svg";
import AirCleanedChart from "../assets/graph-images/air-cleaner-chart.svg";
// Icons
import CountriesIcon from "../assets/icons/map-check-outline.svg";
import LandRestoredIcon from "../assets/icons/island.svg";
import TreeSpeciesIcon from "../assets/icons/view-grid-plus-outline.svg";
import ArrowBD from "../assets/icons/arrow-BD.svg";
// import InformationIcon from "../assets/icons/information-outline.svg";
import AirFilter from "../assets/icons/air-filter.svg";
import StairsUp from "../assets/icons/stairs-up.svg";
import TemperaturIcon from "../assets/icons/thermometer-lines.svg";
import WaterCleanedChart from "../assets/icons/water-cleaner-chart.svg";
import ArrowSanitation from "../assets/icons/arrow-sanitation.svg";
import Accounts from "../assets/icons/account-multiple.svg";
import {
  fetchForestsMetrices,
  getForestCoordinatesDetails,
} from "../store/forests/actions";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import Map from "../components/common/Map";

const Dashboard = () => {
  const [pageLoading, setPageLoading] = useState(false);
  let { metrices, forestCoordinates } = useSelector((state) => state.forests);
  const [selectorSource, setSelectorSource] = useState(null);
  const [details, setDetails] = useState(null);
  let [selectedSourceIndex, setSelectedSourceIndex] = useState(0);

  const noData = {
    title:
      window.localStorage.getItem("dashLang") === "english"
        ? "Dashboard analytics isn't available"
        : "Das Dashboard ist zur Zeit nicht verfügbar.",
    message:
      window.localStorage.getItem("dashLang") === "english"
        ? "You haven't taken patronship of any forest units. Please sponsor one or more forest unit to see more details."
        : "Du hast noch keine Waldpatenschaft übernommen. Spende bitte um mehr Details sehen zu können.",
    imgSrc: NoDataIllustration,
    buttonText:
      window.localStorage.getItem("dashLang") === "english"
        ? "Sponsor Forest Units"
        : "Werde Waldpate",
  };

  const dispatch = useDispatch();
  const finishLoading = () => {
    setPageLoading(false);
  };

  useEffect(() => {
    dispatch(fetchForestsMetrices(finishLoading));
  }, []);

  useEffect(() => {
    if (metrices) {
      if (metrices.length > 0) {
        const metricesArr = metrices.map((item, index) => ({
          isActive: index === selectedSourceIndex ? true : false,
          lifeOnLand: [
            {
              imgSrc: CountriesIcon,
              altText: CountriesIcon,
              bgColor: "rgba(0, 207, 232, 0.12)",
              count:
                item.countries <= 9 ? `0${item.countries}` : item.countries,
              title: "Länder",
            },
            {
              imgSrc: LandRestoredIcon,
              altText: LandRestoredIcon,
              bgColor: "#FFF8E1",
              count: numberWithSpaces(item.landRestored),
              title: "Renaturierte Fläche",
            },
            {
              imgSrc: TreeSpeciesIcon,
              altText: TreeSpeciesIcon,
              bgColor: "#ECEFF1",
              count: item.species,
              title: "Baumarten",
            },
          ],
          bioDiversity: item.bioDiversity,
          percentageBioDiversity: item.percentBioDiversity,
          latitude: item.latitude,
          longitude: item.longitude,
          climate: {
            carbonDioxide: item.co2Intake,
            capturelevel: item.capturelevel,
            airCleaned: `${item.cleanedAir}cm`,
            temperature: item.temperature,
            level: item.percentTemperature,
          },
          waterAndSanitation: {
            waterLevel: item.waterLevelCleaned,
            humidity: item.soilHumidity,
            percentageHumidity: item.percentSoilHumidity,
          },
          jobs: {
            jobsCreated: item.jobsCreated,
            percentage: item.percentJobs,
          },
        }));

        const forests = metrices.map((item, index) => ({
          name: item.forest.name,
          area: item.forest.areaInSqMeters,
          isSelected: index === selectedSourceIndex ? true : false,
        }));

        setSelectorSource(forests);
        setDetails(metricesArr);
      }
    }
  }, [metrices]);
  useEffect(() => {
    if (metrices && metrices[0]) {
      dispatch(
        getForestCoordinatesDetails(metrices[0].forestId, finishLoading)
      );
    }
  }, [metrices]);

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const onChangeSource = (selectedIndex) => {
    setPageLoading(true);
    const metricesArr = metrices.map((item, index) => ({
      isActive: index === selectedIndex ? true : false,
      lifeOnLand: [
        {
          imgSrc: CountriesIcon,
          altText: CountriesIcon,
          bgColor: "rgba(0, 207, 232, 0.12)",
          count: item.countries <= 9 ? `0${item.countries}` : item.countries,
          title: "Länder",
        },
        {
          imgSrc: LandRestoredIcon,
          altText: LandRestoredIcon,
          bgColor: "#FFF8E1",
          count: numberWithSpaces(item.landRestored),
          title: "Renaturierte Fläche",
        },
        {
          imgSrc: TreeSpeciesIcon,
          altText: TreeSpeciesIcon,
          bgColor: "#ECEFF1",
          count: item.species,
          title: "Baumarten",
        },
      ],
      bioDiversity: item.bioDiversity,
      percentageBioDiversity: item.percentBioDiversity,
      latitude: item.latitude,
      longitude: item.longitude,
      climate: {
        carbonDioxide: item.co2Intake,
        capturelevel: item.capturelevel,
        airCleaned: `${item.cleanedAir}cm`,
        temperature: item.temperature,
        level: item.percentTemperature,
      },
      waterAndSanitation: {
        waterLevel: item.waterLevelCleaned,
        humidity: item.soilHumidity,
        percentageHumidity: item.percentSoilHumidity,
      },
      jobs: {
        jobsCreated: item.jobsCreated,
        percentage: item.percentJobs,
      },
    }));

    const forests = metrices.map((item, index) => ({
      name: item.forest.name,
      area: item.forest.areaInSqMeters,
      isSelected: index === selectedIndex ? true : false,
    }));
    setSelectorSource(forests);
    setDetails(metricesArr);
    setSelectedSourceIndex(selectedIndex);
    setTimeout(() => setPageLoading(false), 2000);
  };

  return pageLoading ? (
    <LoaderGif />
  ) : details && forestCoordinates ? (
    <div className="content-body">
      <div className="flex justifyStart alignCenter w-100">
        <h3 className="statisticsLable">{`Statistiken für: `} </h3>
        <Selector
          source={selectorSource}
          selected={selectedSourceIndex}
          setSourceIndex={(idx) => onChangeSource(idx)}
        />
      </div>

      {details &&
        details.map((listItem) => (
          <>
            {listItem.isActive && (
              <>
                <Row gutter={[16, 16]}>
                  <Col xl={12} xs={24}>
                    <CardView large={true}>
                      <h3 className="cardTitle">Leben an Land</h3>
                      <p className="cardDescription">
                        Daten zu deinen terrestrischen Ökosystemen
                      </p>
                      <div className="card-outer-div">
                        <div
                          className="card-inner-div-1"
                          style={{
                            marginRight: 16,
                            minWidth: 200,
                          }}
                        >
                          {listItem.lifeOnLand.map((value, index) => (
                            <div
                              className="flex column justifyStart card-padding"
                              key={index + 1}
                              // style={{
                              //   padding: 12,
                              // }}
                            >
                              <div className="flex">
                                <div
                                  style={{
                                    background: value.bgColor,
                                    padding: 14,
                                    borderRadius: 26,
                                  }}
                                >
                                  <img
                                    src={value.imgSrc}
                                    alt={value.altText}
                                    height={24}
                                    width={24}
                                  />
                                </div>
                                <div
                                  className="flex column card-margin"
                                  // style={{
                                  //   marginLeft: 16,
                                  // }}
                                >
                                  <h3 style={{ color: "#274B28" }}>
                                    {value.count}
                                  </h3>
                                  <p className="small-p">{value.title}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="card-iner-div-2">
                          <div style={{ position: "relative" }}>
                            <img
                              src={BioDiversityChart}
                              alt="BioDiversityChart"
                              height={120}
                              width={180}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                textAlign: "center",
                              }}
                            >
                              <h2 style={{ color: "#ffffff" }}>
                                {`${listItem.bioDiversity}%`}
                              </h2>
                              <p
                                style={{
                                  color: "#FFFFFF",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  margin: 0,
                                }}
                              >
                                Artenvielfalt
                              </p>
                            </div>
                          </div>
                          <div className="flex justifyBetween alignCenter w-100 mt-24">
                            <p className="small-p" style={{ color: "#F1FFF2" }}>
                              <strong
                                style={{ color: "#ffffff", marginRight: 4 }}
                              >
                                {listItem.percentageBioDiversity}
                              </strong>
                              höher im Vergleich zu anliegenden Wäldern
                            </p>
                            <img
                              src={ArrowBD}
                              alt="BioDiversityChart"
                              height={24}
                              width={24}
                              style={{ marginLeft: 8 }}
                            />
                          </div>
                        </div>
                      </div>
                    </CardView>
                  </Col>
                  <Col xl={12} xs={24}>
                    <CardView large={true}>
                      <div className="flex justifyBetween alignStart w-100">
                        <div className="flex column w-100">
                          <h3 className="cardTitle">Karte</h3>
                        </div>
                      </div>
                      <div style={{ width: "100%", marginTop: 24 }}>
                        <Map
                          longitude={
                            forestCoordinates
                              ? forestCoordinates[0].longitude
                              : null
                          }
                          latitude={
                            forestCoordinates
                              ? forestCoordinates[0].latitude
                              : null
                          }
                          zoom={17}
                          width="100%"
                          height="253px"
                          polygone={
                            forestCoordinates &&
                            forestCoordinates[0] &&
                            forestCoordinates[0].polygone &&
                            forestCoordinates[0].polygone.length
                              ? forestCoordinates[0].polygone
                              : []
                          }
                        />
                        {/* <img
                          src={MapView}
                          alt="MapView"
                          height={253}
                          width="100%"
                        /> */}
                      </div>
                    </CardView>
                  </Col>
                  <Col xl={12} xs={24}>
                    <CardView large={true}>
                      <div className="col3-div-1">
                        <div
                          className="flex column"
                          style={{
                            maxWidth: "80%",
                          }}
                        >
                          <h5 style={{ color: "#101112" }}>Klimaschutz</h5>
                          <h6 style={{ color: "#757575", marginTop: 8 }}>
                            Die Klimaschutzwirkung deiner Waldstücke
                          </h6>
                        </div>
                      </div>
                      <div
                        className="temperature-div"
                        // style={{
                        //   margin: "16px 0",
                        // }}
                      >
                        <div
                          className="flex column w-100"
                          style={{
                            marginRight: 12,
                          }}
                        >
                          <div
                            className="flex justifyBetween alignCenter w-100"
                            style={{
                              background: "#274B28",
                              borderRadius: 8,
                              padding: "16px 10px",
                              height: 72,
                            }}
                          >
                            <div
                              style={{
                                background: "rgba(0, 0, 0, 0.24)",
                                borderRadius: 26,
                                padding: 12,
                                height: 48,
                                width: 48,
                              }}
                            >
                              <img
                                src={AirFilter}
                                alt="AirFilter"
                                height={24}
                                width={24}
                              />
                            </div>

                            <div
                              className="flex column"
                              style={{ margin: "0 8px", width: 80 }}
                            >
                              <h5 style={{ color: "#ffffff" }}>
                                {listItem.climate.carbonDioxide} kg
                              </h5>
                              <p
                                className="small-p"
                                style={{
                                  color: "#ffffff",
                                  fontSize: 12,
                                  marginTop: 4,
                                }}
                              >
                                CO₂ gespeichert
                              </p>
                            </div>

                            <div
                              className="flex"
                              style={{
                                background: "#ffffff",
                                padding: "8px 12px",
                                borderRadius: 100,
                              }}
                            >
                              <h4 style={{ color: "#274B28", marginRight: 12 }}>
                                {listItem.climate.capturelevel}%
                              </h4>
                              <img
                                src={StairsUp}
                                alt={StairsUp}
                                height={18}
                                width={18}
                              />
                            </div>
                          </div>
                          <div
                            className="w-100"
                            style={{
                              marginTop: 16,
                              background: "#FAFAFA",
                              height: "100%",
                              maxHeight: 200,
                              borderRadius: 8,
                            }}
                          >
                            <h3
                              style={{
                                color: "#274B28",
                                paddingTop: 24,
                                paddingLeft: 24,
                              }}
                            >
                              {listItem.climate.airCleaned}
                            </h3>
                            <p style={{ color: "#757575", paddingLeft: 24 }}>
                              Luft gereinigt
                            </p>
                            <img
                              src={AirCleanedChart}
                              alt="AirCleanedChart"
                              height={127}
                              width="100%"
                            />
                          </div>
                        </div>
                        <div
                          className="temp-icon-wrapper"
                          // style={{
                          //   background: "#E3F2FD",
                          //   borderRadius: 8,
                          //   // maxWidth: 172,
                          //   minWidth: 0,
                          //   padding: 0,
                          // }}
                        >
                          <img
                            src={TemperaturIcon}
                            alt="TemperaturIcon"
                            className="temp-img"
                          />

                          <div
                            className="flex column justifyCenter alignCenter"
                            style={{
                              width: 180,
                              padding: "0 16px",
                            }}
                          >
                            <h1 style={{ color: "#424242", marginTop: 16 }}>
                              {listItem.climate.temperature}
                            </h1>
                            <p className="small-p" style={{ color: "#757575" }}>
                              Temperature
                            </p>
                            <p
                              className="small-p"
                              style={{
                                color: "#757575",
                                marginTop: 16,
                                textAlign: "center",
                              }}
                            >
                              <strong
                                style={{ color: "#000000", marginRight: 4 }}
                              >
                                {listItem.climate.level}
                              </strong>

                              <span className="temp-p">
                                lower compared to other patches
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </CardView>
                  </Col>
                  <Col xl={12} xs={24}>
                    <CardView large={true}>
                      <div className="flex w-100">
                        <div
                          className="flex column"
                          style={{
                            maxWidth: "80%",
                          }}
                        >
                          <h5 style={{ color: "#101112" }}>
                            Clean Water and Sanitisation
                          </h5>
                          <h6 style={{ color: "#757575", marginTop: 8 }}>
                            Water levels and Humidity information
                          </h6>
                        </div>
                      </div>
                      <div
                        className="flex w-100 justifyBetween"
                        style={{
                          margin: "16px 0",
                        }}
                      >
                        <div
                          className="water-img-container"
                          // style={{
                          //   background: "#2196F3",
                          //   borderRadius: 8,
                          //   maxWidth: 175,
                          //   height: 258,
                          //   justifyContent: "space-around",
                          //   padding: 24,
                          // }}
                        >
                          <h2 style={{ color: "#ffffff" }}>
                            {`${listItem.waterAndSanitation.waterLevel} liters`}
                          </h2>
                          <p style={{ color: "#ffffff" }}>Water Cleaned</p>
                          <img
                            src={WaterCleanedChart}
                            alt="BioDiversityChart"
                            // height={72}
                            // width={212}
                            className="water-img"
                          />
                        </div>
                        <div
                          className="flex column w-100 alignCenter justifyCenter"
                          style={{
                            background: "transparent",
                            borderRadius: 4,
                            maxWidth: 264,
                            height: 275,
                            padding: 24,
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <img
                              src={HumidityBD}
                              alt="HumidityBD"
                              className="humidity-img"
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                textAlign: "center",
                              }}
                            >
                              <h1 style={{ color: "#274B28" }}>
                                {`${listItem.waterAndSanitation.humidity}%`}
                              </h1>
                              <p
                                style={{
                                  color: "#757575",
                                  fontSize: 16,
                                  fontWeight: 700,
                                  margin: 0,
                                }}
                              >
                                Humidity
                              </p>
                            </div>
                          </div>

                          <div
                            className="flex justifyBetween alignCenter w-100"
                            style={{
                              marginTop: 16,
                              borderRadius: 8,
                              padding: 8,
                              background: "#FFEBEE",
                            }}
                          >
                            <p className="small-p" style={{ color: "#757575" }}>
                              <strong
                                style={{ color: "#000000", marginRight: 4 }}
                              >
                                {`${listItem.waterAndSanitation.percentageHumidity}% `}
                              </strong>
                              higher compared to other patches
                            </p>
                            <img
                              src={ArrowSanitation}
                              alt="HumidityArrowBD"
                              height={24}
                              width={24}
                            />
                          </div>
                        </div>
                      </div>
                    </CardView>
                  </Col>
                  <Col xl={12} xs={24}>
                    <CardView large={true}>
                      <div className="flex w-100 h-100">
                        <div
                          className="flex column"
                          style={{
                            maxWidth: "80%",
                          }}
                        >
                          <h5 style={{ color: "#101112" }}>
                            Wirtschaftliche Wirkung
                          </h5>
                          <h6 style={{ color: "#757575", marginTop: 8 }}>
                            Informationen zu wirtschaftlichen Einfluss deiner
                            Waldstücke
                          </h6>
                        </div>
                      </div>
                      <div
                        className="flex w-100 h-100"
                        style={{
                          margin: "16px 0",
                        }}
                      >
                        <div
                          className="flex justifyBetween alignCenter w-100"
                          style={{
                            background: "#FFEB3B",
                            borderRadius: 8,
                            padding: 16,
                          }}
                        >
                          <div
                            style={{
                              background: "#ffffff",
                              borderRadius: 26,
                              padding: 12,
                              height: 48,
                              width: 48,
                            }}
                          >
                            <img
                              src={Accounts}
                              alt="Accounts"
                              height={24}
                              width={24}
                            />
                          </div>

                          <div
                            style={{
                              borderRadius: 100,
                              marginLeft: 16,
                            }}
                          >
                            <h3 style={{ color: "#274B28", marginRight: 8 }}>
                              {`${listItem.jobs.jobsCreated} jobs created`}
                            </h3>
                            <p
                              className="small-p"
                              style={{ color: "#757575", marginTop: 8 }}
                            >
                              Deine Waldstücke erschaffen
                              <strong
                                style={{ color: "#000000", marginRight: 4 }}
                              >
                                {` ${listItem.jobs.percentage}% `}
                              </strong>
                              Arbeitsplätze und verringern Armut.
                            </p>
                          </div>
                        </div>
                      </div>
                    </CardView>
                  </Col>
                </Row>
              </>
            )}
          </>
        ))}
    </div>
  ) : (
    <NoData source={noData} />
  );
};

export default Dashboard;
