import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, message, notification } from "antd";
import StyledLink from "../components/common/NavLink";
import LoadingButton from "../components/common/LoadingButton";
import Poster from "../assets/landing-poster.png";
import LogoTextual from "../assets/logo.svg";
import { login } from "../store/app/actions";
import { history } from "../index";
import api from "../services/api/ApiService";
import "../login.css";

const SignIn = () => {
  const [submitting, setSubmitting] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const [loginFailed, setLoginFailed] = useState(false);
  const [email, setEmail] = useState("");

  const onFinishFailed = (error) => {
    setSubmitting(false);
    setLoginFailed(true);
    notification.error({
      message: error,
      duration: 6,
      style: {
        backgroundColor: "#fff",
        color: "#ff0000",
        zIndex: 100,
        borderRadius: 4,
        wordSpacing: "-5px",
      }
    });
  };

  const onFinishSuccess = () => {
    setSubmitting(false);
  };

  const onFinish = async (values) => {
    if (values) {
      setEmail(values.emailAddress);
      setSubmitting(true);
      dispatch(login(values, onFinishSuccess, onFinishFailed));
    }
  };

  return (
    <div className="login-outer-div">
      <div className="login-image-div">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
            width: "100%",
            position: "relative",
          }}
        >
          <img src={Poster} alt="poster" className="poster-image" />
          <div className="logo-div">
            <a href={process.env.REACT_APP_STATIC_PAGES_URL}>
              <img src={LogoTextual} alt="logo" className="logo" />
            </a>
          </div>
        </div>
      </div>
      <div className="form-div">
        <Form
          ref={formRef}
          layout="vertical"
          style={{ width: "70%", maxWidth: 520, textAlign: "center" }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h1
            style={{
              color: "#274B28",
              textAlign: "left",
              fontSize: "32px",
              wordSpacing: "-10px",
            }}
          >
            Log into your account
          </h1>
          <Form.Item
            label="Email-Adresse"
            name="emailAddress"
            style={{ marginTop: 32 }}
            rules={[
              {
                required: true,
                message: "Please enter valid email address",
              },
            ]}
            required={false}
          >
            <Input
              type="email"
              style={{
                borderRadius: 4,
                background: "#FAFAFA",
                border: "1px solid #E0E0E0",
                boxShadow: "none",
                height: 56,
              }}
              className="login-input"
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
            required={false}
          >
            <Input.Password
              type="password"
              style={{
                borderRadius: 4,
                background: "#FAFAFA",
                border: "1px solid #E0E0E0",
                boxShadow: "none",
                height: 56,
              }}
              className="login-input"
            />
          </Form.Item>
          <span
            onClick={() => history.push("/forgot-password")}
            className="forgot-pwd"
          >
            Forgot password?
          </span>
          {loginFailed && (
            <span
              className="forgot-pwd"
              onClick={async () => {
                const response = await api.post("/resend-activation-email", {
                  emailAddress: email,
                });
                if (response.data.metadata.code === 200) {
                  notification.success({
                    message: response.data.metadata.message,
                    duration: 6,
                    style: {
                      backgroundColor: "#fff",
                      color: "#ff0000",
                      zIndex: 100,
                      borderRadius: 4,
                      wordSpacing: "-5px",
                    },
                  });
                }
              }}
            >
              Resend Confirmation Email
            </span>
          )}
          {/* Login button */}
          <LoadingButton
            btnClass="green-button"
            submitting={submitting}
            label="Login"
            style={{ marginTop: 32 }}
          />

          {/* Go to sign up link */}
          <div
            style={{
              marginTop: 32,
              textAlign: "center",
              color: "#000000",
              wordSpacing: "-5px",
            }}
          >
            <span>
              Don't have an account?
              <StyledLink to="/register" style={{ margin: "0 4px" }}>
                Sign up
              </StyledLink>
              here
            </span>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SignIn;
