import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, notification } from "antd";
import StyledLink from "../components/common/NavLink";
import FormContainer from "./common/FormContainer";
import LoadingButton from "../components/common/LoadingButton";
import { forgotPassword } from "../store/app/actions";

const ForgotPassword = () => {
  const [submitting, setSubmitting] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();

  const onFinishFailed = (error) => {
    setSubmitting(false);
    notification.error({
      message: error,
      duration: 6,
      style: {
        backgroundColor: "#fff",
        color: "#274B28",
      },
    });
  };

  const onFinishSuccess = () => {
    setSubmitting(false);
  };

  const onFinish = async (values) => {
    if (values) {
      setSubmitting(true);
      dispatch(forgotPassword(values, onFinishSuccess, onFinishFailed));
    }
  };

  return (
    <FormContainer>
      <Form
        ref={formRef}
        layout="vertical"
        style={{ width: "75%", textAlign: "center" }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <h1
          style={{
            color: "#274B28",
            marginBottom: 24,
            textAlign: "left",
          }}
        >
          Forgot Password
        </h1>
        <Form.Item
          label="Email-Adresse"
          name="emailAddress"
          rules={[
            {
              required: true,
              message: "Please enter valid email address",
            },
          ]}
          required={false}
        >
          <Input
            type="email"
            style={{
              borderRadius: 4,
              background: "#FAFAFA",
              border: "1px solid #E0E0E0",
              boxShadow: "none",
              height: 56,
            }}
          />
        </Form.Item>

        {/* Send email verification to reset your password */}
        <LoadingButton
          btnClass="green-button"
          submitting={submitting}
          label="Confirm Email"
          style={{ minWidth: 448, marginTop: 32, }}
        />

        {/* Go to login link */}
        <div
          style={{
            marginTop: 32,
            textAlign: "center",
            color: "#000000"
          }}>
          <span>
            Back to
            <StyledLink to="/login" style={{ margin: "0 4px" }}>
              Login
            </StyledLink>
          </span>
        </div>
      </Form>
    </FormContainer>
  );
};

export default ForgotPassword;
