import React, { useState } from "react";
import ShareIcon from "../../../assets/icons/share-variant.svg";
import ShareGreenIcon from "../../../assets/icons/share-variant-green.svg";
import ShareMedia from "../ShareOnSocialMedia";
import EditForestName from "./EditForestName";
import CancelIcon from "../../../assets/icons/cross.png";
import { Button } from "antd";

function SliderContent({ activeIndex, source, isNew, buyNow }) {
  const {
    images,
    name,
    areaInSqMeters,
    address: { streetName, country },
    SubscriptionPlans,
  } = source;
  const plan = SubscriptionPlans.filter(
    (item) => item.subscriptionType === "monthly"
  );
  const { currency, amountPerSqm } = plan[0] || {};

  const [showShareCard, setShowShareCard] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [forestName, setForestName] = useState("");
  const [forestIconIndex, setForestIconIndex] = useState(0);

  return (
    <section>
      {/* Slider */}
      {images &&
        images.map((slide, index) => (
          <div
            key={index}
            className={index === activeIndex ? "slides active" : "inactive"}
          >
            <img
              className="sliding-image"
              src={slide}
              alt={"slide" + index + 1}
            />
          </div>
        ))}

      <div
        className="slider_wrapper"
        style={{
          position: "absolute",
          top: 0,
          borderRadius: 8,
          background:
            "linear-gradient(179.65deg, #000000 0.31%, rgba(0, 0, 0, 0.4) 37.37%)",
        }}
      >
        <div className="slider-left-sec">
          <h2 className="slide-title">{name}</h2>
          <p className="slide-text">{`${streetName} - ${areaInSqMeters} sq Patch | ${country}`}</p>
        </div>
        <div
          className="slider-right-text"
          style={{ marginRight: isNew ? 72 : 32 }}
        >
          {isNew === "true" && (
            <div
              className="flex alignCenter"
              style={{ margin: "4px 0 0 16px" }}
            >
              <div style={{ position: "relative", zIndex: 10 }}>
                <div
                  className="flex column justifyStart"
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: 12,
                    boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.25)",
                    width: 325,
                    height: 268,
                    padding: 24,
                  }}
                >
                  <div className="flex justifyBetween alignStart w-100">
                    <div className="flex column justifyStart">
                      <h2 style={{ color: "#424242", fontWeight: 900 }}>
                        {`${currency} ${amountPerSqm || "0.00"}`}
                      </h2>
                      <p style={{ color: "#9E9E9E", margin: "4px 0 0" }}>
                        Minimale Spende pro qm
                      </p>
                    </div>
                    <img
                      onClick={() => {
                        setShowShareCard(!showShareCard);
                      }}
                      style={{ cursor: "pointer" }}
                      src={ShareGreenIcon}
                      alt={ShareGreenIcon}
                      height={24}
                      width={24}
                    />
                  </div>
                  <div
                    className="buyNow"
                    style={{
                      marginTop: 16,
                      height: 54,
                    }}
                    onClick={buyNow}
                  >
                    <h3 style={{ color: "#274B28" }}>Waldpatenschaft</h3>
                  </div>
                </div>
                {showShareCard && (
                  <div
                    style={{
                      position: "absolute",
                      left: 16,
                      top: 16,
                      background: "#ffffff",
                      boxShadow: "0px 1px 18px rgba(0, 0, 0, 0.12)",
                      border: "1px solid #EEEEEE",
                      borderRadius: 12,
                      width: 262,
                      height: 214,
                      zIndex: 20,
                      padding: "22px 24px",
                    }}
                  >
                    <div className="flex justifyBetween w-100">
                      <p className="small-p textGray">
                        Teile dein Waldstück mit der Welt
                      </p>
                      <img
                        onClick={() => {
                          setShowShareCard(!showShareCard);
                        }}
                        style={{ cursor: "pointer" }}
                        src={CancelIcon}
                        alt={CancelIcon}
                        height={14}
                        width={14}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: 12,
                      }}
                    >
                      <ShareMedia hideCard={() => setShowShareCard(false)} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {isNew === "false" && (
            <div
              className="flex alignCenter"
              style={{ margin: "4px 0 0 16px" }}
            >
              <h2 style={{ color: "#ffffff", display: "none" }}>|</h2>
              <div style={{ position: "relative", marginLeft: 12 }}>
                <img
                  onClick={() => {
                    setShowShareCard(!showShareCard);
                  }}
                  style={{ cursor: "pointer" }}
                  src={ShareIcon}
                  alt={ShareIcon}
                  height={24}
                  width={24}
                />
                {showShareCard && (
                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      top: -24,
                      background: "#ffffff",
                      boxShadow: "0px 1px 18px rgba(0, 0, 0, 0.12)",
                      border: "1px solid #EEEEEE",
                      borderRadius: 8,
                      width: 262,
                      height: 214,
                      zIndex: 20,
                      padding: "22px 24px",
                    }}
                  >
                    <div className="flex justifyBetween">
                      <p className="small-p textGray">
                        Teile dein Waldstück mit der Welt
                      </p>
                      <img
                        onClick={() => {
                          setShowShareCard(!showShareCard);
                        }}
                        style={{ cursor: "pointer" }}
                        src={CancelIcon}
                        alt={CancelIcon}
                        height={14}
                        width={14}
                      />
                    </div>
                    <div style={{ marginTop: 12 }}>
                      <ShareMedia hideCard={() => setShowShareCard(false)} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <EditForestName
        name={forestName}
        index={forestIconIndex}
        showModal={showEditModal}
        closeModal={() => setShowEditModal(false)}
      />
    </section>
  );
}

export default SliderContent;
