import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Form, Input } from "antd";
import LoadingFormButton from "./LoadingFormButton";
import PencileOutline from "../../assets/icons/pencil-outline.svg";
import LightCalendar from "../../assets/icons/calendar-light.svg";
import DarkCalendar from "../../assets/icons/calendar-dark.svg";
import RedCross from "../../assets/icons/close-circle.svg";
import GreenTick from "../../assets/icons/check-circle.svg";
import PulseLoader from "./PulseLoader";
import BackArrow from "../../assets/icons/arrow-left.svg";
import moment from "moment";
import RadioBoxBlank from "../../assets/icons/radiobox-blank.svg";
import RadioBoxMarked from "../../assets/icons/radiobox-marked.svg";
import { createNotification } from "../../store/app/actions";

import UNIONPAY from "../../assets/payment-methods/union.svg";
import AMEX from "../../assets/payment-methods/amex.svg";
import DINERS from "../../assets/payment-methods/diners.svg";
import DISCOVER from "../../assets/payment-methods/discover.svg";
import JBC from "../../assets/payment-methods/jbc.svg";
import MASTERCARD from "../../assets/payment-methods/master.svg";
import VISACARD from "../../assets/payment-methods/visa.svg";
import UnSubModal from "./un-subscribe-modal/unsubscribe-modal";
import {
  renewSubscription,
  unSubscribeofSubscription,
} from "../../store/subscriptions/actions";
import { history } from "../..";
import "../../edit-payment.css";

const EditPayment = ({
  source,
  sourceId,
  paymentMethods,
  paymnetMethodId,
  visible,
  onClose,
  afterUpdate,
}) => {
  const [updateSource, setUpdateSource] = useState();

  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [changesSubmitted, setChangesSubmitted] = useState(false);
  const [showPulseLoader, setShowPulseLoader] = useState(false);
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);
  const [editArea, setEditArea] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [perSqmPrice, setPerSqmPrice] = useState(0);
  const [changedAreaValue, setChangedAreaValue] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [activePaymentMethods, setActivePaymentMethods] = useState([]);
  const [active, setActive] = useState(false);
  const [maxArea, setMaxArea] = useState(0);
  // function onClose(){
  //   setIsVisible(false)
  // }
  const checkDateState = (dateToBeCompared) => {
    const isBefore = moment(dateToBeCompared).isBefore(moment());
    const isToday = moment(dateToBeCompared).isSame(moment());
    const isFuture = moment(dateToBeCompared).isAfter(moment());

    if (isBefore && !isToday && !isFuture) {
      return true;
    } else if (!isBefore && isToday && !isFuture) {
      return true;
    } else if (!isBefore && !isToday && isFuture) {
      return false;
    }
  };

  const finishLoading = () => {
    setShowPulseLoader(false);
  };

  const stopLoading = () => {
    setTimeout(finishLoading, 500);
  };
  const onResultRedirect = () => {
    history.push("/dashboard/payments");
  };
  const unsubscribe = (event, payment) => {
    event.preventDefault();
    dispatch(
      unSubscribeofSubscription(
        {
          subscriptionId: payment.stripeSubscriptionId,
          id: payment.id,
        },
        onResultRedirect
      )
    );
  };

  const onChangeArea = async (area) => {
    if (area <= maxArea) {
      setShowPulseLoader(true);
      const calculatePayment = area * perSqmPrice;
      setChangedAreaValue(area);
      setTotalAmount(Number(calculatePayment).toFixed(2));
      stopLoading();
    } else {
      dispatch(
        createNotification("error-toast", "You have reached the max area limit")
      );
    }
  };

  const onSaveChangesForPaymentMethod = () => {
    setChangesSubmitted(true);
    const paymentMethod = activePaymentMethods.filter((item) => {
      return item.isActive === true;
    });

    setPaymentMethodId(paymentMethod[0].id);
    setChangesSubmitted(false);
    setChangePaymentMethod(false);
  };

  const selectPaymentPlan = (index) => {
    setActive(true);
    const methods = activePaymentMethods.map((item, idx) => ({
      ...item,
      isActive: idx === index ? true : false,
    }));
    setActivePaymentMethods(methods);
  };

  const onGoBack = () => {
    const methods = activePaymentMethods.map((item) => ({
      ...item,
      isActive: item.id === paymnetMethodId ? true : false,
    }));
    setActivePaymentMethods(methods);
    setChangePaymentMethod(false);
  };

  useEffect(() => {
    if (paymentMethods) {
      var methods = paymentMethods;
      if (methods) {
        methods =
          methods &&
          methods.map((item) => ({
            ...item,
            isActive: item.id === paymnetMethodId ? true : false,
          }));
        setActivePaymentMethods(methods);
      }
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (source) {
      const updateSource = source.filter((item) => {
        return item.id === sourceId;
      });
      const persqm = Number(updateSource[0].subscriptionPlan["amountPerSqm"]);
      setPerSqmPrice(persqm);
      const preArea = Number(updateSource[0].areaInSqMeters);
      setChangedAreaValue(preArea);
      const tPay = (
        Number(updateSource[0].areaInSqMeters) *
        Number(updateSource[0].subscriptionPlan["amountPerSqm"])
      ).toFixed(2);
      setTotalAmount(tPay);
      const pMId = updateSource[0].paymentMethodId;
      setPaymentMethodId(pMId);
      const setMaxAreaValue = Number(
        updateSource[0].forest["maximumUnitsInSqAvailableForSale"]
      );
      setMaxArea(setMaxAreaValue);
      setUpdateSource(updateSource);
    }
  }, [source]);

  const sendingResult = () => {
    setSubmitting(false);
    onClose();
    afterUpdate();
  };

  const onDone = async () => {
    setSubmitting(true);
    const paymentMethod = activePaymentMethods.filter((item) => {
      return item.isActive === true;
    });

    // set history params
    const history = {
      isActive: false,
      userId: updateSource[0].userId,
      forestId: updateSource[0].forestId,
      paymentMethodId: updateSource[0].paymentMethodId,
      promotionCodeId: updateSource[0].promotionCodeId || null,
      subscriptionPlanId: updateSource[0].subscriptionPlanId,
      stripeSubscriptionId: updateSource[0].stripeSubscriptionId,
      areaInSqMeters: Number(updateSource[0].areaInSqMeters),
      currency: updateSource[0].currency,
      expiryDate: updateSource[0].expiryDate,
      subscriptionId: updateSource[0].id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
    // set update subscription params
    const updateSubscription = {
      paymentMethodId,
      areaInSqMeters: Number(changedAreaValue),
      updatedAt: new Date().toISOString(),
    };

    // sending params
    const submitParams = {
      history, // create subscription history
      updateSubscription, // update existing subscription
      subscriptionId: updateSource[0].id, // update existing subscription with this id
      updatedArea: Number(changedAreaValue),
      chargeAmount: Number(totalAmount), // update amount in payment
      stripeSubscriptionId: updateSource[0].stripeSubscriptionId, // update changes in stripe using stripe subscription id
      forestId: updateSource[0].forestId, // update forest max area value using forest id
      maxArea:
        Number(maxArea) +
        Number(updateSource[0].areaInSqMeters) -
        Number(changedAreaValue), // max area value now
      priceId: updateSource[0].subscriptionPlan["priceId"],
    };

    dispatch(renewSubscription(submitParams, sendingResult));
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      closable={false}
      style={{ top: 24 }}
      bodyStyle={{ backgroundColor: "#ffffff", padding: 0, borderRadius: 8 }}
      width={576}
    >
      {updateSource &&
        updateSource.map((payment, index) => {
          return (
            <div className="h-100 w-100" key={index + 1}>
              {changePaymentMethod ? (
                <div className="flex column w-100">
                  <div
                    className="flex justifyStart alignCenter"
                    style={{
                      padding: "28px 24px",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <img
                      src={BackArrow}
                      alt={BackArrow}
                      height={24}
                      width={24}
                      style={{ cursor: "pointer", marginRight: 16 }}
                      onClick={() => onGoBack()}
                    />
                    <h2 className="textGreen">Manage Payment Method</h2>
                  </div>

                  <div className="settings-main" style={{ padding: 24 }}>
                    {activePaymentMethods &&
                      activePaymentMethods.map((activePayment, pIndex) => (
                        <div
                          className="flex justifyStart alignCenter w-100"
                          style={{
                            marginTop: 24,
                            background: payment.isActive
                              ? "#F1FFF2"
                              : "#ffffff",
                            padding: 26,
                            borderRadius: 8,
                            border: payment.isActive
                              ? "1px solid #274B28"
                              : "1px solid #E0E0E0",
                          }}
                          key={pIndex + 1}
                          onClick={() => {
                            selectPaymentPlan(pIndex);
                          }}
                        >
                          <div
                            className="flex alignCenter"
                            style={{
                              margin: "0 24px",
                            }}
                          >
                            {activePayment.isActive ? (
                              <img
                                src={RadioBoxMarked}
                                alt="radi-box-marked"
                                height={24}
                                width={24}
                              />
                            ) : (
                              <img
                                src={RadioBoxBlank}
                                alt="radio-box-blank"
                                height={24}
                                width={24}
                              />
                            )}
                          </div>

                          <div className="flex alignCenter">
                            {activePayment.brand === "amex" && (
                              <img
                                src={AMEX}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            {activePayment.brand === "mastercard" && (
                              <img
                                src={MASTERCARD}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            {activePayment.brand === "discover" && (
                              <img
                                src={DISCOVER}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            {activePayment.brand === "diners" && (
                              <img
                                src={DINERS}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            {activePayment.brand === "jcb" && (
                              <img
                                src={JBC}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            {activePayment.brand === "unionpay" && (
                              <img
                                src={UNIONPAY}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            {activePayment.brand === "visa" && (
                              <img
                                src={VISACARD}
                                alt="payment-method-icon"
                                height={40}
                                width={40}
                              />
                            )}

                            <div
                              className="flex column"
                              style={{
                                marginLeft: 16,
                              }}
                            >
                              {activePayment.brand === "amex" && (
                                <h4 className="textGray">
                                  {`American Express Card - ${activePayment.cardNumber}`}
                                </h4>
                              )}

                              {activePayment.brand === "mastercard" && (
                                <h4 className="textGray">
                                  {`Master Card - ${activePayment.cardNumber}`}
                                </h4>
                              )}

                              {activePayment.brand === "discover" && (
                                <h4 className="textGray">
                                  {`Discover Card - ${activePayment.cardNumber}`}
                                </h4>
                              )}

                              {activePayment.brand === "diners" && (
                                <h4 className="textGray">
                                  {`DINERS Club Card - ${activePayment.cardNumber}`}
                                </h4>
                              )}

                              {activePayment.brand === "jcb" && (
                                <h4 className="textGray">
                                  {`JBC Card - ${activePayment.cardNumber}`}
                                </h4>
                              )}

                              {activePayment.brand === "unionpay" && (
                                <h4 className="textGray">
                                  {`Union Pay Card - ${activePayment.cardNumber}`}
                                </h4>
                              )}

                              {activePayment.brand === "visa" && (
                                <h4 className="textGray">
                                  {`Visa Card - ${activePayment.cardNumber}`}
                                </h4>
                              )}

                              <p
                                className="small-p textGray"
                                style={{ margin: "8px 0 0" }}
                              >
                                {`Expires ${
                                  activePayment.expiryMonth <= 9
                                    ? `0${activePayment.expiryMonth}`
                                    : activePayment.expiryMonth
                                }-${activePayment.expiryYear}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}

                    <div
                      className="flex justifyEnd w-100"
                      style={{ marginTop: 64 }}
                    >
                      <div
                        className="flex column justifyCenter alignCenter"
                        style={{
                          background: "#F5F5F5",
                          borderRadius: 100,
                          cursor: "pointer",
                          width: 180,
                          height: 40,
                          marginRight: 24,
                        }}
                        onClick={onGoBack}
                      >
                        <h4 className="textGray">Cancel</h4>
                      </div>

                      {!active ? (
                        <div
                          className="flex justifyCenter alignCenter"
                          style={{
                            background: "#F5F5F5",
                            borderRadius: 100,
                            cursor: "not-allowed",
                            width: 180,
                            height: 40,
                          }}
                        >
                          <h4 className="textGray">Speichern</h4>
                        </div>
                      ) : (
                        <LoadingFormButton
                          submitting={changesSubmitted}
                          label="Speichern"
                          onClick={onSaveChangesForPaymentMethod}
                          style={{ width: 180, height: 40 }}
                          className={"update-button"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="main-padding">
                  <h2 className="textGreen" style={{textAlign:"Left"}}>{payment.forest["name"]}</h2>

                  <p
                    className="textGray"
                    style={{ margin: "8px 0 0", textTransform: "capitalize" }}
                  >
                    {`${payment.address["city"]} | ${payment.address["country"]}`}
                  </p>

                  <div style={{ marginTop: 24 }}>
                    {checkDateState(payment.expiryDate) ? (
                      <div className="overdue">
                        <img
                          src={LightCalendar}
                          alt={LightCalendar}
                          height={24}
                          width={24}
                        />
                        <p
                          style={{
                            fontSize: 16,
                            lineHeight: "20px",
                            margin: "0px 0px 0px 8px",
                          }}
                        >
                          {`Your payment is overdue.`}
                        </p>
                      </div>
                    ) : (
                      <div
                        className={
                          payment.isActive ? "within-due" : "in-active"
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={DarkCalendar}
                            alt={DarkCalendar}
                            height={24}
                            width={24}
                          />
                          <p
                            className="small-p"
                            style={{
                              margin: "0px 0px 0px 8px",
                              color: "#424242",
                            }}
                          >
                            {payment.isActive
                              ? `Your next payment is `
                              : "Your Subscription will end on "}
                            {payment.isActive ? (
                              <b style={{ color: "#424242" }}>
                                {`${payment.currency} ${(
                                  Number(payment.areaInSqMeters) *
                                  Number(
                                    payment.subscriptionPlan["amountPerSqm"]
                                  )
                                ).toFixed(2)}`}
                              </b>
                            ) : (
                              <></>
                            )}
                            {payment.isActive ? ` is due on ` : ""}
                            <b style={{ color: "#424242" }}>
                              {payment.expiryDate}
                            </b>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className="flex column justifyStart"
                    style={{
                      marginTop: 32,
                    }}
                  >
                    <h4
                      style={{
                        color: "#424242",
                      }}
                    >
                      Deine Waldfläche und Spendenhöhe
                    </h4>

                    {editArea ? (
                      <div
                        className="w-100"
                        style={{
                          marginTop: 16,
                          boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.12)",
                          borderRadius: 8,
                        }}
                      >
                        <div
                          className="flex w-100"
                          style={{
                            position: "relative",
                            height: 56,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                          }}
                        >
                          <Form.Item
                            style={{
                              minWidth: "100%",
                              width: "100%",
                              height: "100%",
                              position: "relative",
                              padding: 0,
                              border: "none",
                              background: "#ffffff",
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Waldname muss ausgefüllt sein.",
                              },
                            ]}
                            required={false}
                          >
                            <Input
                              value={changedAreaValue}
                              type="number"
                              onChange={(e) => {
                              const inputValue = e.target.value
                              if (inputValue <= maxArea) {
                              onChangeArea(inputValue);
                              } else if (Number(e.target.value) <= 0) {
                                  onChangeArea(1);
                                }
                              }}
                              style={{
                                height: 56,
                                color: "#424242",
                                border: "none",
                                fontSize: 18,
                                lineHeight: 23,
                                boxShadow: "none",
                              }}
                            />
                          </Form.Item>

                          <div
                            className="flex justifyEnd alignCenter h-100"
                            style={{
                              position: "absolute",
                              right: 0,
                            }}
                          >
                            <img
                              src={RedCross}
                              alt={RedCross}
                              height={24}
                              width={24}
                              style={{
                                marginRight: 10,
                                cursor: "pointer",
                              }}
                              onClick={() => setEditArea(false)}
                            />

                            <img
                              src={GreenTick}
                              alt={GreenTick}
                              height={24}
                              width={24}
                              style={{
                                marginRight: 16,
                                cursor: "pointer",
                              }}
                              onClick={() => setEditArea(false)}
                            />
                          </div>
                        </div>
                        <div
                          className="flex justifyBetween"
                          style={{
                            background: "#FAFAFA",
                            padding: 16,
                            borderBottonLeftRadius: 8,
                            borderBottomRightRadius: 8,
                          }}
                        >
                          <h4 className="textGray">{`Spendenhöhe`}</h4>

                          {showPulseLoader ? (
                            <PulseLoader />
                          ) : (
                            <h4
                              style={{
                                color: "#424242",
                              }}
                            >
                              {`${payment.currency} ${totalAmount}`}
                            </h4>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="flex column"
                        style={{
                          boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.12)",
                          marginTop: 16,
                          borderRadius: 8,
                        }}
                      >
                        <div
                          className="flex justifyBetween"
                          style={{
                            background: "#ffffff",
                            padding: 16,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                          }}
                        >
                          <h4
                            style={{
                              color: "#424242",
                            }}
                          >
                            {`${changedAreaValue} Quadratmeter`}
                          </h4>
                          <img
                            src={PencileOutline}
                            alt={PencileOutline}
                            height={24}
                            width={24}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setMaxArea(
                                Number(
                                  payment.forest[
                                    "maximumUnitsInSqAvailableForSale"
                                  ]
                                )
                              );
                              setEditArea(true);
                            }}
                          />
                        </div>
                        <div
                          className="flex justifyBetween"
                          style={{
                            background: "#FAFAFA",
                            padding: 16,
                            borderBottonLeftRadius: 8,
                            borderBottomRightRadius: 8,
                          }}
                        >
                          <h4 className="textGray">{`Spendenhöhe`}</h4>
                          <h4
                            style={{
                              color: "#424242",
                            }}
                          >
                            {`${payment.currency} ${totalAmount}`}
                          </h4>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className="flex column justifyStart"
                    style={{
                      marginTop: 32,
                    }}
                  >
                    <h4 style={{ color: "#424242" }}>
                      Dein Spendenplan
                    </h4>
                    <div
                      className="flex column"
                      style={{
                        background: "#ffffff",
                        boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.12)",
                        borderRadius: 8,
                        marginTop: 16,
                      }}
                    >
                      <div
                        className="flex"
                        style={{
                          background: "#ffffff",
                          padding: 16,
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                        }}
                      >
                        <h4
                          style={{
                            color: "#424242",
                            textTransform: "capitalize",
                          }}
                        >
                          {`${payment.subscriptionPlan["subscriptionType"]} subscription`}
                        </h4>
                      </div>
                    </div>
                  </div>

                  {payment.isActive ? (
                    <div
                      className="flex justifyCenter alignCenter"
                      style={{
                        marginTop: 32,
                        background: "#FFEBEE",
                        borderRadius: 8,
                        padding: "10px 0",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{ color: "#F44336", fontSize: 10 }}
                        onClick={(event) => {
                          setIsVisible(true);
                          // unsubscribe(event, payment);
                        }}
                      >
                        Spenden beenden
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="flex column">
                    <div
                      className="flex justifyEnd w-100"
                      style={{
                        marginTop: 24,
                      }}
                    >
                      <div className="editpayment_cancel_btn" onClick={onClose}>
                        <h4 className="textGray" style={{textAlign:"center"}}>Cancel</h4>
                      </div>

                      <LoadingFormButton
                        submitting={submitting}
                        label="Update Subscription"
                        onClick={onDone}
                        className={"update-button"}
                        style={{ width: 220, fontSize:"16px" }}
                        status={!payment.isActive}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div>
                {isVisible && (
                  <UnSubModal
                    showModal={isVisible}
                    closeModal={() => {
                      setIsVisible(false);
                    }}
                    unsubscribe={unsubscribe}
                    payment={payment}
                  />
                )}
              </div>
            </div>
          );
        })}
    </Modal>
  );
};

export default EditPayment;
