import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, notification } from "antd";
import StyledLink from "../components/common/NavLink";
import LoadingButton from "../components/common/LoadingButton";
import Poster from "../assets/landing-poster.png";
import LogoTextual from "../assets/logo.svg";
import { signup } from "../store/app/actions";
import "../login.css";

const SignUp = () => {
  const [submitting, setSubmitting] = useState(false);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef();
  const [isNewsLetter, setIsNewsLetter] = useState(false);
  const onFinishFailed = (error) => {
    setSubmitting(false);
    notification.error({
      message: error,
      duration: 6,
      style: {
        backgroundColor: "#fff",
        color: "#ff0000",
        zIndex: 100,
        borderRadius: 4,
      },
    });
  };

  const onFinishSuccess = () => {
    setSubmitting(false);
    const script = document.createElement('script');
    script.innerHTML = "gtag('event', 'conversion', {'send_to': 'AW-10941038690/k31oCIa27NgDEOKAjOEo'});"
    document.body.appendChild(script);
  };

  const onFinish = async (values) => {
    if (checked) {
      setSubmitting(true);
      if (values) {
        values.newsLetterActivated = isNewsLetter;
        dispatch(signup(values, onFinishSuccess, onFinishFailed));
      }
    } else {
      notification.error({
        message: "Please accept our terms and conditions and privacy policy",
        duration: 6,
        style: {
          backgroundColor: "#fff",
          color: "#ff0000",
          zIndex: 100,
          borderRadius: 4,
        },
      });
    }
  };

  return (
    <div className="login-outer-div">
      <div className="login-image-div">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
            width: "100%",
            position: "relative",
          }}
        >
          <img src={Poster} alt="poster" className="poster-image" />

          {/* <div className="logo-div">
            <img src={LogoTextual} alt="logo" className="logo" />
          </div> */}

          <div className="logo-div">
            <a href={process.env.REACT_APP_STATIC_PAGES_URL}>
              <img src={LogoTextual} alt="logo" className="logo" />
            </a>
          </div>
        </div>
      </div>
      <div className="form-div">
        <Form
          ref={formRef}
          layout="vertical"
          style={{ width: "70%", maxWidth: 520, textAlign: "center" }}
          onFinish={onFinish}
          onFinishFailed={() => onFinishFailed("Sign up failed")}
        >
          <h1
            style={{
              color: "#274B28",
              marginBottom: 24,
              textAlign: "left",
              wordSpacing: "-10px",
            }}
          >
            Sign up
          </h1>
          {/* <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 12,
              flexDirection: ,
            }}
          > */}
          <Form.Item
            className="settings-form-item"
            style={{ marginRight: 16 }}
            label="Vorname"
            name="firstName"
            rules={[
              {
                required: true,
                message: "First name is required",
              },
            ]}
            required={true}
          >
            <Input
              type="text"
              style={{
                borderRadius: 4,
                background: "#FAFAFA",
                border: "1px solid #E0E0E0",
                boxShadow: "none",
                height: 56,
              }}
              className="login-input"
            />
          </Form.Item>
          <Form.Item
            className="settings-form-item"
            label="Nachname"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Last name is required",
              },
            ]}
            required={true}
          >
            <Input
              type="text"
              style={{
                borderRadius: 4,
                background: "#FAFAFA",
                border: "1px solid #E0E0E0",
                boxShadow: "none",
                height: 56,
              }}
              className="login-input"
            />
          </Form.Item>
          {/* </div> */}
          <Form.Item
            label="E-Mail Adresse"
            name="emailAddress"
            rules={[
              {
                required: true,
                message: "Bitte trage eine richtige E-Mail Adresse ein.",
              },
            ]}
            required={false}
          >
            <Input
              type="email"
              style={{
                borderRadius: 4,
                background: "#FAFAFA",
                border: "1px solid #E0E0E0",
                boxShadow: "none",
                height: 56,
              }}
              className="login-input"
            />
          </Form.Item>
          <Form.Item
            label="Passwort erstellen"
            name="password"
            rules={[
              {
                required: true,
                message: "Passwort ist ein Pflichtfeld",
              },
            ]}
            required={false}
          >
            <Input.Password
              style={{
                borderRadius: 4,
                background: "#FAFAFA",
                border: "1px solid #E0E0E0",
                boxShadow: "none",
                height: 56,
              }}
              className="login-input"
            />
          </Form.Item>

          <div
            className="w-100 mt-16"
            style={{
              color: "#000000",
            }}
          >
            <label className="checkbox_container">
              <input
                type="checkbox"
                checked={checked}
                onClick={() => setChecked(!checked)}
              />
              <span className="checkmark" />
            </label>
            <span className="wspace_4" style={{ marginLeft: 26 }}>
              Ich akzeptiere die
              <span
                className="pointer checkbox_link"
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_STATIC_PAGES_URL + "/privacy-policy")
                }
              >
                Datenschutzrichtlinien
              </span>
            </span>
          </div>
          <div
            className="w-100 mt-16"
            style={{
              color: "#000000",
            }}
          >
            <label className="checkbox_container">
              <input
                type="checkbox"
                checked={isNewsLetter}
                onClick={() => setIsNewsLetter(!isNewsLetter)}
              />
              <span className="checkmark" />
            </label>

            <p className="wspace_4" style={{ marginLeft: 26 }}>
              Möchtest du den Newsletter abonnieren?
            </p>
          </div>
          {/* Sign up button */}
          <LoadingButton
            btnClass="green-button"
            submitting={submitting}
            label="Sign up"
            style={{ minWidth: 0, marginTop: 32, wordSpacing: "-5px" }}
          />

          {/* Go to login link */}
          <div
            style={{
              marginTop: 32,
              textAlign: "center",
              color: "#000000",
              wordSpacing: "-5px",
            }}
          >
            <span>
              Du hast schon einen Account?
              <StyledLink to="/login" style={{ margin: "0 4px" }}>
                Login
              </StyledLink>
              hier
            </span>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SignUp;
