import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoaderGif from "../components/common/LoaderGif";
import NoData from "../components/common/NoData";
import SearchBar from "../components/SearchBar";
import NoDataIllustration from "../assets/illustration/undraw_empty_street_sfxm.svg";
import PaymentCard from "../components/common/PaymentCard";
import { fetchSubscriptions } from "../store/subscriptions/actions";
import { fetchPaymentMethods } from "../store/forests/actions";
import "../payment-card.css";

const Payments = () => {
  const {
    user: { id },
  } = useSelector((state) => state.app);
  const { subscriptions, subscriptionsAddress } = useSelector(
    (state) => state.subscriptions
  );
  const { paymentMethods } = useSelector((state) => state.forests);

  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState(false);
  const [searchedDataSource, setSearchedDataSource] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);

  const noData = {
    title:
      window.localStorage.getItem("dashLang") === "english"
        ? "Donation plans currently not available"
        : "Spendenpläne sind zur Zeit nicht verfügbar",
    message:
      window.localStorage.getItem("dashLang") === "english"
        ? "You haven't taken patronship of any forest units. Please sponsor one or more forest unit to see more details."
        : "Du hast noch keine Waldpatenschaft übernommen. Spende bitte um mehr Details sehen zu können.",
    imgSrc: NoDataIllustration,
    buttonText:
      window.localStorage.getItem("dashLang") === "english"
        ? "Be a forest patron"
        : "Werde Waldpate",
  };

  const finishLoading = () => {
    setPageLoading(false);
  };

  const stopPageLoading = () => {
    setTimeout(finishLoading, 2000);
  };

  useEffect(() => {
    if (pageLoading) {
      dispatch(fetchSubscriptions(id, stopPageLoading));
      dispatch(fetchPaymentMethods(id));
    } else {
      return;
    }
  }, [pageLoading]);

  useEffect(() => {
    if (subscriptions && subscriptionsAddress) {
      const subList = subscriptions.map((item) => {
        return {
          ...item,
          address: subscriptionsAddress.filter((addressEntity, idx) => {
            return (
              item.forest["addressId"] === addressEntity.id && addressEntity
            );
          })[0],
        };
      });
      setSearchedDataSource(subList);
      setSubscriptionList(subList);
    } else {
      return;
    }
  }, [subscriptions, subscriptionsAddress]);

  useEffect(() => {
    setPageLoading(true);
  }, []);

  return pageLoading ? (
    <LoaderGif />
  ) : subscriptionList && subscriptionList.length > 0 ? (
    <div>
      <div className="flex w-100">
        <SearchBar
          searchedData={(source) => setSearchedDataSource(source)}
          dataSource={subscriptionList}
        />
      </div>

      {paymentMethods && (
        <PaymentCard
          paymentMethods={paymentMethods}
          dataSource={searchedDataSource}
          afterUpdate={() => setPageLoading(true)}
        />
      )}
    </div>
  ) : (
    <NoData source={noData} />
  );
};

export default Payments;
