import React, { useState, useEffect } from "react";
import { Button } from "antd";
import LoadingImageGif from "../assets/loader.gif";
import CongratsIllustration from "../assets/illustration/congrats-illustration.png";
import { history } from "../index";

const Congratulations = () => {
  const [pageLoading, setPageLoading] = useState(false);

  const finishLoading = () => {
    setPageLoading(false);
  };
  const stopPageLoading = () => {
    setTimeout(finishLoading, 1000);
  };

  useEffect(() => {
    setPageLoading(true);
    stopPageLoading();
  }, []);

  return pageLoading ? (
    <div
      className="flex column justifyCenter alignCenter wv-100 hv-100"
      style={{
        backgroundColor: "#fffff",
      }}
    >
      <img src={LoadingImageGif} alt="loading-gif" height={120} width={120} />
    </div>
  ) : (
    <div
      className="flex justifyCenter alignCenter w-100 h-100"
      style={{
        // minHeight: "100vh",
        textAlign: "center",
        margin: "auto",
      }}
    >
      <div
        className="flex column alignCenter justifyCenter"
        style={{
          maxWidth: 992,
          background: "#ffffff",
          borderRadius: 12,
          padding: "56px 32px",
        }}
      >
        <img
          src={CongratsIllustration}
          alt="congrats-illustration"
          width={220}
          height={164}
        />
        <div style={{ marginTop: 32 }}>
          <h1 style={{ color: "#274B28" }}>Congratulations</h1>
          <p
            style={{ color: "#9E9E9E", textAlign: "center", margin: "24px 0" }}
          >
            Du hast erfolgreich die Patenschaft für deine Waldstücke übernommen
            und hast damit einen aktiven und positiven Einfluss auf unsere
            Mutter Erde.
          </p>
          <Button
            className="green-button"
            onClick={() => {
              window.localStorage.removeItem("submit1");
              window.localStorage.removeItem("submit2");
              window.localStorage.removeItem("submit3");
              window.localStorage.removeItem("patch-name");
              window.localStorage.removeItem("editTab");
              window.localStorage.removeItem("gift");
              //  history.push("/dashboard/patches");
              let patchid = localStorage.getItem("patchid");
              history.push(
                `/dashboard/patch/details?id=${patchid}&isNew=${false}`
              );
            }}
          >
            See your forest units
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
