import React from "react";

const EmailVerificationMessage = () => {
  return (
    <div className="container">
      <div className="container_404">
        <h1
          style={{
            fontSize: 28,
            lineHeight: "140%",
            color: "#ffffff",
          }}
        >
          Bitte bestätige deine Email-Adresse
        </h1>
        <p
          style={{
            padding: 0,
            margin: "32px 0px 0px",
            color: "#ffffff",
            fontSize: 18,
            wordSpacing: 0,
            lineHeight: "160%",
          }}
        >
          Ein Bestätigungslink wurde an deine Email-Adresse versand, bitte klicke den
          Link um deine Email zu bestätigen und die Registrierung abzuschließen.
        </p>
      </div>
    </div>
  );
};

export default EmailVerificationMessage;
