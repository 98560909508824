import React from "react";
import { useSelector } from "react-redux";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";
import { history } from "../../index";

const ShareOnSocialMedia = ({ hideCard }) => {
  const domain = process.env.REACT_APP_STATIC_PAGES_URL
  const { location: { pathname, search } } = history;
  const forestURL = `${domain}${pathname}${search}`;
  const { forestDetails } = useSelector((state) => state.forests);

  return (
    <div>
      <FacebookShareButton
        url={forestURL}
        quote={forestDetails && forestDetails.name}
        hashtag={["#forest"]}
        description={"Please have a look on my forest"}
        className="share-button"
        onClick={hideCard}
      >
        <FacebookIcon size={32} round style={{ marginRight: 8 }} />
        <h4 style={{ color: "#274B28" }}> Share on Facebook</h4>
      </FacebookShareButton>
      <TwitterShareButton
        title={forestDetails && forestDetails.name}
        url={forestURL}
        hashtags={[
          "forestly",
          "forest",
          "nature",
          "humanity",
          "communityforests",
          "climatesolution",
          "natural",
        ]}
        className="share-button"
        onClick={hideCard}
      >
        <TwitterIcon size={32} round style={{ marginRight: 8 }} />
        <h4 style={{ color: "#274B28" }}>Share on Twitter</h4>
      </TwitterShareButton>
      <LinkedinShareButton
        title={forestDetails && forestDetails.name}
        url={forestURL}
        className="share-button"
        summary={"Please have a look on my forest"}
        source={"Forest.ly"}
        onClick={hideCard}
      >
        <LinkedinIcon size={32} style={{ marginRight: 8, borderRadius: 8 }} />
        <h4 style={{ color: "#274B28" }}>Share on LinkedIn</h4>
      </LinkedinShareButton>
    </div>
  );
};

export default ShareOnSocialMedia;
