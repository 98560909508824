import { put, takeEvery, call } from "redux-saga/effects";
import api from "../../services/api/ApiService";
import {
  UPDATE_USER_PASSWORD,
  UPDATE_SETTINGS,
  UPDATE_USER_ADDRESS,
  UPLOAD_PROFILE_IMAGE,
} from "./actions";
import { setUser } from "../app/actions";
import { createNotification } from "../app/actions";

function updatePassword(userId, values) {
  const resp = api.put(`/api/user/${userId}/updatePassword`, values);
  return resp;
}

function* updateUserPassword({ payload: { form, userId, success, error } }) {
  try {
    const response = yield call(updatePassword, userId, form);
    const {
      data: { metadata },
    } = response;

    if (metadata.code === 200) {
      yield call(success, metadata.message);
      yield put(
        createNotification(
          "success-toast",
          "Password has been updated successfully"
        )
      );
    } else {
      const { message } = metadata;
      yield call(error, message);
    }
  } catch (e) {
    const { metadata: { message } } = e.response.data;
    yield call(error, message);
  }
}

function updateSettings(values, userId) {
  const resp = api.put(`/api/user/${userId}`, values);
  return resp;
}

function* updateNewSettings({ payload: { form, userId, success, error } }) {
  try {
    const response = yield call(updateSettings, form, userId);
    const {
      data: { payload, metadata },
    } = response;

    if (metadata.code === 200) {
      const { user } = payload;
      yield put(setUser(user));
      yield call(success, metadata.message);
      yield put(
        createNotification(
          "success-toast",
          "Basic information has been updated successfully"
        )
      );
    } else {
      const { message } = metadata;
      yield call(error, message);
    }
  } catch (e) {
    const { metadata: { message } } = e.response.data;
    yield call(error, message);
  }
}

function updateAddress(values, userId) {
  const resp = api.put(`/api/user/${userId}/updateAddress`, values);
  return resp;
}

function* updateNewAddress({ payload: { form, userId, success, error } }) {
  try {
    const response = yield call(updateAddress, form, userId);
    const {
      data: { payload, metadata },
    } = response;
    if (metadata.code === 200) {
      const { user } = payload;
      yield put(setUser(user));
      yield call(success, metadata.message);
      yield put(
        createNotification(
          "success-toast",
          "Address information has been updated successfully"
        )
      );
    } else {
      const { message } = metadata;
      yield call(error, message);
    }
  } catch (e) {
    const { metadata: { message } } = e.response.data;
    yield call(error, message);
  }
}


function updateProfileImage(imageURL, userId) {
  const resp = api.put(`/api/user/${userId}/profileImage`,
    {
      profileURL: imageURL
    });
  return resp;
}

function* updateUserImage({ payload: { imageURL, userId, success, error } }) {
  try {
    const response = yield call(updateProfileImage, imageURL, userId);
    const {
      data: { metadata },
    } = response;
    if (metadata.code === 200) {
      yield call(success, metadata.message);
      yield put(
        createNotification(
          "success-toast",
          "Profile image has been updated successfully"
        )
      );
    } else {
      const { message } = metadata;
      yield call(error, message);
    }
  } catch (err) {
    if (err.response) {
      const {
        data: { details },
      } = err.response;
      yield call(error, details);
    }
  }
}

function* settingsSaga() {
  yield takeEvery(UPDATE_SETTINGS, updateNewSettings);
  yield takeEvery(UPDATE_USER_ADDRESS, updateNewAddress);
  yield takeEvery(UPDATE_USER_PASSWORD, updateUserPassword);
  yield takeEvery(UPLOAD_PROFILE_IMAGE, updateUserImage);
}

export default settingsSaga;
