import React, { useState } from "react";
import ArrowDown from "../../assets/icons/chevron-down.svg";
import ArrowUp from "../../assets/icons/chevron-up.svg";

const Selector = ({ source, selected, setSourceIndex }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(selected);

  const onHandleChange = (index) => {
    setShowDropdown(!showDropdown);
    setDropdownIndex(index);
    setSourceIndex(index);
  };

  return (
    <div style={{ position: "relative", marginLeft: 16 }}>
      <div
        // style={{
        //     background: "#ffffff",
        //     display: "flex",
        //     alignItems: "center",
        //     width: "auto",
        //     borderRadius: 100,
        //     boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
        //     padding: "8px 24px",
        //     cursor: "pointer",
        // }}
        className="selectorBtn"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <h4 style={{ color: "#274B28" }}>{source[dropdownIndex].name}</h4>
        {showDropdown ? (
          <img
            src={ArrowUp}
            alt="arrow-up"
            style={{
              marginLeft: 8,
              height: 26,
              width: 26,
            }}
          />
        ) : (
          <img
            src={ArrowDown}
            alt="arrow-down"
            style={{
              marginLeft: 8,
              height: 26,
              width: 26,
            }}
          />
        )}
      </div>

      {showDropdown && (
        <div
          style={{
            position: "absolute",
            background: "#ffffff",
            width: 344,
            borderRadius: 8,
            padding: "8px 0",
            marginTop: 16,
            zIndex: 10,
            boxShadow: "0px 1px 18px rgba(0, 0, 0, 0.12)",
          }}
        >
          {source.map((detail, index) => (
            <>
              {index === dropdownIndex ? (
                <div
                  className="dropdown-items dropdown-items-active"
                  key={`dropdown-item-${index}`}
                  onClick={() => onHandleChange(index)}
                >
                  <h5 style={{ color: "#274B28" }}>{detail.name}</h5>
                  <h6 style={{ color: "#757575", margin: "6px 0" }}>
                    {`${detail.area} sq Patch`}
                  </h6>
                </div>
              ) : (
                <div
                  className="dropdown-items"
                  key={`dropdown-item-${index}`}
                  onClick={() => onHandleChange(index)}
                >
                  <h5 style={{ color: "#274B28", margin: "8px 0" }}>
                    {detail.name}
                  </h5>
                  <h6 style={{ color: "#757575", margin: "6px 0" }}>
                    {`${detail.area} sq Patch`}
                  </h6>
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default Selector;
