import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, Input } from "antd";
import LoadingFormButton from "./../LoadingFormButton";
import CrossIcon from "../../../assets/icons/cross.png";
import RadioBoxBlank from "../../../assets/icons/radiobox-blank.svg";
import RadioBoxMarked from "../../../assets/icons/radiobox-marked.svg";
import PulseLoader from "./../PulseLoader";
import { useHistory } from "react-router-dom";
import { addToCart } from "../../../store/forests/actions";

const BuyModal = ({ source, sourceID, visible, onClose, afterSubmit }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { user: { id } } = useSelector((state) => state.app);
  const userId = id;
  const { SubscriptionPlans, maximumUnitsInSqAvailableForSale } = source;
  const plan = SubscriptionPlans.filter(item => item.subscriptionType === "monthly");
  const { currency, amountPerSqm } = plan[0] || {};

  // States
  let [plans, setPlans] = useState(source.SubscriptionPlans);
  const [submitting, setSubmitting] = useState(false);
  const [buySqm, setBuySqm] = useState(1);
  const [payable, setPayable] = useState(amountPerSqm);
  const [showPulseLoader, setShowPulseLoader] = useState(false);
  const [isPlanSelected, onSelectPlan] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  const finishLoading = () => {
    setShowPulseLoader(false);
  };

  const stopLoading = () => {
    setTimeout(finishLoading, 500);
  };

  const calculatePayment = (amount, area) => {
    const amt = amount * area;
    return amt.toFixed(2);
  }

  const onChangeArea = async (sqm) => {
    setShowPulseLoader(true);
    setBuySqm(sqm);
    setPayable(calculatePayment(amountPerSqm, sqm));
    const updatedPlan = SubscriptionPlans.map(plan => ({ ...plan, amountPerSqm: calculatePayment(plan.amountPerSqm, sqm) }));
    setPlans(updatedPlan);
    stopLoading();
  };

  const selectPlan = async (idx) => {
    onSelectPlan(true);
    const updatedPlan = plans.map((plan, index) => ({ ...plan, isActive: index === idx ? true : false }));
    setPlans(updatedPlan);
  };

  const onFinishFailed = (error) => {
    setSubmitting(false);
    onClose();
    console.error("error", error);
    afterSubmit();
  }

  const onFinishSuccess = () => {
    setSubmitting(false);
    onClose();
    afterSubmit();
  }

  const onDone = async () => {
    setSubmitting(true);
    let { amountPerSqm, id, subscriptionType } = (plans.filter(item => item.id === selectedPlan))[0];
    const params = {
      userId: userId,
      forestId: sourceID,
      subscriptionPlanId: id,
      subscriptionType: subscriptionType,
      areaInSqMeters: buySqm,
      amountToPay: Number(amountPerSqm),
      currency: currency
    }
    await dispatch(addToCart(params, onFinishSuccess, onFinishFailed));
    history.push("/dashboard/patches/cart/review");

  }

  useEffect(() => {
    if (plans) {
      setPlans(plans.map((plan) => ({ ...plan, isActive: false })));
    }
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      closable={false}
      style={{ top: 24 }}
      bodyStyle={{ backgroundColor: "#ffffff", padding: 0, borderRadius: 8 }}
      width={576}
    >

      <div className="h-100 w-100">
        <div className="flex justifyBetween alignCenter w-100"
          style={{ borderBottom: "1px solid #E0E0E0" }}
        >
          <h2 className="modalTitle textGreen flex alignCenter w-100"> Waldpatenschaft</h2>
          <img
            src={CrossIcon}
            alt={"close_icon"}
            style={{ height: 16, width: 16, cursor: "pointer", marginRight: 24 }}
            onClick={onClose}
          />
        </div>
        <div className="areaForm flex column justifyStart">
          <p style={{ color: "#9E9E9E", margin: 0 }}>
            {`Wie viel Waldfläche möchtest du?`}
          </p>

          {/* Input field */}
          <div className="flex w-100 areaInputContainer">
            <Form.Item
              className="h-100 w-100"
              style={{ minWidth: "100%", position: "relative", padding: 0, border: "none", }}
              rules={[
                {
                  required: true,
                  message: "Area is required",
                },
              ]}
              required={false}
            >
              <Input
                type="number"
                value={buySqm}
                onChange={(e) => {
                  const inputValue = e.target.value
                  if (inputValue <= maximumUnitsInSqAvailableForSale) {
                    setBuySqm(inputValue);
                    onChangeArea(inputValue);
                  } else if (Number(e.target.value) <= 0) {
                    setBuySqm(1);
                    onChangeArea(1);
                  }
                }}
                style={{
                  height: 64,
                  color: "#424242",
                  background: "#FAFAFA",
                  borderRadius: 8,
                  border: "1px solid #E0E0E0",
                  fontSize: 24,
                  paddingLeft: 24,
                  lineHeight: "30px",
                  boxShadow: "none",
                }}
              />
            </Form.Item>

            <div className="flex column JustifyCenter alignCenter h-100"
              style={{
                position: "absolute",
                right: 24,
                marginTop: 16
              }}
            >
              <p className="textGreen"
                style={{
                  background: "rgba(0, 0, 0, 0.04)",
                  borderRadius: 100,
                  padding: "8px 24px",
                  margin: 0,
                }}
              >
                {`Verfügbar : ${maximumUnitsInSqAvailableForSale - buySqm} qm`}
              </p>
            </div>
          </div>

          <div className="flex justifyBetween w-100" style={{ marginTop: 26 }}>
            {/* Estimated cost */}
            <div className="flex column">
              <h4 className="textGray">
                {`Spendenbetrag`}
              </h4>
              <h2 style={{ color: "#9E9E9E", fontSize: 12, margin: "2px 0 8px",textAlign:"left" }}>
                Deine Auswahl
              </h2>

              {showPulseLoader ? (
                <PulseLoader />
              ) : (
                <h3 style={{ color: "#424242", marginTop: 12, fontWeight: 700 }}>
                  {`${currency} ${payable || `0.00`}`}
                </h3>
              )}
            </div>

            {/* Monthly price */}
            <div className="flex column justifyCenter"
              style={{
                background: "#F1FFF2",
                border: "1px solid #E0E0E0",
                borderRadius: 8,
                height: 64,
                padding: "0 16px",
              }}
            >
              <h4
                style={{
                  color: "#424242",
                  fontWeight: 700,
                }}
              >
                {`${currency} ${amountPerSqm}`}
              </h4>
              <p
                style={{
                  color: "#424242",
                  margin: "4px 0 0",
                  fontSize: 14,
                  lineHeight: "18px",
                }}
              >
                pro Quadratmeter
              </p>
            </div>
          </div>
        </div>

        {/* 2- Select payment plan */}
        <div className="flex column justifyCenter alignCenter"
          style={{
            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            background: "#F5F5F5",
          }}
        >
          <div className="flex column justifyStart w-100" style={{ padding: "20px 26px" }}>
            <p
              style={{
                color: "#9E9E9E",
                fontSize: 18,
                lineHeight: "21px",
                margin: 0,
              }}
            >
              {`Wähle deinen Spendeninterval`}
            </p>
            {plans && plans.map((plan, index) => {
              return (
                <div className="flex justifyBetween alignCenter mt-16 w-100"
                  style={{
                    background: plan.isActive ? "#F1FFF2" : "#ffffff",
                    padding: 16,
                    borderRadius: 8,
                    border: plan.isActive
                      ? "1px solid #274B28"
                      : "1px solid #E0E0E0",
                  }}
                  onClick={() => {
                    selectPlan(index);
                    setSelectedPlan(plan.id);
                  }}
                  key={index + 1}
                >
                  <div className="flex alignCenter justifyStart w-100">
                    {plan.isActive ? (
                      <img
                        src={RadioBoxMarked}
                        alt="radi-box-marked"
                        height={24}
                        width={24}

                      />
                    ) : (
                      <img
                        src={RadioBoxBlank}
                        alt="radio-box-blank"
                        height={24}
                        width={24}
                      />
                    )}

                    {plan.subscriptionType === "monthly" && (
                      <p className="textGreen" style={{ margin: "0 0 0 16px" }}>
                        {`Monatlich`}
                      </p>
                    )}
                    {plan.subscriptionType === "yearly" && (
                      <p className="textGreen" style={{ margin: "0 0 0 16px" }}>
                        {`Jährlich`}
                      </p>
                    )}
                    {plan.subscriptionType === "lifetime" && (
                      <p className="textGreen" style={{ margin: "0 0 0 16px" }}>
                        {`Einmalig`}
                      </p>
                    )}

                  </div>
                  <div className="flex alignCenter justifyEnd w-100">


                    {showPulseLoader ? (
                      <PulseLoader />
                    ) : (
                      <h5
                        style={{
                          color: "#424242",
                          fontWeight: 800,
                        }}
                      >
                        {`${plan.currency} ${plan.amountPerSqm || `0.00`}`}
                      </h5>
                    )}


                    {plan.subscriptionType === "monthly" && (
                      <p style={{ color: "#9E9E9E", fontSize: 12, margin: "0 0 0 8px" }}>
                        {`pro Monat`}
                      </p>
                    )}
                    {plan.subscriptionType === "yearly" && (
                      <p style={{ color: "#9E9E9E", fontSize: 12, margin: "0 0 0 8px" }}>
                        {`jährlich abgerechnet`}
                      </p>
                    )}
                    {plan.subscriptionType === "lifetime" && (
                      <p style={{ color: "#9E9E9E", fontSize: 12, margin: "0 0 0 8px" }}>
                        {`eimmalig`}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex column"
          style={{ padding: "24px 24px 32px" }}>
          <div className="flex justifyEnd w-100">
            <div
              style={{
                background: "#F5F5F5",
                borderRadius: 100,
                padding: "12px",
                cursor: "pointer",
                marginRight: 16,
                width: 176,
                textAlign: "center",
              }}
              onClick={onClose}
            >
              <h4 className="textGray" style={{textAlign:"center"}} >Abbrechen</h4>
            </div>

            {!isPlanSelected ? (
              <div
                style={{
                  background: "#F5F5F5",
                  borderRadius: 100,
                  padding: "12px",
                  cursor: "not-allowed",
                  marginRight: 16,
                  width: 176,
                  textAlign: "center",
                }}
              >
                <h4 className="textGray" style={{textAlign:"center"}}>Hinzufügen</h4>
              </div>
            ) : (
              <LoadingFormButton
                submitting={submitting}
                label="Hinzufügen"
                onClick={() => onDone()}
                style={{ width: 176, fontSize:"16px" }}
                className={"update-button"}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BuyModal;
