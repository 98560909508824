export default {
  app: {
    isInitialized: false,
    user: null,
    address: null,
    email: null,
    cartItems: 0,
    cartPayload: [],
    newForests: null,
    paymentMethods: null,
    forestDetails: null,
    forestSpecies: null,
    forestCoordinates: null,
    subscriptions: null,
    payments: null,
    metrices: null,
    subscriptionsHistory: null,
    subscriptionsAddress: null,
    notification: { open: false },
  },
  loading: {},
};
